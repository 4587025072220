const errorMessageFromStatus = status => {
  switch (status) {
    case 500:
      return 'Server error. Please contact support.'
    case 502:
      return 'Bad gateway. Please contact support.'
    case 503:
      return 'Service unavailable. Please try again later.'
    case 400:
      return 'Bad request.'
    case 401:
      return 'Unauthorised. You do not have permission.'
    case 403:
      return 'Forbidden. You do not have permission.'
    case 422:
      return 'Unprocessible entity.'
    default:
      return status ? status : 'Please try again. If the problem persists contact support.'
  }
}

export { errorMessageFromStatus }
