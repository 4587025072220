import { BACKEND_CONSTANTS } from './constants'

import { patch } from './helpers'

const updateStocktakeProduct = async (stocktakeProductId, data) =>
  patch(`stocktake_products/${stocktakeProductId}`, BACKEND_CONSTANTS.STOCKTAKE_PRODUCTS.UPDATE_STOCKTAKE_PRODUCT, data)

const updateStocktakeProducts = async data =>
  patch('stocktake_products', BACKEND_CONSTANTS.STOCKTAKE_PRODUCTS.UPDATE_STOCKTAKE_PRODUCTS, data)

export { updateStocktakeProduct, updateStocktakeProducts }
