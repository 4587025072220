import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FaPlus } from 'react-icons/fa'

import { AutoComplete } from './AutoComplete'
import { AutoCompleteResult } from './AutoCompleteResult'
import { ErrorInline } from './ErrorInline'
import { Loading } from './Loading'
import { Modal } from './Modal'

import { useVariants } from '../hooks/useVariants'
import { sortArrayByObjectKey } from '../helpers'

const AddProductModal = ({ isOpen, loading, onClose, onSubmit, supplierId }) => {
  const [selections, setSelections] = useState()
  const { variants, error } = useVariants(supplierId, isOpen)
  const [results, setResults] = useState()

  useEffect(() => {
    if (variants) setResults(sortArrayByObjectKey(variants, 'title'))
  }, [variants])

  function getResults(q) {
    return results.reduce(
      (acc, cur) =>
        cur.title.toLowerCase().includes(q) ||
        (cur.sku && cur.sku.toLowerCase().includes(q)) ||
        cur.vendorName.toLowerCase().includes(q)
          ? [...acc, cur]
          : acc,
      [],
    )
  }

  function handleSetSelections(id) {
    setSelections(prev => {
      if (!prev) return [id]

      return prev.some(s => s === id) ? prev.filter(s => s !== id) : [...prev, id]
    })
  }

  function renderResult(product) {
    const { id, sku, title } = product

    return (
      <AutoCompleteResult
        key={id}
        selected={selections && selections.some(s => s === id)}
        onClick={() => handleSetSelections(product.id)}
      >
        <div className="w-64">{sku}</div>
        <div>{title}</div>
      </AutoCompleteResult>
    )
  }

  return (
    <Modal
      closeModal={onClose}
      disabled={!selections}
      heading="Add Products"
      loading={loading}
      isOpen={isOpen}
      maxWidth="6xl"
      onSubmit={() => onSubmit(selections)}
      submitIcon={<FaPlus />}
      submitValue="Add Products"
    >
      {!error && results && (
        <AutoComplete
          className="mt-6 w-full"
          getResults={getResults}
          id="product-query"
          placeholder="Search by product name, sku or vendor"
          renderResult={renderResult}
        />
      )}
      {!error && !results && <Loading loading />}
      {error && <ErrorInline error={error} />}
    </Modal>
  )
}

AddProductModal.propTypes = {
  isOpen: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  loading: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  supplierId: PropTypes.string,
}

AddProductModal.defaultProps = {
  loading: false,
  isOpen: false,
  supplierId: null,
}

export { AddProductModal }
