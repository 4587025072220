import React from 'react'
import PropTypes from 'prop-types'
import { FaPlus } from 'react-icons/fa'

import { Modal } from '../../Modal'

import { PROP_TYPES } from '../../../common/propTypes'

const DeleteExpenseModal = ({ expense, isOpen, loading, onClose, onSubmit }) =>
  expense ? (
    <Modal
      closeModal={onClose}
      heading="Delete Fixed Expense"
      loading={loading}
      isOpen={isOpen}
      onSubmit={() => onSubmit(expense.id)}
      submitIcon={<FaPlus />}
      submitValue="Delete"
    >
      <p className="mt-4">
        This will delete <code className="text-orange">{expense.description}</code> from fixed expenses.
      </p>
    </Modal>
  ) : null

DeleteExpenseModal.propTypes = {
  expense: PROP_TYPES.EXPENSE,
  isOpen: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  loading: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

DeleteExpenseModal.defaultProps = {
  expense: null,
  isOpen: false,
  loading: false,
}

export { DeleteExpenseModal }
