import { BACKEND_CONSTANTS } from './constants'

import { patch, destroy } from './helpers'

const deleteOrderProducts = async orderProductIds =>
  destroy(`order_products`, BACKEND_CONSTANTS.ORDER_PRODUCTS.DELETE_ORDER_PRODUCT, { orderProductIds })

const updateOrderProduct = async (orderProductId, data) =>
  patch(`order_products/${orderProductId}`, BACKEND_CONSTANTS.ORDER_PRODUCTS.UPDATE_ORDER_PRODUCT, data)

const updateOrderProducts = async data =>
  patch('order_products', BACKEND_CONSTANTS.ORDER_PRODUCTS.UPDATE_ORDER_PRODUCTS, data)

export { deleteOrderProducts, updateOrderProduct, updateOrderProducts }
