import React from 'react'
import PropTypes from 'prop-types'
import { FaExclamationTriangle } from 'react-icons/fa'

import { Modal } from '../../Modal'

import { PROP_TYPES } from '../../../common/propTypes'

const RemoveIntegrationModal = ({ integration, isOpen, loading, onClose, onSubmit }) =>
  integration ? (
    <Modal
      closeModal={onClose}
      disabled={loading}
      heading="Remove Integration"
      isOpen={isOpen}
      loading={loading}
      onSubmit={() => onSubmit(integration.id)}
      submitIcon={<FaExclamationTriangle />}
      submitValue="Remove"
    >
      <p className="mt-6">
        Are you sure you want to remove integration <code className="bg-orange-100 p-1">{integration.name}</code> ?
      </p>
    </Modal>
  ) : null

RemoveIntegrationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  loading: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  integration: PROP_TYPES.INTEGRATION,
}

RemoveIntegrationModal.defaultProps = {
  isOpen: false,
  loading: false,
  integration: null,
}

export { RemoveIntegrationModal }
