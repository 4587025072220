import React from 'react'

import { errorMessageFromStatus } from '../common/errors'
import { PROP_TYPES } from '../common/propTypes'

const ErrorMessage = ({ error }) => (
  <>
    <p className="text-2xl mt-8 font-bold">
      <code className="text-orange">{error.status}</code>
    </p>
    {(!error.messages || error.messages.length < 1) && <p className="mt-4">{errorMessageFromStatus(error.status)}</p>}
    {error.messages && error.messages.length > 0 && (
      <div className="max-h-1/2 overflow-y-auto">
        {[...error.messages].map(message => (
          <p key={message} className="mt-4">
            {message}
          </p>
        ))}
      </div>
    )}
  </>
)

ErrorMessage.propTypes = {
  error: PROP_TYPES.ERROR,
}

ErrorMessage.defaultProps = {
  error: {
    messages: null,
    status: '',
  },
}

export { ErrorMessage }
