import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FaSyncAlt } from 'react-icons/fa'

import { Button } from '../../Button'
import { DateRangePicker } from '../../DateRangePicker'
import { Dropdown } from '../../Dropdown'

import { PROP_TYPES } from '../../../common/propTypes'

const OrderStockPosition = ({
  fetching,
  initialDateRange,
  locations: orderLocations,
  onFill,
  onRefresh,
  orderLocation,
}) => {
  const [isEdited, setIsEdited] = useState()
  const [values, setValues] = useState({
    date: initialDateRange,
    location: orderLocation.id,
  })

  const allLocationsPlaceholder = { id: 'all', companyName: 'All locations' }
  const locations = [...orderLocations, allLocationsPlaceholder]

  function onChange(val, type) {
    setValues(prev => ({
      ...prev,
      [type]: val,
    }))

    if (!isEdited) setIsEdited(true)
  }

  return (
    <div className="flex items-end">
      <div className="mr-2 w-auto">
        <label className="font-bold text-sm" htmlFor="positionDateRange">
          Sales Date Range
        </label>
        <div className="relative mt-1">
          <DateRangePicker
            id="positionDateRange"
            maxDate={new Date()}
            onChange={val => onChange(val, 'date')}
            value={values.date}
          />
        </div>
      </div>
      <Dropdown
        className="w-48 mr-2"
        id="positionLocation"
        label="Sales Location"
        onChange={val => onChange(val, 'location')}
        options={locations.map(loc => ({ value: loc.id, option: loc.companyName }))}
        value={values.location}
      />
      <Button
        className="mr-2"
        disabled={fetching}
        iconBefore={<FaSyncAlt />}
        onClick={() => {
          onRefresh(values.date, values.location)
          setIsEdited()
        }}
        value="Refresh"
        variant="secondary"
      />
      <Button disabled={isEdited || fetching} onClick={onFill} value="Fill quantities" variant="outline" />
    </div>
  )
}

OrderStockPosition.propTypes = {
  fetching: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  initialDateRange: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
  locations: PropTypes.arrayOf(PROP_TYPES.LOCATION),
  onFill: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  orderLocation: PROP_TYPES.LOCATION.isRequired,
}

OrderStockPosition.defaultProps = {
  fetching: false,
  locations: [],
}

export { OrderStockPosition }
