import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { navigate } from '@reach/router'

import { Button } from './Button'
import { Input } from './Input'
import { Logo } from './SVG/Logo'

import { authenticateUser } from '../backend/authenticate'
import { createUser } from '../backend/users'
import { errorMessageFromStatus } from '../common/errors'

const Authenticate = ({ setShopUrl }) => {
  const [loading, setLoading] = useState()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState()
  // const [hasAccount, setHasAccount] = useState(true)

  const hasAccount = true

  async function onSubmit(e) {
    e.preventDefault()
    setError()
    setLoading(true)

    const credentials = { email: email.trim().toLowerCase(), password: password.trim() }
    const res = hasAccount ? await authenticateUser(credentials) : await createUser(credentials)
    if (!res.success) setError(res)
    if (res.success) {
      setShopUrl(res.data.shopifyDomain)

      if (!hasAccount) navigate('/', { replace: true })
    }

    setLoading()
  }

  return (
    <div className="h-screen w-full flex items-center justify-center">
      <p className="inline-block text absolute top-0 left-0 px-4 py-2">
        <Logo />
      </p>
      <div className="w-full max-w-md">
        <form className="w-full max-w-md border rounded py-16 px-8" onSubmit={onSubmit}>
          <div className="mb-6 text-center">
            <p className="text-xl">{hasAccount ? 'Sign into your account' : 'Create an account'}</p>
          </div>
          {error && <p className="text-orange my-4">{errorMessageFromStatus(error.status)}</p>}
          <Input
            disabled={loading}
            id="email"
            label="Email"
            placeholder="Enter your email"
            onChange={setEmail}
            type="email"
            value={email}
          />
          <Input
            className="mt-2"
            disabled={loading}
            id="password"
            label="Password"
            placeholder="Enter your password"
            onChange={setPassword}
            type="password"
            value={password}
          />
          <Button
            className="mt-6 w-full"
            disabled={loading}
            loading={loading}
            type="submit"
            value={hasAccount ? 'Sign In' : 'Sign Up'}
            variant={hasAccount ? 'secondary' : 'primary'}
          />
        </form>
        {/* <div className="text-right mt-4 pr-8">
          <p className="text-sm">
            {hasAccount ? 'Not yet registered?' : 'Already registered?'}{' '}
            <span
              className={`font-bold cursor-pointer ${hasAccount ? 'text-orange' : ''}`}
              onClick={() => setHasAccount(val => !val)}
            >
              {hasAccount ? 'Create Account' : 'Sign In'}
            </span>
          </p>
        </div> */}
      </div>
    </div>
  )
}

Authenticate.propTypes = {
  setShopUrl: PropTypes.func.isRequired,
}

export { Authenticate }
