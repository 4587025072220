import { BACKEND_CONSTANTS } from './constants'
import { get } from './helpers'

const getStockPosition = async (orderId, startDate, endDate, locationId) => {
  const locationParam = locationId ? `&location_id=${locationId}` : ''
  return get(
    `orders/${orderId}/stock_position?start_date=${startDate}&end_date=${endDate}${locationParam}`,
    BACKEND_CONSTANTS.STOCK_POSITION.GET_STOCK_POSITION,
  )
}

export { getStockPosition }
