import React from 'react'
import PropTypes from 'prop-types'
import { FaPlus } from 'react-icons/fa'
import { Link } from '@reach/router'

import { Button } from '../../Button'
import { Header } from '../../Header'

const OrdersHeader = ({ onCreateClick }) => (
  <Header
    actions={
      onCreateClick ? <Button iconBefore={<FaPlus />} onClick={onCreateClick} value="New" variant="primary" /> : null
    }
    crumbs={[{ to: '/orders', value: 'Orders' }]}
  >
    {!onCreateClick && (
      <p>
        To create an order you first need to{' '}
        <Link className="text-orange font-bold hover:underline" to="/suppliers">
          create a supplier
        </Link>
      </p>
    )}
  </Header>
)

OrdersHeader.propTypes = {
  onCreateClick: PropTypes.func,
}

OrdersHeader.defaultProps = {
  onCreateClick: null,
}

export { OrdersHeader }
