import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '../../Button'
import { Header } from '../../Header'
import { Status } from '../../Status'

import { CONSTANTS } from '../../../common/constants'
import { formatDate } from '../../../helpers'
import { PROP_TYPES } from '../../../common/propTypes'

const TransferHeader = ({ parent, order, setModal, ...props }) => {
  const isDraft = order.status === CONSTANTS.STATUS.DRAFT
  const isRequested = order.status === CONSTANTS.STATUS.REQUESTED
  const isFulfilled = order.status === CONSTANTS.STATUS.FULFILLED

  return (
    <Header
      actions={
        <>
          {isDraft && <Button className="ml-2" onClick={() => setModal('request')} value="Mark as requested" />}
          {isRequested && <Button className="ml-2" onClick={() => setModal('fulfill')} value="Mark as fulfilled" />}
          {isFulfilled && <Button className="ml-2" onClick={() => setModal('receive')} value="Mark as received" />}
        </>
      }
      crumbs={[
        { to: parent.to, value: parent.value },
        {
          to: `${parent.to}/${order.id}`,
          value: (
            <div>
              {order.id} <Status className="mx-2 text-sm" status={order.status} />{' '}
              <span className="text-black inline-block text-sm font-bold">Created at:</span>{' '}
              <span className="text-black inline-block text-sm">{formatDate(order.createdAt)}</span>
            </div>
          ),
        },
      ]}
      {...props}
    />
  )
}

TransferHeader.propTypes = {
  order: PROP_TYPES.ORDER,
  parent: PropTypes.shape({
    to: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  setModal: PropTypes.func.isRequired,
}

TransferHeader.defaultProps = {
  order: null,
}

export { TransferHeader }
