import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { FaPlus, FaArrowRight } from 'react-icons/fa'

import { AddProductModal } from '../components/AddProductModal'
import { Button } from '../components/Button'
import { Loading } from '../components/Loading'
import { LoadingGlobal } from '../components/LoadingGlobal'
import { NotFound } from './NotFound'
import { TransferHeader } from '../components/Layouts/Transfer/TransferHeader'
import { TransferReceiveModal } from '../components/Layouts/Transfer/TransferReceiveModal'
import { TransferRequestModal } from '../components/Layouts/Transfer/TransferRequestModal'
import { TransferFulfillModal } from '../components/Layouts/Transfer/TransferFulfillModal'
import { TransferTable } from '../components/Layouts/Transfer/TransferTable'

import { CONSTANTS } from '../common/constants'
import { useLocations } from '../hooks/useLocations'
import { useOrder } from '../hooks/useOrder'
import { useOverlay } from '../hooks/useOverlay'

const Transfer = ({ orderId, parent }) => {
  const [modalContainerRef, modal, setModal] = useOverlay()
  const { locations, isFetchingLocations } = useLocations()
  const {
    addOrderProducts,
    deleteOrderProducts,
    fulfillOrder,
    receiveOrder,
    requestOrder,
    order,
    isFetchingOrder,
    isOrderModalLoading,
    isUpdatingOrder,
    notFound,
    updateOrderProduct,
  } = useOrder(orderId)

  const isFulfilled = order && order.status === CONSTANTS.STATUS.FULFILLED
  const isReceived = order && order.status === CONSTANTS.STATUS.RECEIVED

  useEffect(() => {
    if (order && !isOrderModalLoading) setModal()
  }, [isOrderModalLoading])

  if (notFound) return <NotFound />
  if (!order || !locations || isFetchingOrder || isFetchingLocations) return <Loading />

  return (
    <div className="max-w-site mb-12">
      <TransferHeader parent={parent} order={order} setModal={setModal} />
      <div className="my-8 flex items-center">
        <h2>
          <span className="font-bold">Origin location</span>:{' '}
          <span className="capitalize">
            {locations.find(location => location.id === order.fromLocationId).companyName}
          </span>
        </h2>
        <span className="px-4">
          <FaArrowRight />
        </span>
        <h2>
          <span className="font-bold">Destination location</span>:{' '}
          <span className="capitalize">{locations.find(location => location.id === order.locationId).companyName}</span>
        </h2>
      </div>
      <TransferTable deleteOrderProducts={deleteOrderProducts} order={order} updateOrderProduct={updateOrderProduct} />
      {!isFulfilled && !isReceived && (
        <div className="mt-4">
          <Button
            iconBefore={<FaPlus />}
            onClick={() => setModal('addProducts')}
            size="small"
            value="Add Products"
            variant="primary"
          />
        </div>
      )}
      {modal && (
        <div ref={modalContainerRef} className="fixed top-0 right-0 w-full h-screen z-50">
          <AddProductModal
            isOpen={modal === 'addProducts'}
            loading={isOrderModalLoading}
            onClose={setModal}
            onSubmit={productIds => addOrderProducts(productIds)}
            supplierId={order.supplierId}
          />
          <TransferFulfillModal
            isOpen={modal === 'fulfill'}
            loading={isOrderModalLoading}
            onClose={setModal}
            onSubmit={fulfillOrder}
            orderId={order.id}
          />
          <TransferReceiveModal
            isOpen={modal === 'receive'}
            loading={isOrderModalLoading}
            onClose={setModal}
            onSubmit={() => receiveOrder(CONSTANTS.KIND.TRANSFER)}
            orderId={order.id}
          />
          <TransferRequestModal
            isOpen={modal === 'request'}
            loading={isOrderModalLoading}
            onClose={setModal}
            onSubmit={requestOrder}
            orderId={order.id}
            supplier={order.supplier}
          />
        </div>
      )}
      <LoadingGlobal loading={isUpdatingOrder} />
    </div>
  )
}

Transfer.propTypes = {
  orderId: PropTypes.string,
  parent: PropTypes.shape({
    to: PropTypes.string,
    value: PropTypes.string,
  }),
}

Transfer.defaultProps = {
  orderId: '',
  parent: null,
}

export { Transfer }
