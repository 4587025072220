import { useEffect, useState, useRef } from 'react'

import { createSupplier as create, deleteSupplier as destroy, getSuppliers } from '../backend/suppliers'

import { sortArrayByObjectKey } from '../helpers'
import { useFeedback } from './useFeedback'

const useSuppliers = () => {
  const isMounted = useRef(true)
  const [isFetchingSuppliers, setIsFetchingSuppliers] = useState()
  const [isUpdatingSuppliers, setIsUpdatingSuppliers] = useState()
  const [suppliers, setSuppliers] = useState()
  const { setError, setToast } = useFeedback()

  useEffect(() => {
    getData()

    return () => {
      isMounted.current = false
    }
  }, [])

  async function getData() {
    setIsFetchingSuppliers(true)

    const res = await getSuppliers()

    if (!isMounted.current) return
    if (!res.success) setError(res)
    if (res.success) setSuppliers(sortArrayByObjectKey(res.data, 'name'))

    setIsFetchingSuppliers()
  }

  async function createSupplier(data) {
    setIsUpdatingSuppliers(true)

    const res = await create(data)

    if (!isMounted.current) return
    if (!res.success) setError(res)
    if (res.success) {
      setSuppliers(sortArrayByObjectKey([...suppliers, { ...res.data, vendors: [] }], 'name', true))
    }

    setIsUpdatingSuppliers()
  }

  async function deleteSupplier(supplierId) {
    setIsUpdatingSuppliers(true)

    const res = await destroy(supplierId)

    if (!isMounted.current) return
    if (!res.success) setError(res)
    if (res.success) {
      setSuppliers(suppliers.filter(({ id }) => id !== supplierId))
      setToast('Supplier deleted!')
    }

    setIsUpdatingSuppliers()
  }

  return { createSupplier, deleteSupplier, isFetchingSuppliers, isUpdatingSuppliers, suppliers }
}

export { useSuppliers }
