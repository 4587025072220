import React from 'react'
import PropTypes from 'prop-types'
import { FaTrash } from 'react-icons/fa'

import { Checkbox } from '../../Checkbox'
import { Table } from '../../Table'
import { TableCell } from '../../TableCell'
import { TableHeadCell } from '../../TableHeadCell'
import { TableHeadRow } from '../../TableHeadRow'
import { TableRow } from '../../TableRow'
import { TableRowActions } from '../../TableRowActions'

import { CONSTANTS } from '../../../common/constants'
import { PROP_TYPES } from '../../../common/propTypes'

const TransferTable = ({ deleteOrderProducts, order, updateOrderProduct }) => {
  const isDraft = order.status === CONSTANTS.STATUS.DRAFT
  const isFulfilled = order.status === CONSTANTS.STATUS.FULFILLED
  const isReceived = order.status === CONSTANTS.STATUS.RECEIVED

  function handleUpdateOrderProduct(id, field, value, fieldType) {
    updateOrderProduct({
      field,
      fieldType,
      id,
      value,
    })
  }

  return (
    <Table
      bulkAction={!isReceived ? deleteOrderProducts : null}
      childIds={order.products.map(({ id }) => id)}
      className="mt-6 overflow-hidden"
      emptyState={!order.products.length && 'No products yet...'}
      renderHead={({ bulkChecked, onBulkChange }) => (
        <TableHeadRow>
          {!isReceived && (
            <TableHeadCell
              slim
              width="4"
              value={
                <Checkbox
                  checked={bulkChecked.length >= order.products.length}
                  disabled={!order.products.length}
                  onChange={() => onBulkChange('all', bulkChecked.length >= order.products.length)}
                />
              }
            />
          )}
          <TableHeadCell value="Product" width="1/3" />
          <TableHeadCell value="SKU" width="32" />
          <TableHeadCell value="Destination stock" width="24" />
          <TableHeadCell value="Origin stock" width="24" />
          <TableHeadCell value={`Qty${!isDraft ? ' Requested' : ''}`} />
          {!isDraft && <TableHeadCell value="Qty Fulfilled" />}
          {(isFulfilled || isReceived) && <TableHeadCell value="Qty Received" />}
          {!isReceived && <TableHeadCell alignment="right" width="32" value="Actions" />}
        </TableHeadRow>
      )}
      renderBody={({ bulkChecked, onBulkChange }) => (
        <>
          {order.products.map(
            ({
              id,
              fromQuantityInStock,
              quantityFulfilled,
              quantityOrdered,
              quantityReceived,
              sku,
              title,
              toQuantityInStock,
              url,
            }) => (
              <TableRow key={id}>
                {!isReceived && (
                  <TableCell
                    slim
                    width="4"
                    value={
                      <Checkbox
                        checked={bulkChecked.includes(id)}
                        onChange={() => onBulkChange(id, bulkChecked.includes(id))}
                        tabIndex="-1"
                      />
                    }
                  />
                )}
                <TableCell width="1/3" value={title} url={url} />
                <TableCell width="32" capitalize={false} value={sku} />
                <TableCell width="24" type="number" value={toQuantityInStock} />
                <TableCell width="24" type="number" value={fromQuantityInStock} />
                <TableCell
                  type="number"
                  onChange={
                    isDraft
                      ? value => handleUpdateOrderProduct(id, 'quantityOrdered', value, CONSTANTS.FIELD_TYPES.INT)
                      : null
                  }
                  value={quantityOrdered}
                />
                {!isDraft && (
                  <TableCell
                    type="number"
                    onChange={
                      !isReceived && !isFulfilled
                        ? value => handleUpdateOrderProduct(id, 'quantityFulfilled', value, CONSTANTS.FIELD_TYPES.INT)
                        : null
                    }
                    value={quantityFulfilled}
                  />
                )}
                {(isFulfilled || isReceived) && (
                  <TableCell
                    onChange={
                      !isReceived
                        ? value => handleUpdateOrderProduct(id, 'quantityReceived', value, CONSTANTS.FIELD_TYPES.INT)
                        : null
                    }
                    type="number"
                    value={quantityReceived}
                  />
                )}
                {!isReceived && (
                  <TableRowActions
                    actions={[
                      {
                        id: 'delete',
                        iconBefore: <FaTrash />,
                        onClick: () => deleteOrderProducts([id]),
                        tabIndex: '-1',
                      },
                    ]}
                  />
                )}
              </TableRow>
            ),
          )}
        </>
      )}
    />
  )
}

TransferTable.propTypes = {
  deleteOrderProducts: PropTypes.func.isRequired,
  order: PROP_TYPES.ORDER.isRequired,
  updateOrderProduct: PropTypes.func.isRequired,
}

export { TransferTable }
