import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import equal from 'fast-deep-equal'
import { FaSave, FaUndo } from 'react-icons/fa'
import { Link } from '@reach/router'

import { Button } from '../../Button'
import { Dropdown } from '../../Dropdown'
import { Input } from '../../Input'
import { Loading } from '../../Loading'

import { PROP_TYPES } from '../../../common/propTypes'
import { useSupplier } from '../../../hooks/useSupplier'

const SupplierDetails = ({ loading, supplier, updateSupplier }) => {
  const [updatedSupplier, setUpdatedSupplier] = useState(supplier)
  const [xeroContactId, setXeroContactId] = useState(supplier.xeroContactId)
  const { getXeroContacts, xeroContacts } = useSupplier(supplier.id)

  useEffect(() => {
    getXeroContacts()
  }, [])

  function handleSave() {
    updateSupplier({
      ...updatedSupplier,
      xeroContactId,
    })
  }

  function handleSetSupplier(updatedFields) {
    setUpdatedSupplier({
      ...updatedSupplier,
      ...updatedFields,
    })
  }

  return (
    <div className="mt-2">
      <div className="flex">
        <Input
          className="w-64 mr-2"
          disabled={loading}
          id="supplier-edit-name"
          label="Name"
          onChange={name => handleSetSupplier({ name })}
          value={updatedSupplier.name}
        />
        <Input
          className="w-64"
          disabled={loading}
          id="supplier-edit-email"
          label="Email"
          onChange={email => handleSetSupplier({ email })}
          type="email"
          value={updatedSupplier.email}
        />
      </div>
      <div className="mt-6">
        {xeroContacts && xeroContacts.length > 0 && (
          <Dropdown
            className="w-64"
            id="xero-contacts"
            label="Xero Contact"
            onChange={setXeroContactId}
            options={xeroContacts.map(x => ({ option: x.name, value: x.id }))}
            value={xeroContactId}
          />
        )}
        {xeroContacts && xeroContacts.length < 1 && (
          <div>
            <span className="font-bold text-sm">Xero Contact</span>
            <p className="mt-2">
              You need to{' '}
              <Link className="text-orange" to="/integrations">
                create a Xero integration
              </Link>
            </p>
          </div>
        )}
        {!xeroContacts && (
          <div className="w-64">
            <Loading loading />
          </div>
        )}
      </div>
      <p className="font-bold mt-6">Contact person</p>
      <div className="flex mt-2">
        <Input
          className="w-64 mr-2"
          disabled={loading}
          id="supplier-edit-firstName"
          label="First Name"
          onChange={contactFirstName => handleSetSupplier({ contactFirstName })}
          type="firstName"
          value={updatedSupplier.contactFirstName}
        />
        <Input
          className="w-64"
          disabled={loading}
          id="supplier-edit-lastName"
          label="Last Name"
          onChange={contactLastName => handleSetSupplier({ contactLastName })}
          type="lastName"
          value={updatedSupplier.contactLastName}
        />
      </div>

      <div className="flex items-center mt-6">
        <Button
          disabled={loading || equal(supplier, updatedSupplier)}
          iconBefore={<FaUndo />}
          onClick={() => setUpdatedSupplier(supplier)}
          value="Undo"
          variant="ghost"
        />
        <Button
          className="ml-2"
          disabled={loading}
          iconBefore={<FaSave />}
          loading={loading}
          onClick={handleSave}
          value="Save"
        />
      </div>
    </div>
  )
}

SupplierDetails.propTypes = {
  loading: PropTypes.bool,
  supplier: PROP_TYPES.SUPPLIER.isRequired,
  updateSupplier: PropTypes.func.isRequired,
}

SupplierDetails.defaultProps = {
  loading: false,
}

export { SupplierDetails }
