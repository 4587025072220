import React from 'react'
import PropTypes from 'prop-types'
import { FaSyncAlt } from 'react-icons/fa'

import { Modal } from '../../Modal'
import { CONSTANTS } from '../../../common/constants'

const OrderSyncModal = ({ loading, isOpen, onClose, onSubmit, status, warnings }) => (
  <Modal
    closeModal={onClose}
    heading="Sync Stock With Shopify"
    loading={loading}
    isOpen={isOpen}
    onSubmit={() => onSubmit()}
    submitIcon={<FaSyncAlt />}
    submitValue="Confirm"
  >
    <div className="mt-4">
      <p>
        This will update Shopify with the received stock quantities
        {status === CONSTANTS.STATUS.SENT && (
          <>
            {' '}
            and the order will be marked as <code className="text-orange">Partially Received</code>
          </>
        )}
        .
      </p>
      {warnings && (
        <>
          <div className="text-sm mt-4 p-4 bg-orange-100 rounded border border-orange overflow-y-auto max-h-1/2">
            <p className="font-bold mb-2">Please check the following first:</p>
            {warnings.map(error => (
              <p key={error} dangerouslySetInnerHTML={{ __html: error }} />
            ))}
          </div>
          <p className="mt-4">Are you sure?</p>
        </>
      )}
    </div>
  </Modal>
)

OrderSyncModal.propTypes = {
  loading: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isOpen: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  status: PropTypes.string,
  warnings: PropTypes.arrayOf(PropTypes.string),
}

OrderSyncModal.defaultProps = {
  isOpen: false,
  loading: false,
  status: '',
  warnings: null,
}

export { OrderSyncModal }
