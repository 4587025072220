import PropTypes from 'prop-types'

const EXPENSE = PropTypes.shape({
  amount: PropTypes.number,
  createdAt: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.string,
  orderId: PropTypes.string,
  updatedAt: PropTypes.string,
})

const LOCATION = PropTypes.shape({
  companyName: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  line1: PropTypes.string,
  line2: PropTypes.string,
  line3: PropTypes.string,
  city: PropTypes.string,
  postcode: PropTypes.string,
  phoneNumber: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
})

const ORDER_PRODUCT = PropTypes.shape({
  costPaid: PropTypes.number,
  costPrevious: PropTypes.number,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  product: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    lastPricePaid: PropTypes.number,
    sku: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
    vendorId: PropTypes.number,
  }),
  quantityInStock: PropTypes.number,
  quantityOnOrder: PropTypes.number,
  quantityOrdered: PropTypes.number,
  quantityReceived: PropTypes.number,
  quantitySold: PropTypes.number,
  supplierCode: PropTypes.string,
})

const STOCKTAKE_PRODUCT = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  costOfGoods: PropTypes.number,
  product: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    sku: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
    vendorId: PropTypes.number,
  }),
  quantityCounted: PropTypes.number,
  quantityExpected: PropTypes.number,
})

const SUPPLIER = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  contactFirstName: PropTypes.string,
  contactLastName: PropTypes.string,
  email: PropTypes.string,
  name: PropTypes.string,
  vendors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
})

const VENDOR = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
})

const PROP_TYPES = {
  ERROR: PropTypes.shape({
    status: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    messages: PropTypes.arrayOf(PropTypes.string),
  }),
  EXPENSE,
  EXPENSES: PropTypes.arrayOf(EXPENSE),
  INTEGRATION: PropTypes.shape({
    authUrl: PropTypes.string,
    name: PropTypes.string,
  }),
  LOCATION,
  PRODUCT: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    createdAt: PropTypes.string,
    price: PropTypes.number,
    previousPrice: PropTypes.number,
    quantityInStock: PropTypes.number,
    quantityOrdered: PropTypes.number,
    quantityOnOrder: PropTypes.number,
    quantityReceived: PropTypes.number,
    quantitySold: PropTypes.number,
    runningCog: PropTypes.number,
    sku: PropTypes.string,
    title: PropTypes.string,
    updatedAt: PropTypes.string,
    url: PropTypes.string,
    vendorId: PropTypes.number,
  }),
  ORDER: PropTypes.shape({
    adjustments: PropTypes.number,
    createdAt: PropTypes.string,
    fixedExpenses: PropTypes.arrayOf(EXPENSE),
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    invoiceDate: PropTypes.string,
    invoiceNumber: PropTypes.string,
    location: LOCATION,
    locationId: PropTypes.string,
    notes: PropTypes.string,
    orderDate: PropTypes.string,
    orderNumber: PropTypes.number,
    paymentDue: PropTypes.string,
    products: PropTypes.arrayOf(ORDER_PRODUCT),
    status: PropTypes.string,
    supplier: SUPPLIER,
    supplierId: PropTypes.string,
    supplierInvoiceNumber: PropTypes.string,
    updatedAt: PropTypes.string,
  }),
  ORDER_PRODUCT,
  STOCKTAKE: PropTypes.shape({
    createdAt: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    location: LOCATION,
    locationId: PropTypes.string,
    products: PropTypes.arrayOf(STOCKTAKE_PRODUCT),
    status: PropTypes.string,
    stocktakeNumber: PropTypes.number,
    updatedAt: PropTypes.string,
    vendor: VENDOR,
    vendorId: PropTypes.string,
  }),
  STOCK_POSITION: PropTypes.arrayOf(
    PropTypes.shape({
      productId: PropTypes.string,
      quantitySold: PropTypes.number,
    }),
  ),
  SUPPLIER,
  TABLE_ROW: {
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        iconAfter: PropTypes.node,
        iconBefore: PropTypes.node,
        onClick: PropTypes.func,
        size: PropTypes.string,
        value: PropTypes.string,
        variant: PropTypes.string,
      }),
    ),
    bulkAction: PropTypes.shape({
      bulkChecked: PropTypes.arrayOf(PropTypes.string),
      onBulkChange: PropTypes.func,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        editable: PropTypes.bool,
        id: PropTypes.string.isRequired,
        type: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.node]),
        url: PropTypes.string,
      }),
    ).isRequired,
    loading: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  },
  VENDOR,
  VENDOR_SUPPLIER: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
    vendors: PropTypes.arrayOf(PropTypes.string),
  }),
}

export { PROP_TYPES }
