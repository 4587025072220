import { BACKEND_CONSTANTS } from './constants'

import { get, post, patch, destroy } from './helpers'

const createSupplier = async data => post('suppliers', BACKEND_CONSTANTS.SUPPLIER.CREATE_SUPPLIER, { supplier: data })
const deleteSupplier = async id => destroy(`suppliers/${id}`, BACKEND_CONSTANTS.SUPPLIER.DELETE_SUPPLIER)
const getSupplier = async id => get(`suppliers/${id}`, BACKEND_CONSTANTS.SUPPLIER.GET_SUPPLIER)
const getSupplierVariants = async supplierId =>
  get(`suppliers/${supplierId}/variants`, BACKEND_CONSTANTS.SUPPLIER.GET_SUPPLIER_VARIANTS)
const getSuppliers = async () => get('suppliers', BACKEND_CONSTANTS.SUPPLIER.GET_SUPPLIERS)
const getXeroContacts = async () => get('get/xero/contacts', BACKEND_CONSTANTS.SUPPLIER.GET_XERO_CONTACTS)
const updateSupplier = async (id, data) => patch(`suppliers/${id}`, BACKEND_CONSTANTS.SUPPLIER.UPDATE_SUPPLIER, data)

export {
  createSupplier,
  deleteSupplier,
  getSupplier,
  getSupplierVariants,
  getSuppliers,
  getXeroContacts,
  updateSupplier,
}
