import React, { useEffect, useState, useRef } from 'react'

import { Loading } from '../components/Loading'
import { VendorsHeader } from '../components/Layouts/Vendors/VendorsHeader'
import { VendorsTable } from '../components/Layouts/Vendors/VendorsTable'

import { getSuppliers } from '../backend/suppliers'
import { getVendors } from '../backend/vendors'
import { toVendorSuppliers } from '../backend/transform'
import { useFeedback } from '../hooks/useFeedback'

const Vendors = () => {
  const isMounted = useRef(true)
  const [suppliers, setSuppliers] = useState()
  const [vendors, setVendors] = useState()
  const { setError } = useFeedback()

  useEffect(() => {
    getData()

    return () => {
      isMounted.current = false
    }
  }, [])

  async function getData() {
    const suppliersRes = await getSuppliers()
    const vendorsRes = await getVendors()

    if (!isMounted.current) return
    if (!suppliersRes.success) setError(suppliersRes)
    if (!vendorsRes.success) setError(vendorsRes)
    if (vendorsRes.success) {
      setSuppliers(toVendorSuppliers(suppliersRes.data))
      setVendors(vendorsRes.data)
    }
  }

  return !vendors ? (
    <Loading />
  ) : (
    <div className="max-w-site mb-8">
      <VendorsHeader />
      <VendorsTable suppliers={suppliers} vendors={vendors} />
    </div>
  )
}

export { Vendors }
