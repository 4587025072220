import React from 'react'
import PropTypes from 'prop-types'
import Picker from '@wojtekmaj/react-daterange-picker/dist/entry.nostyle'
import { FaCalendarAlt } from 'react-icons/fa'

const DateRangePicker = ({ className, onChange, value, ...props }) => (
  <Picker
    calendarIcon={<FaCalendarAlt />}
    className={className}
    clearIcon={null}
    format="dd/MM/y"
    onChange={onChange}
    value={value}
    {...props}
  />
)

DateRangePicker.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
}

DateRangePicker.defaultProps = {
  className: '',
}

export { DateRangePicker }
