import React, { useState, useEffect } from 'react'
import queryString from 'query-string'
import PropTypes from 'prop-types'
import { navigate } from '@reach/router'

import { CreateOrderModal } from '../components/Layouts/Orders/CreateOrderModal'
import { DeleteOrderModal } from '../components/Layouts/Orders/DeleteOrderModal'
import { Loading } from '../components/Loading'
import { OrdersHeader } from '../components/Layouts/Orders/OrdersHeader'
import { OrdersTable } from '../components/Layouts/Orders/OrdersTable'
import { Tabs } from '../components/Tabs'

import { CONSTANTS } from '../common/constants'
import { useOrders } from '../hooks/useOrders'
import { useLocations } from '../hooks/useLocations'
import { useSuppliers } from '../hooks/useSuppliers'

const Orders = ({ location }) => {
  const [currentTab, setCurrentTab] = useState(CONSTANTS.STATUS.DRAFT)
  const [currentOrderId, setCurrentOrderId] = useState()
  const [modal, setModal] = useState('receive')
  const { createOrder, deleteOrder, isFetchingOrders, isUpdatingOrders, orders, orderIdXero, sendToXero } = useOrders()
  const { locations, isFetchingLocations } = useLocations()
  const { suppliers, isFetchingSuppliers } = useSuppliers()

  useEffect(() => {
    if (orders && !isUpdatingOrders) setModal()
  }, [isUpdatingOrders])

  useEffect(() => {
    const parsed = queryString.parse(location.search)

    if (parsed.tab) setCurrentTab(parsed.tab.toUpperCase())
  }, [])

  function handleSetTab(tab) {
    navigate(`/orders?tab=${tab.toLowerCase()}`)
    setCurrentTab(tab)
  }

  function getFilteredOrders() {
    return currentTab === 'ALL' ? orders : orders.filter(({ status }) => status === currentTab)
  }

  function handleDeleteOrder(orderId) {
    setCurrentOrderId(orderId)
    setModal('delete')
  }

  if (!orders || !locations || !suppliers || isFetchingOrders || isFetchingLocations || isFetchingSuppliers)
    return <Loading />

  return (
    <>
      <OrdersHeader onCreateClick={suppliers.length ? () => setModal('create') : null} />
      <Tabs
        className="w-full"
        content={
          <OrdersTable
            currentTab={currentTab}
            deleteOrder={handleDeleteOrder}
            locations={locations}
            orders={orders ? getFilteredOrders() : []}
            orderIdXero={orderIdXero}
            sendToXero={sendToXero}
            suppliers={suppliers}
          />
        }
        currentTab={currentTab}
        maxWidth="none"
        onTabClick={handleSetTab}
        tabs={[
          {
            id: CONSTANTS.STATUS.DRAFT,
            label: 'Draft',
          },
          {
            id: CONSTANTS.STATUS.SENT,
            label: 'Sent',
          },
          {
            id: CONSTANTS.STATUS.PARTIALLY_RECEIVED,
            label: 'Partially Received',
          },
          {
            id: CONSTANTS.STATUS.RECEIVED,
            label: 'Received',
          },
          {
            id: 'ALL',
            label: 'All',
          },
        ]}
      />
      {suppliers.length > 0 && locations.length > 0 && (
        <CreateOrderModal
          isOpen={modal === 'create'}
          loading={isUpdatingOrders}
          locations={locations}
          onClose={setModal}
          onSubmit={createOrder}
          suppliers={suppliers}
        />
      )}
      <DeleteOrderModal
        isOpen={modal === 'delete'}
        loading={isUpdatingOrders}
        onClose={() => setModal()}
        onSubmit={() => deleteOrder(currentOrderId)}
      />
    </>
  )
}

Orders.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
}

Orders.defaultProps = {
  location: { search: '' },
}

export { Orders }
