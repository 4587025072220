import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { debounce } from 'throttle-debounce'

import { Input } from './Input'

const AutoComplete = ({ className, getResults, id, label, placeholder, renderResult }) => {
  const [results, setResults] = useState(getResults(''))
  const [query, setQuery] = useState('')
  const [queried, setQueried] = useState()

  const handleGetResults = useCallback(
    debounce(200, q => {
      setResults(getResults(q))

      if (!queried) setQueried(true)
    }),
    [],
  )

  function onChange(q) {
    setQuery(q)
    handleGetResults(q.toString().trim().toLowerCase())
  }

  return (
    <div className={`pb-64 ${className}`}>
      <Input
        id={id}
        className="w-full"
        label={label}
        onChange={q => onChange(q)}
        placeholder={placeholder}
        value={query}
      />
      <div className="mt-4 relative">
        <div className="absolute top-0 left-0 w-full h-64 overflow-y-auto">
          {results && results.map(renderResult)}
          {queried && !results.length && <p>No results found.</p>}
        </div>
      </div>
    </div>
  )
}

AutoComplete.propTypes = {
  className: PropTypes.string,
  getResults: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  renderResult: PropTypes.func.isRequired,
}

AutoComplete.defaultProps = {
  className: '',
  label: null,
  placeholder: 'Start typing',
}

AutoComplete.displayName = 'AutoComplete'

export { AutoComplete }
