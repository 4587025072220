import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from '@reach/router'
import { FaCog, FaSignOutAlt, FaUserCircle } from 'react-icons/fa'
import { css } from '@emotion/core'

import { Logo } from './SVG/Logo'
import { MenuItem } from './MenuItem'
import { Routes } from './Routes'
import { SidebarLink } from './SidebarLink'
import { Sync } from './Sync'

import { logout } from '../backend/authenticate'
import { useFeedback } from '../hooks/useFeedback'
import { useOverlay } from '../hooks/useOverlay'
import { SyncJob } from './SyncJob'

const PageLayout = ({ onLogout, shopUrl }) => {
  const [dropdownRef, dropdown, setDropdown] = useOverlay()
  const { getSyncJobs, setError, syncJobs } = useFeedback()

  const headerHeight = '2.8rem'

  useEffect(() => {
    getSyncJobs()
  }, [])

  function onMenuClick() {
    setDropdown('menu')
  }

  async function handleLogout() {
    setDropdown()

    const res = await logout()

    if (res.error) setError({ code: 'Whoops!' })
    if (res.success) onLogout()
  }

  return (
    <>
      <header className="fixed top-0 left-0 w-full z-20">
        <ul
          ref={dropdownRef}
          className={`
            list-none text-white absolute top-full right-0 bg-orange rounded-bl transition duration-200 ease-linear transform min-w-72
            ${dropdown === 'menu' ? 'translate-y-0' : '-translate-y-full'}
          `}
        >
          {syncJobs.length > 0 && (
            <div className="pt-2">
              {syncJobs.map(job => (
                <SyncJob key={job.jid} {...job} />
              ))}
            </div>
          )}
          <MenuItem
            className="capitalize"
            onClick={handleLogout}
            icon={<FaSignOutAlt />}
            value={`Logout of ${shopUrl.split('.my')[0].replace('-', ' ')}`}
          />
        </ul>
        <div
          className="w-full bg-black flex justify-between items-stretch relative"
          css={css`
            height: ${headerHeight};
          `}
        >
          <Link className="text-white px-3 py-2 inline-block w-24 flex items-center" to="/orders">
            <Logo className="w-full" />
          </Link>
          <div className="px-2 flex items-center">
            <div
              className="px-3 cursor-pointer h-full flex items-center transition duration-200 ease-linear text-xl text-white"
              onClick={onMenuClick}
            >
              <Sync synced={syncJobs.some(job => job.status !== 'complete') ? false : true} />
              <FaUserCircle />
            </div>
          </div>
        </div>
      </header>
      <aside
        className="bg-gray-100 fixed h-screen top-0 w-64 z-10 flex flex-col justify-between"
        css={css`
          padding-top: ${headerHeight};
        `}
      >
        <nav className="w-full">
          <SidebarLink to="/orders">Purchase Orders</SidebarLink>
          <SidebarLink to="/transfers">Transfers</SidebarLink>
          <SidebarLink to="/stocktakes">Stocktakes</SidebarLink>
          <SidebarLink to="/suppliers">Suppliers</SidebarLink>
          <SidebarLink to="/vendors">Vendors</SidebarLink>
          <SidebarLink to="/integrations">Integrations</SidebarLink>
        </nav>
        <div>
          <SidebarLink className="flex items-center" to="/control-panel">
            <span className="mr-2">
              <FaCog />
            </span>{' '}
            Control Panel
          </SidebarLink>
        </div>
      </aside>
      <div className="pl-64 mt-10">
        <div className="px-5 py-4">
          <Routes />
        </div>
      </div>
    </>
  )
}

PageLayout.propTypes = {
  onLogout: PropTypes.func.isRequired,
  shopUrl: PropTypes.string,
}

PageLayout.defaultProps = {
  shopUrl: '',
}

export { PageLayout }
