import { BACKEND_CONSTANTS } from './constants'

import { get, patch, post, destroy } from './helpers'
import { CONSTANTS } from '../common/constants'

const addProducts = async (orderId, productIds) =>
  post(`orders/${orderId}/add_variants`, BACKEND_CONSTANTS.ORDER.ADD_PRODUCT, { productIds })

const createOrder = async data => post('orders', BACKEND_CONSTANTS.ORDER.CREATE_ORDER, data)
const deleteOrder = async id =>
  destroy(`orders/${id}`, BACKEND_CONSTANTS.ORDER.DELETE_ORDER, { status: CONSTANTS.STATUS.DELETED })

const getOrder = async id => get(`orders/${id}`, BACKEND_CONSTANTS.ORDER.GET_ORDER)
const getOrders = async orderKind => get(`orders?kind=${orderKind.toLowerCase()}`, BACKEND_CONSTANTS.ORDER.GET_ORDERS)

const fulfillOrder = async (orderId, data = {}) =>
  post(`orders/${orderId}/fulfill_transfer`, BACKEND_CONSTANTS.ORDER.FULFILL, data)

const receiveOrder = async (orderKind, orderId, markAsRecieved = false) => {
  const data = markAsRecieved ? { status: CONSTANTS.STATUS.RECEIVED } : {}

  return post(
    `orders/${orderId}/receive_${orderKind.toLowerCase()}`,
    BACKEND_CONSTANTS.ORDER[`RECEIVE_${orderKind}`],
    data,
  )
}

const sendOrder = async (orderId, data = {}) => post(`orders/${orderId}/send_order`, BACKEND_CONSTANTS.ORDER.SEND, data)
const sendToXero = async orderId => post(`orders/${orderId}/create_invoice`, BACKEND_CONSTANTS.ORDER.SEND_TO_XERO, {})
const syncOrder = async (orderId, markAsPartiallyReceived = false) => {
  const data = markAsPartiallyReceived ? { status: CONSTANTS.STATUS.PARTIALLY_RECEIVED } : {}
  return post(`orders/${orderId}/receive_purchase`, BACKEND_CONSTANTS.ORDER.SYNC, data)
}
const updateOrder = async (orderId, data) => patch(`orders/${orderId}`, BACKEND_CONSTANTS.ORDER.UPDATE, data)

export {
  addProducts,
  deleteOrder,
  createOrder,
  fulfillOrder,
  getOrder,
  getOrders,
  receiveOrder,
  sendOrder,
  sendToXero,
  syncOrder,
  updateOrder,
}
