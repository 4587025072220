import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'

import { Loading } from '../components/Loading'
import { NotFound } from './NotFound'
import { VendorHeader } from '../components/Layouts/Vendor/VendorHeader'
import { VendorProductsTable } from '../components/Layouts/Vendor/VendorProductsTable'

import { getVendor } from '../backend/vendors'
import { useFeedback } from '../hooks/useFeedback'

const Vendor = ({ parent, vendorId }) => {
  const isMounted = useRef(true)
  const [notFound, setNotFound] = useState()
  const [vendor, setVendor] = useState()
  const { setError } = useFeedback()

  useEffect(() => {
    getData()

    return () => {
      isMounted.current = false
    }
  }, [vendorId])

  async function getData() {
    const res = await getVendor(vendorId)

    if (!isMounted.current) return
    if (!res.success && res.code === 404) setNotFound(true)
    if (!res.success && res.code !== 404) setError(res)
    if (res.success) setVendor(res.data)
  }

  if (notFound) return <NotFound />

  return !vendorId || !vendor ? (
    <Loading />
  ) : (
    <div>
      <VendorHeader parent={parent} id={vendorId} name={vendor.name} />
      <VendorProductsTable products={vendor.products} />
    </div>
  )
}

Vendor.propTypes = {
  parent: PropTypes.shape({
    to: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  vendorId: PropTypes.string,
}

Vendor.defaultProps = {
  vendorId: null,
}

export { Vendor }
