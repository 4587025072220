import { BACKEND_CONSTANTS } from './constants'

import { get, patch, post, destroy } from './helpers'
import { CONSTANTS } from '../common/constants'

const beginStocktake = async (stocktakeId, data = {}) =>
  post(`stocktakes/${stocktakeId}/begin`, BACKEND_CONSTANTS.STOCKTAKE.BEGIN_STOCKTAKE, data)

const completeStocktake = async stocktakeId =>
  post(`stocktakes/${stocktakeId}/complete`, BACKEND_CONSTANTS.STOCKTAKE.COMPLETE_STOCKTAKE, {
    status: CONSTANTS.STATUS.COUNTED,
  })

const createStocktake = async data => post('stocktakes', BACKEND_CONSTANTS.STOCKTAKE.CREATE_ORDER, data)
const deleteStocktake = async id =>
  destroy(`stocktakes/${id}`, BACKEND_CONSTANTS.STOCKTAKE.DELETE_STOCKTAKE, { status: CONSTANTS.STATUS.DELETED })

const getStocktake = async id => get(`stocktakes/${id}`, BACKEND_CONSTANTS.STOCKTAKE.GET_STOCKTAKE)
const getStocktakes = async () => get('stocktakes', BACKEND_CONSTANTS.STOCKTAKE.GET_STOCKTAKES)
const updateStocktake = async (stocktakeId, data) =>
  patch(`stocktakes/${stocktakeId}`, BACKEND_CONSTANTS.STOCKTAKE.UPDATE, data)

export {
  beginStocktake,
  completeStocktake,
  createStocktake,
  deleteStocktake,
  getStocktake,
  getStocktakes,
  updateStocktake,
}
