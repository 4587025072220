import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FaTrash, FaPlus } from 'react-icons/fa'

import { Button } from '../../Button'
import { CreateExpenseModal } from './CreateExpenseModal'
import { DeleteExpenseModal } from './DeleteExpenseModal'
import { EmptyTableRow } from '../../EmptyStates/EmptyTableRow'
import { Table } from '../../Table'
import { TableCell } from '../../TableCell'
import { TableCellInput } from '../../TableCellInput'
import { TableHeadCell } from '../../TableHeadCell'
import { TableHeadRow } from '../../TableHeadRow'

import { PROP_TYPES } from '../../../common/propTypes'
import { roundToTwo } from '../../../helpers'

const OrderExpensesTable = ({
  createExpense,
  deleteExpense,
  expenses,
  loading,
  modalLoading,
  readOnly,
  updateExpense,
}) => {
  const [currentExpense, setCurrentExpense] = useState()
  const [modal, setModal] = useState()

  useEffect(() => {
    if (!modalLoading) setModal()
  }, [modalLoading])

  function handleDeleteClick(expenseId) {
    setCurrentExpense(expenses.find(({ id }) => id === expenseId))
    setModal('delete')
  }

  return (
    <div className="w-full">
      <div className="flex justify-between">
        <h2 className="font-bold">Fixed Expenses</h2>
        {!readOnly && (
          <Button
            disabled={loading}
            iconBefore={<FaPlus />}
            onClick={() => setModal('create')}
            size="small"
            value="Add Expense"
            variant="secondary"
          />
        )}
      </div>
      <Table
        className="mt-2"
        disabled={readOnly}
        fullWidth
        renderHead={() => (
          <TableHeadRow>
            <TableHeadCell value="Description" width="3/4" />
            <TableHeadCell value="Amount" width="1/4" />
            {!readOnly && <TableHeadCell alignment="right" width="32" value="Actions" />}
          </TableHeadRow>
        )}
        renderBody={() => (
          <>
            {expenses.map(({ id, amount, description }) => (
              <tr key={id}>
                <TableCell
                  slim
                  value={
                    !readOnly ? (
                      <TableCellInput
                        className="capitalize"
                        disabled={loading}
                        onChange={value => updateExpense(id, 'description', value)}
                        value={description}
                      />
                    ) : (
                      description
                    )
                  }
                />
                <TableCell
                  slim
                  value={
                    !readOnly ? (
                      <TableCellInput
                        className="capitalize"
                        disabled={loading}
                        onChange={value => updateExpense(id, 'amount', value ? roundToTwo(parseFloat(value)) * 100 : 0)}
                        type="number"
                        value={roundToTwo(amount / 100)}
                      />
                    ) : (
                      roundToTwo(amount / 100)
                    )
                  }
                />
                {!readOnly && (
                  <TableCell
                    alignment="right"
                    value={
                      <span className="flex items-center justify-end inline-block">
                        <Button
                          disabled={loading}
                          size="small"
                          variant="ghost"
                          iconBefore={<FaTrash />}
                          onClick={() => handleDeleteClick(id)}
                        />
                      </span>
                    }
                  />
                )}
              </tr>
            ))}
            {expenses.length < 1 && <EmptyTableRow columns={['description', 'amount']} hasActions />}
          </>
        )}
      />
      {modal === 'create' && (
        <CreateExpenseModal isOpen loading={modalLoading} onClose={() => setModal()} onSubmit={createExpense} />
      )}
      <DeleteExpenseModal
        expense={currentExpense}
        isOpen={currentExpense && modal === 'delete'}
        loading={modalLoading}
        onClose={() => setModal()}
        onSubmit={deleteExpense}
      />
    </div>
  )
}

OrderExpensesTable.propTypes = {
  createExpense: PropTypes.func.isRequired,
  deleteExpense: PropTypes.func.isRequired,
  expenses: PROP_TYPES.EXPENSES.isRequired,
  loading: PropTypes.bool,
  modalLoading: PropTypes.bool,
  readOnly: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  updateExpense: PropTypes.func.isRequired,
}

OrderExpensesTable.defaultProps = {
  loading: false,
  modalLoading: false,
  readOnly: false,
}

export { OrderExpensesTable }
