import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { animated, useTransition, useSpring } from 'react-spring'
import { FaCheck, FaSyncAlt, FaExclamationTriangle } from 'react-icons/fa'

const Error = props => (
  <div className="flex items-center justify-center text-sm text-red" {...props}>
    <span className="mr-2">Error</span> <FaExclamationTriangle />
  </div>
)

const Synced = props => (
  <div className="flex items-center justify-center text-sm" {...props}>
    <span className="mr-2">Synced</span> <FaCheck />
  </div>
)

const Syncing = props => {
  const { transform } = useSpring({
    loop: true,
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
    config: {
      duration: 1000,
    },
  })

  return (
    <div className="flex items-center justify-center text-sm" {...props}>
      <span className="mr-2">Syncing...</span>{' '}
      <animated.span
        style={{
          transform,
        }}
      >
        <FaSyncAlt />
      </animated.span>
    </div>
  )
}

const Sync = ({ synced }) => {
  const timer = useRef()
  const [visible, setVisible] = useState()
  const mountTransitions = useTransition(visible, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  useEffect(() => {
    if (!synced) setVisible(true)
    if (synced) {
      timer.current = setTimeout(() => {
        setVisible()
      }, 3000)
    }

    return () => {
      clearTimeout(timer.current)
    }
  }, [synced])

  return mountTransitions.map(
    ({ item, key, props }) =>
      item && (
        <animated.div className="flex justify-center w-32 rounded-tl text-gray-200" key={key} style={props}>
          {synced === true && <Synced />}
          {synced === 'error' && <Error />}
          {!synced && <Syncing />}
        </animated.div>
      ),
  )
}

Sync.propTypes = {
  synced: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
}

Sync.defaultProps = {
  synced: true,
}

export { Sync }
