import React from 'react'
import PropTypes from 'prop-types'

import { theme } from '../../../tailwind.config'

const Logo = ({ accent, fill, ...props }) => (
  <svg fill={fill} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 124.2 46.2" {...props}>
    <path d="M0 40.5v-6.8c2.8 2.6 6.4 3.9 10.8 3.9 1.5 0 2.7-.2 3.6-.8.9-.5 1.3-1.2 1.3-2 0-.6-.3-1.1-.8-1.7-.6-.5-1.9-1.2-4-2.1-2.1-.8-3.4-1.3-3.8-1.5C3 27.7.9 25 .9 21.3c0-2.6 1-4.6 3-6.2 2-1.6 4.5-2.3 7.5-2.3 3.2 0 5.8.7 7.9 2v6.3c-1-.6-2.1-1.1-3.4-1.6-1.3-.4-2.7-.6-4.1-.6-1.3 0-2.3.2-3.1.6s-1.2 1-1.2 1.8c0 .2.1.4.2.8s.5.7 1 1.1c.6.3 1.8.8 3.7 1.5 2.5 1 4.4 1.8 5.7 2.6 1.3.8 2.4 1.7 3.2 2.9a7.5 7.5 0 011.2 4.3c0 1.1-.2 2.2-.7 3.3-.4 1.1-1.1 2-2.1 2.9-.9.9-2.2 1.5-3.7 2s-3.3.8-5.3.8c-4.1.1-7.7-.9-10.7-3z" />
    <path d="M26.6 42.9V2.6h6.9v23.6l9.8-12.4h7.9l-11 13.9 12 15.2H44L33.5 29.4v13.5h-6.9z" />
    <path d="M54.3 31.3V13.7h7v17c0 2.2.6 3.8 1.8 4.9 1.2 1.1 2.7 1.6 4.6 1.6s3.4-.5 4.6-1.6 1.8-2.7 1.8-4.9v-17h7v17.4c0 1.5-.2 3-.7 4.5s-1.3 2.8-2.4 4-2.5 2.1-4.3 2.9c-1.7.7-3.8 1.1-6.1 1.1-2.8 0-5.2-.5-7.1-1.6-2-1.1-3.5-2.5-4.6-4.3-1-1.7-1.6-3.9-1.6-6.4z" />
    <g>
      <path
        d="M115.7 44.7l-10.6-16.1-2-3.1c-.5.9-1.2 2.1-1.9 3.2l-10.4 16h-5.6L100.1 22 90.3 7.1c-.6-1-1.4-1.1-2.6-1.1h-2.1V1.6h2.1c3.4 0 5 .6 7.2 3.9l6.3 9.7c.7 1.1 1.5 2.3 2.1 3.2.5-.9 1.2-2.2 2-3.3l6.3-9.7c2.1-3.3 3.7-3.9 7.2-3.9h2.1v4.3h-2.1c-1.2 0-1.9.2-2.6 1.1l-9.8 14.8 15 23h-5.7 0z"
        fill="none"
        stroke={accent}
        strokeWidth="3"
      />
    </g>
  </svg>
)

Logo.propTypes = {
  accent: PropTypes.string,
  fill: PropTypes.string,
}

Logo.defaultProps = {
  accent: theme.colors['gray-200'],
  fill: theme.colors.white,
}

export { Logo }
