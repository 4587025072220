import { useEffect, useState, useRef } from 'react'
import { navigate } from '@reach/router'

import { CONSTANTS } from '../common/constants'
import {
  beginStocktake as begin,
  completeStocktake as complete,
  getStocktake,
  updateStocktake as update,
} from '../backend/stocktakes'
import {
  updateStocktakeProduct as updateProduct,
  updateStocktakeProducts as updateProducts,
} from '../backend/stocktakeProducts'
import { sortArrayByObjectKey } from '../helpers'
import { useFeedback } from './useFeedback'

const useStocktake = stocktakeId => {
  const isMounted = useRef(true)
  const [isFetchingStocktake, setIsFetchingStocktake] = useState()
  const [isStocktakeModalLoading, setIsStocktakeModalLoading] = useState()
  const [isUpdatingStocktake, setIsUpdatingStocktake] = useState()
  const [notFound, setNotFound] = useState()
  const [stocktake, setStocktake] = useState()
  const { setError, setSaved, setToast } = useFeedback()

  useEffect(() => {
    if (stocktakeId) getData()

    return () => {
      isMounted.current = false
    }
  }, [])

  async function getData() {
    setIsFetchingStocktake(true)
    const res = await getStocktake(stocktakeId)

    if (!isMounted.current) return
    if (!res.success) setNotFound(true)
    if (res.success) setStocktake({ ...res.data, products: sortArrayByObjectKey(res.data.products, 'title') })

    setIsFetchingStocktake()
  }

  async function beginStocktake() {
    setIsStocktakeModalLoading(true)

    const res = await begin(stocktakeId)

    if (!isMounted.current) return
    if (!res.success) setError(res)
    if (res.success) setStocktake(res.data)

    setIsStocktakeModalLoading()
  }

  async function completeStocktake() {
    setIsStocktakeModalLoading(true)

    const res = await complete(stocktakeId)

    if (!isMounted.current) return
    if (!res.success) setError(res)
    if (res.success) {
      navigate('/stocktakes?tab=counted')
      setToast('Stock counted!')
    }

    setIsStocktakeModalLoading()
  }

  async function updateStocktake(data, inline = false) {
    if (inline) {
      setSaved(false)
    } else {
      setIsStocktakeModalLoading(true)
    }

    const res = await update(stocktakeId, data)

    if (!isMounted.current) return
    if (!res.success) setError(res)
    if (!res.success && inline) setSaved('error')

    if (res.success) {
      setStocktake(prev => ({
        ...prev,
        status: res.data.status,
        ...data,
      }))
    }
    if (res.success && inline) setSaved(true)
    if (res.success && !inline) setToast('Stocktake updated!')

    if (!inline) setIsStocktakeModalLoading()
  }

  async function updateStocktakeProduct({
    allowNegative = true,
    field,
    fieldType = CONSTANTS.FIELD_TYPES.STRING,
    id,
    value,
  }) {
    if (fieldType === CONSTANTS.FIELD_TYPES.INT && !allowNegative && Math.sign(value) < 0) {
      setError({ code: 'No negative values allowed!' })
      return
    }

    setSaved(false)

    const res = await updateProduct(id, {
      [field]: value === '' ? null : parseInt(value),
    })

    if (!isMounted.current) return
    if (!res.success) {
      setSaved('error')
      setError(res)
    }

    if (res.success) {
      setStocktake(prev => ({
        ...prev,
        products: prev.products.map(product => (product.id === id ? { ...product, ...res.data } : product)),
      }))
      setSaved(true)
    }
  }

  async function updateStocktakeProducts(stocktakeProducts) {
    setIsUpdatingStocktake(true)

    const res = await updateProducts({ stocktakeId, stocktakeProducts })

    if (!isMounted.current) return
    if (!res.success) setError(res)
    if (res.success) {
      setStocktake(prev => ({ ...prev, products: sortArrayByObjectKey(res.data, 'title') }))
    }

    setIsUpdatingStocktake()
  }

  return {
    beginStocktake,
    completeStocktake,
    isFetchingStocktake,
    isStocktakeModalLoading,
    isUpdatingStocktake,
    notFound,
    stocktake,
    updateStocktake,
    updateStocktakeProduct,
    updateStocktakeProducts,
  }
}

export { useStocktake }
