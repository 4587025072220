import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { debounce } from 'throttle-debounce'

const DebouncedInput = ({ Component, delay, requestHandler, setValue, ...props }) => {
  const debounced = useCallback(debounce(delay, requestHandler), [])

  function handleChange(value) {
    setValue(value)
    debounced(value)
  }

  return <Component onChange={handleChange} {...props} />
}

DebouncedInput.propTypes = {
  Component: PropTypes.elementType.isRequired,
  delay: PropTypes.number,
  requestHandler: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
}

DebouncedInput.defaultProps = {
  delay: 850,
}

export { DebouncedInput }
