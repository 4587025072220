import React from 'react'
import PropTypes from 'prop-types'
import { FaPrint } from 'react-icons/fa'

import { Button } from '../../Button'
import { Header } from '../../Header'
import { Status } from '../../Status'

import { CONSTANTS } from '../../../common/constants'
import { formatDate } from '../../../helpers'
import { PROP_TYPES } from '../../../common/propTypes'

const StocktakeHeader = ({ onPrintClick, parent, stocktake, setModal, ...props }) => {
  const isDraft = stocktake.status === CONSTANTS.STATUS.DRAFT
  const isInProgress = stocktake.status === CONSTANTS.STATUS.IN_PROGRESS

  return (
    <Header
      actions={
        <>
          <Button className="ml-2" iconBefore={<FaPrint />} onClick={onPrintClick} variant="secondary" value="Print" />
          {isDraft && <Button className="ml-2" onClick={() => setModal('begin')} value="Begin counting" />}
          {isInProgress && <Button className="ml-2" onClick={() => setModal('complete')} value="Mark as counted" />}
        </>
      }
      crumbs={[
        { to: parent.to, value: parent.value },
        {
          to: `${parent.to}/${stocktake.id}`,
          value: (
            <div>
              {stocktake.stocktakeNumber} <Status className="mx-2 text-sm" status={stocktake.status} />{' '}
              <span className="text-black inline-block text-sm font-bold">Created at:</span>{' '}
              <span className="text-black inline-block text-sm">{formatDate(stocktake.createdAt)}</span>
            </div>
          ),
        },
      ]}
      {...props}
    />
  )
}

StocktakeHeader.propTypes = {
  onPrintClick: PropTypes.func.isRequired,
  stocktake: PROP_TYPES.STOCKTAKE,
  parent: PropTypes.shape({
    to: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  setModal: PropTypes.func.isRequired,
}

StocktakeHeader.defaultProps = {
  stocktake: null,
}

export { StocktakeHeader }
