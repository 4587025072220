import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FaPlus } from 'react-icons/fa'

import { Input } from '../../Input'
import { Modal } from '../../Modal'

const CreateExpenseModal = ({ isOpen, loading, onClose, onSubmit }) => {
  const [descriptionValue, setDescriptionValue] = useState('')
  const [dollarValue, setDollarValue] = useState(0)
  const [centValue, setCentValue] = useState('00')

  return (
    <Modal
      closeModal={onClose}
      disabled={!descriptionValue || (!dollarValue && !centValue)}
      heading="Add Fixed Expense"
      loading={loading}
      isOpen={isOpen}
      onSubmit={() => onSubmit(descriptionValue, dollarValue, centValue)}
      submitIcon={<FaPlus />}
      submitValue="Add"
    >
      <Input
        className="w-64 mt-6"
        id="expense-description"
        label="Description"
        onChange={setDescriptionValue}
        value={descriptionValue}
      />
      <h3 className="mt-4 font-bold text-sm">Amount</h3>
      <div className="mt-2 flex">
        <div className="flex items-center">
          <span className="inline-block mr-2 font-bold text-lg pt-1">$</span>
          <Input
            alignText="right"
            className="w-16"
            id="expense-dollars"
            onChange={setDollarValue}
            onClick={e => e.target.select()}
            type="number"
            value={dollarValue}
          />
        </div>
        <div className="flex items-center">
          <span className="inline-block font-bold mr-2 text-lg">.</span>
          <Input
            alignText="right"
            className="w-12 text-right"
            id="expense-cents"
            onChange={setCentValue}
            onClick={e => e.target.select()}
            value={centValue}
          />
        </div>
      </div>
    </Modal>
  )
}

CreateExpenseModal.propTypes = {
  isOpen: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  loading: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

CreateExpenseModal.defaultProps = {
  isOpen: false,
  loading: false,
}

export { CreateExpenseModal }
