import { useEffect, useState, useRef } from 'react'
import { navigate } from '@reach/router'

import { CONSTANTS } from '../common/constants'
import { getFieldValueFromType } from '../common/helpers'
import {
  addProducts,
  fulfillOrder as fulfill,
  getOrder,
  receiveOrder as receive,
  sendOrder as send,
  syncOrder as sync,
  updateOrder as update,
} from '../backend/orders'
import { createFixedExpense, deleteFixedExpense, updateFixedExpense } from '../backend/expenses'
import {
  deleteOrderProducts as deleteProducts,
  updateOrderProduct as updateProduct,
  updateOrderProducts as updateProducts,
} from '../backend/orderProducts'
import { sortArrayByObjectKey } from '../helpers'
import { useFeedback } from './useFeedback'

const useOrder = orderId => {
  const isMounted = useRef(true)
  const [isDeletingSingleProduct, setIsDeletingSingleProduct] = useState()
  const [isFetchingOrder, setIsFetchingOrder] = useState()
  const [isOrderModalLoading, setIsOrderModalLoading] = useState()
  const [isUpdatingOrder, setIsUpdatingOrder] = useState()
  const [notFound, setNotFound] = useState()
  const [order, setOrder] = useState()
  const { setError, setSaved, setToast } = useFeedback()

  useEffect(() => {
    if (orderId) getData()

    return () => {
      isMounted.current = false
    }
  }, [])

  async function getData() {
    setIsFetchingOrder(true)
    const res = await getOrder(orderId)

    if (!isMounted.current) return
    if (!res.success) setNotFound(true)
    if (res.success) setOrder({ ...res.data, products: sortArrayByObjectKey(res.data.products, 'title') })

    setIsFetchingOrder()
  }

  async function addOrderProducts(productIds) {
    setIsOrderModalLoading(true)
    const res = await addProducts(orderId, productIds)

    if (!isMounted.current) return
    if (!res.success) setError(res)
    if (res.success) {
      setOrder({ ...order, products: [...order.products, ...res.data] })
      setToast('Products added!')
    }

    setIsOrderModalLoading()
  }

  async function createExpense(descriptionValue, dollarValue, centValue) {
    setIsOrderModalLoading(true)

    const res = await createFixedExpense({
      order_id: orderId,
      description: descriptionValue,
      amount: parseInt(dollarValue) * 100 + parseInt(centValue),
    })

    if (!isMounted.current) return
    if (!res.success) setError(res)
    if (res.success) {
      setOrder({ ...order, fixedExpenses: [...order.fixedExpenses, res.data] })
      setToast('Expense added!')
    }

    setIsOrderModalLoading()
  }

  async function deleteExpense(fixedExpenseId) {
    setIsOrderModalLoading(true)

    const res = await deleteFixedExpense(fixedExpenseId)

    if (!isMounted.current) return
    if (!res.success) setError(res)
    if (res.success) {
      setOrder({ ...order, fixedExpenses: order.fixedExpenses.filter(({ id }) => id !== fixedExpenseId) })
      setToast('Expense removed!')
    }

    setIsOrderModalLoading()
  }

  async function deleteOrderProduct(orderProductId) {
    setIsDeletingSingleProduct(true)
    const res = await deleteProducts([orderProductId])

    if (!isMounted.current) return
    if (!res.success) setError(res)
    if (res.success) {
      setOrder(prevOrder => ({
        ...prevOrder,
        products: prevOrder.products.filter(product => orderProductId !== product.id),
      }))
      setToast('Products removed!')
    }

    setIsDeletingSingleProduct()
  }

  async function deleteOrderProducts(orderProductIds) {
    setIsUpdatingOrder(true)
    const res = await deleteProducts(orderProductIds)

    if (!isMounted.current) return
    if (!res.success) setError(res)
    if (res.success) {
      setOrder(prevOrder => ({
        ...prevOrder,
        products: prevOrder.products.filter(product => !orderProductIds.includes(product.id)),
      }))
      setToast('Products removed!')
    }

    setIsUpdatingOrder()
  }

  async function fulfillOrder() {
    setIsOrderModalLoading(true)

    const res = await fulfill(orderId)

    if (!isMounted.current) return
    if (!res.success) setError(res)
    if (res.success) {
      navigate('/transfers?tab=fulfilled')
      setToast('Order fulfilled!')
    }

    setIsOrderModalLoading()
  }

  async function receiveOrder(orderKind = CONSTANTS.KIND.PURCHASE) {
    setIsOrderModalLoading(true)

    const isPurchase = orderKind === CONSTANTS.KIND.PURCHASE

    const res = await receive(orderKind, orderId, isPurchase)

    if (!isMounted.current) return
    if (!res.success) setError(res)
    if (res.success) {
      navigate(`/${isPurchase ? 'orders' : 'transfers'}?tab=received`)
      setToast('Order received!')
    }

    setIsOrderModalLoading()
  }

  async function requestOrder() {
    setIsOrderModalLoading(true)

    const res = await send(orderId)

    if (!isMounted.current) return
    if (!res.success) setError(res)
    if (res.success) {
      navigate('/transfers?tab=requested')
      setToast('Order requested!')
    }

    setIsOrderModalLoading()
  }

  async function sendOrder() {
    setIsOrderModalLoading(true)

    const res = await send(orderId)

    if (!isMounted.current) return
    if (!res.success) setError(res)
    if (res.success) {
      navigate('/orders?tab=sent')
      setToast('Order sent!')
    }

    setIsOrderModalLoading()
  }

  async function syncOrder(isSent) {
    setIsOrderModalLoading(true)

    const res = await sync(orderId, isSent)

    if (!isMounted.current) return
    if (!res.success) setError(res)
    if (res.success) {
      navigate('/orders?tab=partially_received')
      setToast('Sync complete!')
    }

    setIsOrderModalLoading()
  }

  async function updateExpense(fixedExpenseId, field, value) {
    setSaved(false)

    const res = await updateFixedExpense(fixedExpenseId, { [field]: value })

    if (!isMounted.current) return
    if (!res.success) {
      setSaved('error')
      setError(res)
    }
    if (res.success) {
      setOrder(prevOrder => ({
        ...prevOrder,
        fixedExpenses: order.fixedExpenses.map(expense => (expense.id === res.data.id ? res.data : expense)),
      }))
      setSaved(true)
    }
  }

  async function updateOrder(data, inline = false) {
    if (inline) {
      setSaved(false)
    } else {
      setIsOrderModalLoading(true)
    }

    const res = await update(orderId, data)

    if (!isMounted.current) return
    if (!res.success) setError(res)
    if (!res.success && inline) setSaved('error')

    if (res.success) {
      setOrder(prevOrder => ({
        ...prevOrder,
        status: res.data.status,
        ...data,
      }))
    }
    if (res.success && inline) setSaved(true)
    if (res.success && !inline) setToast('Order updated!')

    if (!inline) setIsOrderModalLoading()
  }

  async function updateOrderProduct({
    allowNegative = false,
    field,
    fieldType = CONSTANTS.FIELD_TYPES.STRING,
    id,
    value,
  }) {
    if (fieldType === CONSTANTS.FIELD_TYPES.INT && !allowNegative && Math.sign(value) < 0) {
      setError({ code: 'No negative values allowed!' })
      return
    }

    setSaved(false)

    const res = await updateProduct(id, {
      [field]: getFieldValueFromType(fieldType, value),
    })

    if (!isMounted.current) return
    if (!res.success) {
      setSaved('error')
      setError(res)
    }

    if (res.success) {
      setOrder(prevOrder => ({
        ...prevOrder,
        products: prevOrder.products.map(product => (product.id === id ? { ...product, ...res.data } : product)),
      }))
      setSaved(true)
    }
  }

  async function updateOrderProducts(orderProducts) {
    setIsUpdatingOrder(true)

    const res = await updateProducts({ orderId, orderProducts })

    if (!isMounted.current) return
    if (!res.success) setError(res)
    if (res.success) {
      setOrder(prevOrder => ({ ...prevOrder, products: sortArrayByObjectKey(res.data, 'title') }))
    }

    setIsUpdatingOrder()
  }

  return {
    addOrderProducts,
    createExpense,
    deleteExpense,
    deleteOrderProduct,
    deleteOrderProducts,
    fulfillOrder,
    isDeletingSingleProduct,
    isFetchingOrder,
    isOrderModalLoading,
    isUpdatingOrder,
    notFound,
    order,
    receiveOrder,
    requestOrder,
    sendOrder,
    syncOrder,
    updateExpense,
    updateOrderProducts,
    updateOrder,
    updateOrderProduct,
  }
}

export { useOrder }
