import React from 'react'
import PropTypes from 'prop-types'
import { FaTrash } from 'react-icons/fa'

import { Checkbox } from '../../Checkbox'
import { Table } from '../../Table'
import { TableCell } from '../../TableCell'
import { TableHeadCell } from '../../TableHeadCell'
import { TableHeadRow } from '../../TableHeadRow'
import { TableRow } from '../../TableRow'
import { TableRowActions } from '../../TableRowActions'

import { CONSTANTS } from '../../../common/constants'
import { getFieldValueFromType } from '../../../common/helpers'
import { PROP_TYPES } from '../../../common/propTypes'
import { roundToTwo } from '../../../helpers'
import { useSupplierCode } from '../../../hooks/useSupplierCode'

const OrderTable = ({
  deleteOrderProducts,
  handleDeleteProduct,
  isEditing,
  isFetchingPosition,
  order,
  stockPosition,
  updateOrderProduct,
}) => {
  const { updateSupplierCode } = useSupplierCode()

  const isDraft = order.status === CONSTANTS.STATUS.DRAFT
  const isSent = order.status === CONSTANTS.STATUS.SENT
  const isSentNotEditable = isSent && !isEditing
  const isPartial = order.status === CONSTANTS.STATUS.PARTIALLY_RECEIVED
  const isReceived = order.status === CONSTANTS.STATUS.RECEIVED

  function handleSupplierCodeChange(productSupplierId, value) {
    return updateSupplierCode(productSupplierId, getFieldValueFromType(CONSTANTS.FIELD_TYPES.STRING, value))
  }

  function handleUpdateOrderProduct(id, field, value, fieldType) {
    return updateOrderProduct({
      field,
      fieldType,
      id,
      value,
    })
  }

  return (
    order && (
      <Table
        bulkAction={isDraft ? deleteOrderProducts : null}
        childIds={order.products.map(({ id }) => id)}
        className="mt-6 overflow-hidden"
        renderHead={({ bulkChecked, onBulkChange }) => (
          <TableHeadRow>
            {isDraft && (
              <TableHeadCell
                slim
                width="4"
                value={
                  <Checkbox
                    disabled={!order.products.length}
                    checked={bulkChecked.length >= order.products.length}
                    onChange={() => onBulkChange('all', bulkChecked.length >= order.products.length)}
                  />
                }
              />
            )}
            <TableHeadCell value="Product" width="1/3" />
            <TableHeadCell value="SKU" width="32" />
            <TableHeadCell value="Supplier Code" width="32" />
            {isDraft && (
              <>
                <TableHeadCell value="In Stock" />
                <TableHeadCell value="On Order" />
                <TableHeadCell value="Sold" />
              </>
            )}
            <TableHeadCell value={`Qty${!isDraft ? ' Ordered' : ''}`} />
            {!isDraft && !isSentNotEditable && <TableHeadCell value="Qty Received" />}
            {!isDraft && !isSent && <TableHeadCell value="Qty Synced" />}
            <TableHeadCell value="Prev $" />
            {!isDraft && !isSentNotEditable && <TableHeadCell value="Paid $" />}
            {isDraft ? <TableHeadCell value="Estimate $" /> : <TableHeadCell value="Total $" />}
            {(isDraft || isSentNotEditable || isPartial) && (
              <TableHeadCell alignment="right" width="32" value="Actions" />
            )}
          </TableHeadRow>
        )}
        renderBody={({ bulkChecked, onBulkChange }) => (
          <>
            {order.products.map(
              ({
                id,
                lastPricePaid,
                costPaid,
                productSupplierId,
                quantityOrdered,
                quantityReceived,
                quantitySynced,
                sku,
                supplierCode,
                title,
                url,
              }) => {
                const productPosition = stockPosition && stockPosition.find(p => p.id === id)
                return (
                  <TableRow key={id}>
                    {isDraft && (
                      <TableCell
                        slim
                        width="4"
                        value={
                          <Checkbox
                            checked={bulkChecked.includes(id)}
                            onChange={() => onBulkChange(id, bulkChecked.includes(id))}
                            tabIndex="-1"
                          />
                        }
                      />
                    )}
                    <TableCell width="1/3" value={title} url={url} tabIndex="-1" />
                    <TableCell width="32" capitalize={false} value={sku} />
                    <TableCell
                      onChange={value => handleSupplierCodeChange(productSupplierId, value)}
                      width="32"
                      tabIndex="-1"
                      type="string"
                      value={supplierCode}
                    />
                    {isDraft && (
                      <>
                        <TableCell
                          loading={isFetchingPosition}
                          type="number"
                          value={(productPosition && productPosition.quantityInStock) || 0}
                        />
                        <TableCell
                          loading={isFetchingPosition}
                          type="number"
                          value={(productPosition && productPosition.quantityOnOrder) || 0}
                        />
                        <TableCell
                          loading={isFetchingPosition}
                          type="number"
                          value={(productPosition && productPosition.quantitySold) || 0}
                        />
                      </>
                    )}
                    <TableCell
                      key={`${id}-${quantityOrdered}`}
                      onChange={
                        isDraft
                          ? value => handleUpdateOrderProduct(id, 'quantityOrdered', value, CONSTANTS.FIELD_TYPES.INT)
                          : null
                      }
                      type="number"
                      value={
                        !isEditing && !isPartial ? (
                          quantityOrdered
                        ) : (
                          <div className="flex justify-between">
                            <span>{quantityOrdered}</span>
                            <button
                              className="px-1 mr-1 border border-black"
                              title="Fill quantity"
                              onClick={() =>
                                handleUpdateOrderProduct(
                                  id,
                                  'quantityReceived',
                                  quantityOrdered,
                                  CONSTANTS.FIELD_TYPES.INT,
                                )
                              }
                            >
                              &#8594;
                            </button>
                          </div>
                        )
                      }
                    />
                    {!isDraft && !isSentNotEditable && (
                      <TableCell
                        onChange={
                          !isReceived
                            ? value =>
                                handleUpdateOrderProduct(id, 'quantityReceived', value, CONSTANTS.FIELD_TYPES.INT)
                            : null
                        }
                        type="number"
                        value={quantityReceived}
                      />
                    )}
                    {!isDraft && !isSent && <TableCell value={quantitySynced} />}
                    <TableCell value={roundToTwo(lastPricePaid / 100)} />
                    {!isDraft && !isSentNotEditable && (
                      <TableCell
                        onChange={
                          !isReceived && quantitySynced === 0
                            ? value => handleUpdateOrderProduct(id, 'costPaid', value, CONSTANTS.FIELD_TYPES.MONEY)
                            : null
                        }
                        type="number"
                        value={roundToTwo(costPaid / 100)}
                      />
                    )}
                    {isDraft ? (
                      <TableCell value={roundToTwo((lastPricePaid * quantityOrdered) / 100)} />
                    ) : (
                      <TableCell width="32" value={roundToTwo((costPaid * quantityReceived) / 100)} />
                    )}
                    {(isDraft || isSentNotEditable || isPartial) && (
                      <TableRowActions
                        actions={[
                          {
                            id: 'delete',
                            iconBefore: <FaTrash />,
                            onClick: () => handleDeleteProduct(id),
                            tabIndex: '-1',
                          },
                        ]}
                      />
                    )}
                  </TableRow>
                )
              },
            )}
          </>
        )}
      />
    )
  )
}

OrderTable.propTypes = {
  deleteOrderProducts: PropTypes.func.isRequired,
  handleDeleteProduct: PropTypes.func.isRequired,
  isEditing: PropTypes.bool,
  isFetchingPosition: PropTypes.bool,
  order: PROP_TYPES.ORDER.isRequired,
  stockPosition: PROP_TYPES.STOCK_POSITION,
  updateOrderProduct: PropTypes.func.isRequired,
}

OrderTable.defaultProps = {
  isEditing: false,
  isFetchingPosition: false,
  stockPosition: null,
}

OrderTable.displayName = 'OrderTable'

export { OrderTable }
