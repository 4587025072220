import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from '@reach/router'
import { FaPlus, FaExclamationTriangle } from 'react-icons/fa'

import { Dropdown } from '../../Dropdown'
import { Modal } from '../../Modal'

import { CONSTANTS } from '../../../common/constants'
import { PROP_TYPES } from '../../../common/propTypes'

const CreateOrderModal = ({ isOpen, loading, locations, onClose, onSubmit, suppliers, ...props }) => {
  const [location, setLocation] = useState(locations[0].id)
  const [supplier, setSupplier] = useState(suppliers[0].vendors.length ? suppliers[0].id : '')

  return (
    <Modal
      closeModal={onClose}
      disabled={!supplier}
      loading={loading}
      heading="New Purchase Order"
      isOpen={isOpen}
      onSubmit={() =>
        onSubmit({
          locationId: location,
          supplierId: supplier,
          kind: CONSTANTS.KIND.PURCHASE,
        })
      }
      submitIcon={<FaPlus />}
      submitValue="Create"
      {...props}
    >
      <Dropdown
        className="mt-6"
        id="new-order-supplier"
        label="Supplier"
        onChange={setSupplier}
        options={suppliers.map(({ id: value, name, vendors }) => ({
          disabled: !vendors.length,
          option: name,
          value,
        }))}
        value={supplier}
      />
      {suppliers.some(({ vendors }) => !vendors.length) && (
        <p className="text-sm text-orange flex items-center mt-1">
          <FaExclamationTriangle />
          <span className="ml-2">
            Suppliers without vendors are disabled.{' '}
            <Link className="underline text-black" to="/suppliers">
              Edit suppliers
            </Link>
          </span>
        </p>
      )}
      <Dropdown
        className="mt-4"
        id="new-order-location"
        label="Delivery Location"
        onChange={setLocation}
        options={locations.map(({ id: value, companyName }) => ({ value, option: companyName }))}
        value={location}
      />
    </Modal>
  )
}

CreateOrderModal.propTypes = {
  isOpen: PropTypes.bool,
  loading: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  locations: PropTypes.arrayOf(PROP_TYPES.LOCATION).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  suppliers: PropTypes.arrayOf(PROP_TYPES.SUPPLIER).isRequired,
}

CreateOrderModal.defaultProps = {
  isOpen: false,
  loading: false,
}

export { CreateOrderModal }
