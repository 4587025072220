import React from 'react'
import PropTypes from 'prop-types'
import { FaPaperPlane } from 'react-icons/fa'

import { Modal } from '../../Modal'

const TransferReceiveModal = ({ isOpen, loading, onClose, onSubmit, orderId }) => (
  <Modal
    closeModal={onClose}
    heading={`Transfer #${orderId} - Sync received stock with Shopify`}
    loading={loading}
    isOpen={isOpen}
    onSubmit={onSubmit}
    submitIcon={<FaPaperPlane />}
    submitValue="Do it"
  >
    <div className="mt-4">
      <p>
        This will change your transfer status to <code className="text-orange">Received</code>.
      </p>
    </div>
  </Modal>
)

TransferReceiveModal.propTypes = {
  isOpen: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  loading: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  orderId: PropTypes.string.isRequired,
}

TransferReceiveModal.defaultProps = {
  isOpen: false,
  loading: false,
}

export { TransferReceiveModal }
