import 'unfetch/polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import moment from 'moment'

import App from './App'

import './assets/styles/index.css'

moment.locale('en-nz')

ReactDOM.render(<App />, document.getElementById('app'))

if (!process.env.BRANCH) {
  module.hot.accept()
}
