import { BACKEND_CONSTANTS } from './constants'
import { get } from './helpers'
import { toLocation } from './transform'

const getLocations = async () => {
  const locationsRes = await get('locations', BACKEND_CONSTANTS.LOCATION.GET_LOCATIONS)
  if (!locationsRes.success) return locationsRes

  return {
    ...locationsRes,
    data: locationsRes.data.map(location => toLocation(location)),
  }
}

export { getLocations }
