import React from 'react'

import { HorizontalTable } from '../../HorizontalTable'
import { HorizontalTableCell } from '../../HorizontalTableCell'

import { CONSTANTS } from '../../../common/constants'
import { PROP_TYPES } from '../../../common/propTypes'
import { roundToTwo } from '../../../helpers'

const StocktakeTotals = ({ stocktake }) => {
  const value = stocktake.products.reduce((acc, { costOfGoods, quantityCounted, quantityExpected }) => {
    const hasQuantityCounted = quantityCounted === 0 || quantityCounted

    return hasQuantityCounted && costOfGoods ? (quantityCounted - quantityExpected) * costOfGoods + acc : acc
  }, 0)
  const isDraft = stocktake.status === CONSTANTS.STATUS.DRAFT

  return (
    <div className="mt-8">
      <HorizontalTable
        head={
          <HorizontalTableCell
            bold
            large
            value={`Total
            ${isDraft ? 'Estimated' : ''} Value`}
          />
        }
        body={<HorizontalTableCell large value={`$${roundToTwo(value / 100)}`} />}
      />
    </div>
  )
}

StocktakeTotals.propTypes = {
  stocktake: PROP_TYPES.STOCKTAKE.isRequired,
}

export { StocktakeTotals }
