import { BACKEND_CONSTANTS } from './constants'
import { onError, onSuccess, post, get, destroy } from './helpers'

import { fakeRequest } from '../../__mocks__/helpers'

const addIntegration = async data => post('integrations', BACKEND_CONSTANTS.INTEGRATIONS.ADD_INTEGRATION, data)

const getIntegration = async () => {
  try {
    await fakeRequest()

    return onSuccess(BACKEND_CONSTANTS.INTEGRATIONS.GET_INTEGRATION)
  } catch (e) {
    return onError(BACKEND_CONSTANTS.INTEGRATIONS.GET_INTEGRATION, e)
  }
}

const getIntegrations = async () => get('integrations', BACKEND_CONSTANTS.INTEGRATIONS.GET_INTEGRATIONS)
const removeIntegration = async id => destroy(`integrations/${id}`, BACKEND_CONSTANTS.INTEGRATIONS.REMOVE_INTEGRATION)

export { addIntegration, getIntegration, getIntegrations, removeIntegration }
