import React, { useState, useEffect } from 'react'

import { CreateSupplierModal } from '../components/Layouts/Suppliers/CreateSupplierModal'
import { DeleteSupplierModal } from '../components/Layouts/Suppliers/DeleteSupplierModal'
import { Loading } from '../components/Loading'
import { SuppliersHeader } from '../components/Layouts/Suppliers/SuppliersHeader'
import { SuppliersTable } from '../components/Layouts/Suppliers/SuppliersTable'

import { useSuppliers } from '../hooks/useSuppliers'

const Suppliers = () => {
  const [modal, setModal] = useState()
  const [currentSupplier, setCurrentSupplier] = useState()
  const { createSupplier, deleteSupplier, isFetchingSuppliers, isUpdatingSuppliers, suppliers } = useSuppliers()

  useEffect(() => {
    if (suppliers && !isUpdatingSuppliers) setModal()
  }, [isUpdatingSuppliers])

  function onDeleteCancel() {
    setCurrentSupplier()
    setModal()
  }

  function onDeleteClick(supplierId) {
    setCurrentSupplier(suppliers.find(({ id }) => id === supplierId))
    setModal('delete')
  }

  if (isFetchingSuppliers) return <Loading />

  return (
    <div className="max-w-site mb-8">
      <SuppliersHeader onCreateClick={() => setModal('create')} />
      <SuppliersTable onDeleteClick={onDeleteClick} suppliers={suppliers} />
      {modal === 'create' && (
        <CreateSupplierModal
          handleCancel={setModal}
          handleCreate={createSupplier}
          isOpen
          loading={isUpdatingSuppliers}
        />
      )}
      <DeleteSupplierModal
        handleCancel={onDeleteCancel}
        handleDelete={deleteSupplier}
        isOpen={currentSupplier && modal === 'delete'}
        loading={isUpdatingSuppliers}
        supplier={currentSupplier}
      />
    </div>
  )
}

export { Suppliers }
