import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { FaPlus, FaExclamationTriangle } from 'react-icons/fa'

import { Dropdown } from '../../Dropdown'
import { Modal } from '../../Modal'
import { Loading } from '../../Loading'

import { getVendors } from '../../../backend/vendors'
import { useFeedback } from '../../../hooks/useFeedback'

const SupplierAddVendorModal = ({ existingVendors, isOpen, loading, onClose, onSubmit }) => {
  const isMounted = useRef(true)
  const [vendors, setVendors] = useState()
  const [vendor, setVendor] = useState('')
  const { setError } = useFeedback()

  useEffect(() => {
    getData()

    return () => {
      isMounted.current = false
    }
  }, [existingVendors])

  async function getData() {
    const res = await getVendors()

    if (!isMounted.current) return
    if (!res.success) setError(res)
    if (res.success) {
      const filteredVendors = res.data.filter(({ id }) => !existingVendors.map(v => v.id).includes(id))
      setVendors(filteredVendors)
      setVendor(filteredVendors.length ? filteredVendors[0].id : null)
    }
  }

  return (
    <Modal
      closeModal={onClose}
      disabled={!vendor}
      heading="Add Vendor"
      isOpen={isOpen}
      loading={loading}
      onSubmit={() => onSubmit(vendor)}
      submitIcon={<FaPlus />}
      submitValue="Add"
    >
      {!vendors ? (
        <Loading />
      ) : (
        <div className="mt-6">
          {!vendors.length ? (
            <div className="bg-orange-100 rounded p-4">
              <p className="flex font-bold items-center">
                <FaExclamationTriangle /> <span className="pl-2 block">There are no more vendors to select</span>
              </p>
              <p className="mt-2">
                To create one a new vendor you must first create a new product in Shopify and add the new vendor to it.
              </p>
            </div>
          ) : (
            <Dropdown
              className="w-64"
              disabled={loading}
              id="supplier-add-vendor"
              label="Vendor"
              onChange={setVendor}
              options={vendors.map(({ id: value, name }) => ({ value, option: name }))}
              value={vendor}
            />
          )}
        </div>
      )}
    </Modal>
  )
}

SupplierAddVendorModal.propTypes = {
  existingVendors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
    }),
  ).isRequired,
  isOpen: PropTypes.bool,
  loading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

SupplierAddVendorModal.defaultProps = {
  isOpen: false,
  loading: false,
}

export { SupplierAddVendorModal }
