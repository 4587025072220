function getBaseUrl() {
  switch (process.env.BRANCH) {
    case 'master':
      return 'https://api.skux.app'
    case 'staging':
    default:
      return 'https://staging-api.skux.app'
  }
}

const BACKEND_CONSTANTS = {
  AUTH_TOKEN: 'auth_token',
  BASE_URL: getBaseUrl(),
  // BASE_URL: 'https://shwitchio.au.ngrok.io',
  AUTHENTICATE: {
    AUTHENTICATE_USER: 'AUTHENTICATE_USER',
    CHECK_AUTHENTICATED: 'CHECK_AUTHENTICATED',
    LOGOUT: 'LOGOUT',
  },
  EXPENSE: {
    CREATE_EXPENSE: 'CREATE_EXPENSE',
    DELETE_EXPENSE: 'DELETE_EXPENSE',
    UPDATE_EXPENSE: 'UPDATE_EXPENSE',
  },
  INTEGRATIONS: {
    ADD_INTEGRATION: 'ADD_INTEGRATION',
    GET_INTEGRATION: 'GET_INTEGRATION',
    GET_INTEGRATIONS: 'GET_INTEGRATIONS',
    REMOVE_INTEGRATION: 'REMOVE_INTEGRATION',
  },
  LOCATION: {
    GET_LOCATION: 'GET_LOCATION',
    GET_LOCATIONS: 'GET_LOCATIONS',
  },
  ORDER: {
    ADD_PRODUCT: 'ADD_PRODUCT',
    CREATE_ORDER: 'CREATE_ORDER',
    DELETE_ORDER: 'DELETE_ORDER',
    FULFILL: 'FULFILL',
    GET_ORDER: 'GET_ORDER',
    GET_ORDERS: 'GET_ORDERS',
    RECEIVE_PURCHASE: 'RECEIVE_PURCHASE',
    RECEIVE_TRANSFER: 'RECEIVE_TRANSFER',
    SEND: 'SEND',
    SEND_TO_XERO: 'SEND_TO_XERO',
    SYNC: 'SYNC',
    UPDATE: 'UPDATE_ORDER',
    UPDATE_EXPENSE: 'UPDATE_EXPENSE',
    UPDATE_STATUS: 'UPDATE_STATUS',
  },
  ORDER_PRODUCTS: {
    DELETE_ORDER_PRODUCT: 'DELETE_ORDER_PRODUCT',
    UPDATE_ORDER_PRODUCT: 'UPDATE_ORDER_PRODUCT',
    UPDATE_ORDER_PRODUCTS: 'UPDATE_ORDER_PRODUCTS',
  },
  PRODUCT_SUPPLIER: {
    UPDATE_SUPPLIER_CODE: 'UPDATE_SUPPLIER_CODE',
  },
  REPORTS: {
    GET_REPORT: 'GET_REPORT',
  },
  STATUS: {
    GET_STATUSES: 'GET_STATUSES',
  },
  STOCK_POSITION: {
    GET_STOCK_POSITION: 'GET_STOCK_POSITION',
  },
  STOCKTAKE: {
    BEGIN_STOCKTAKE: 'BEGIN_STOCKTAKE',
    COMPLETE_STOCKTAKE: 'COMPLETE_STOCKTAKE',
    CREATE_STOCKTAKE: 'CREATE_STOCKTAKE',
    DELETE_STOCKTAKE: 'DELETE_STOCKTAKE',
    GET_STOCKTAKE: 'GET_STOCKTAKE',
    GET_STOCKTAKES: 'GET_STOCKTAKES',
    UPDATE: 'UPDATE_STOCKTAKE',
    UPDATE_STATUS: 'UPDATE_STATUS',
  },
  STOCKTAKE_PRODUCTS: {
    UPDATE_STOCKTAKE_PRODUCT: 'UPDATE_STOCKTAKE_PRODUCT',
    UPDATE_STOCKTAKE_PRODUCTS: 'UPDATE_STOCKTAKE_PRODUCTS',
  },
  SUPPLIER: {
    CREATE_SUPPLIER: 'CREATE_SUPPLIER',
    DELETE_SUPPLIER: 'DELETE_SUPPLIER',
    GET_SUPPLIER: 'GET_SUPPLIER',
    GET_SUPPLIER_VARIANTS: 'GET_SUPPLIER_VARIANTS',
    GET_SUPPLIERS: 'GET_SUPPLIERS',
    GET_XERO_CONTACTS: 'GET_XERO_CONTACTS',
    UPDATE_SUPPLIER: 'UPDATE_SUPPLIER',
  },
  SYNC: {
    SHOPIFY: 'SHOPIFY',
  },
  USER: {
    CREATE_USER: 'CREATE_USER',
  },
  VARIANTS: {
    GET_VARIANTS: 'GET_VARIANTS',
  },
  VENDOR: {
    ADD_VENDOR: 'ADD_VENDOR',
    GET_VENDOR: 'GET_VENDOR',
    GET_VENDORS: 'GET_VENDORS',
    GET_VENDOR_PRODUCTS: 'GET_VENDOR_PRODUCTS',
    REMOVE_VENDOR: 'REMOVE_VENDOR',
  },
}

export { BACKEND_CONSTANTS }
