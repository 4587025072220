import React from 'react'
import PropTypes from 'prop-types'

import { Table } from '../../Table'
import { TableCell } from '../../TableCell'
import { TableHeadCell } from '../../TableHeadCell'
import { TableHeadRow } from '../../TableHeadRow'
import { TableRow } from '../../TableRow'

import { CONSTANTS } from '../../../common/constants'
import { PROP_TYPES } from '../../../common/propTypes'
import { roundToTwo } from '../../../helpers'

const StocktakeTable = ({ printable, stocktake, updateStocktakeProduct }) => {
  const isDraft = stocktake.status === CONSTANTS.STATUS.DRAFT
  const isCounted = stocktake.status === CONSTANTS.STATUS.COUNTED

  function handleUpdateStocktakeProduct(id, field, value, fieldType) {
    return updateStocktakeProduct({
      allowNegative: true,
      field,
      fieldType,
      id,
      value,
    })
  }

  return (
    <Table
      childIds={stocktake.products.map(({ id }) => id)}
      className="mt-6 overflow-hidden"
      renderHead={() => (
        <TableHeadRow>
          <TableHeadCell value="Product" width="1/3" />
          <TableHeadCell value="SKU" width="32" />
          <TableHeadCell value="Qty Expected" />
          {!isDraft && !printable && (
            <>
              <TableHeadCell value="Qty Counted" />
              <TableHeadCell value="Cost Of Goods" />
              <TableHeadCell value="Change" />
              <TableHeadCell value="Value $" />
            </>
          )}
          {printable && <TableHeadCell value="Count" />}
        </TableHeadRow>
      )}
      renderBody={() => (
        <>
          {stocktake.products.map(({ costOfGoods, id, quantityExpected, quantityCounted, sku, title, url }) => {
            const hasQuantityCounted = quantityCounted === 0 || quantityCounted
            return (
              <TableRow key={id}>
                <TableCell width="1/2" value={title} url={url} tabIndex="-1" />
                <TableCell width="32" capitalize={false} value={sku} />
                <TableCell type="number" value={quantityExpected} />
                {!isDraft && !printable && (
                  <>
                    <TableCell
                      nullable
                      onChange={
                        !isCounted && !printable
                          ? value =>
                              handleUpdateStocktakeProduct(
                                id,
                                'quantityCounted',
                                value,
                                CONSTANTS.FIELD_TYPES.INT,
                                true,
                              )
                          : null
                      }
                      type="number"
                      value={hasQuantityCounted ? quantityCounted.toString() : null}
                    />
                    <TableCell value={roundToTwo(costOfGoods / 100)} />
                    <TableCell value={hasQuantityCounted ? quantityCounted - quantityExpected : 0} />
                    <TableCell
                      value={
                        hasQuantityCounted
                          ? roundToTwo(((quantityCounted - quantityExpected) * costOfGoods) / 100)
                          : '0.00'
                      }
                    />
                  </>
                )}
                {printable && <TableCell value="" />}
              </TableRow>
            )
          })}
        </>
      )}
    />
  )
}

StocktakeTable.propTypes = {
  printable: PropTypes.bool,
  stocktake: PROP_TYPES.STOCKTAKE.isRequired,
  updateStocktakeProduct: PropTypes.func.isRequired,
}

StocktakeTable.defaultProps = {
  printable: false,
}

export { StocktakeTable }
