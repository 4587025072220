import { useEffect, useState, useRef } from 'react'
import { navigate } from '@reach/router'

import { createOrder as create, deleteOrder as destroy, getOrders, sendToXero as send } from '../backend/orders'
import { useFeedback } from './useFeedback'
import { CONSTANTS } from '../common/constants'

const useOrders = (orderKind = CONSTANTS.KIND.PURCHASE) => {
  const isMounted = useRef(true)
  const [isFetchingOrders, setIsFetchingOrders] = useState()
  const [isUpdatingOrders, setIsUpdatingOrders] = useState()
  const [orders, setOrders] = useState()
  const [orderIdXero, setOrderIdXero] = useState()
  const { setError, setToast } = useFeedback()

  useEffect(() => {
    getData()

    return () => {
      isMounted.current = false
    }
  }, [])

  async function getData() {
    setIsFetchingOrders(true)
    const res = await getOrders(orderKind)

    if (!isMounted.current) return
    if (!res.success) setError(res)
    if (res.success) setOrders(res.data)

    setIsFetchingOrders()
  }

  async function createOrder(data) {
    setIsUpdatingOrders(true)
    const res = await create(data)
    const isTransfer = data.kind === CONSTANTS.KIND.TRANSFER

    if (!isMounted.current) return
    if (!res.success) setError(res)
    if (res.success) {
      setToast(`${isTransfer ? 'Transfer' : 'Order'} created!`)
      navigate(`/${isTransfer ? 'transfers' : 'orders'}/${res.data.id}`)
    }

    setIsUpdatingOrders()
  }

  async function deleteOrder(orderId) {
    if (orderKind === CONSTANTS.KIND.PURCHASE) setError(res)

    setIsUpdatingOrders(true)

    const res = await destroy(orderId)

    if (!isMounted.current) return
    if (!res.success) setError(res)
    if (res.success) {
      setOrders(prevOrders => prevOrders.filter(order => order.id !== orderId))
      setToast('Deleted!')
    }

    setIsUpdatingOrders()
  }

  async function sendToXero(orderId) {
    setOrderIdXero(orderId)

    const res = await send(orderId)

    if (!isMounted.current) return
    if (!res.success) setError(res)
    if (res.success) {
      setOrders(prev => prev.map(order => (order.id === res.data.id ? { ...order, ...res.data } : order)))
      setToast('Sent to Xero!')
    }

    setOrderIdXero()
  }

  return {
    createOrder,
    deleteOrder,
    isFetchingOrders,
    isUpdatingOrders,
    orders,
    orderIdXero,
    sendToXero,
  }
}

export { useOrders }
