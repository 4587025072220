import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { navigate } from '@reach/router'
import { FaFileInvoiceDollar, FaEye, FaTrash } from 'react-icons/fa'

import { OrdersFilter } from './OrdersFilter'
import { Table } from '../../Table'
import { TableCell } from '../../TableCell'
import { TableHeadCell } from '../../TableHeadCell'
import { TableSortableColumnHeading } from '../../TableSortableColumnHeading'
import { TableRow } from '../../TableRow'
import { TableRowActions } from '../../TableRowActions'

import { CONSTANTS } from '../../../common/constants'
import { formatDate, roundToTwo, sortArrayByObjectKey } from '../../../helpers'
import { PROP_TYPES } from '../../../common/propTypes'

const OrdersTable = ({ currentTab, deleteOrder, locations, orders, orderIdXero, sendToXero, suppliers }) => {
  const [filteredOrders, setFilteredOrders] = useState(sortArrayByObjectKey(orders, 'orderNumber', true))
  const [sorting, setSorting] = useState({
    key: 'updatedAt',
    ascending: true,
  })

  const isDraft = currentTab === CONSTANTS.STATUS.DRAFT ? true : null
  const isSent = currentTab === CONSTANTS.STATUS.SENT ? true : null
  const isReceived = currentTab === CONSTANTS.STATUS.RECEIVED ? true : null

  function sortedOrders() {
    const sorted = sortArrayByObjectKey(filteredOrders, sorting.key, true)
    return sorting.ascending ? sorted : sorted.reverse()
  }

  function handleSort(key) {
    setSorting({
      key,
      ascending: key === sorting.key ? !sorting.ascending : sorting.ascending,
    })
  }

  return (
    <>
      <OrdersFilter
        filterKeys={[
          { data: locations.map(l => ({ id: l.id, label: l.companyName })), label: 'Location', value: 'locationId' },
          { label: 'Purchase Order No.', value: 'orderNumber' },
          { data: suppliers.map(s => ({ id: s.name, label: s.name })), label: 'supplier', value: 'supplierName' },
        ]}
        orders={orders}
        setFilteredOrders={setFilteredOrders}
      />
      <Table
        className="mt-6 max-w-6xl"
        emptyState={!orders.length && 'No orders here...'}
        renderHead={() => (
          <TableRow>
            <TableHeadCell value="Order No." width="32" />
            {!isDraft && !isSent && <TableHeadCell value="Invoice No." width="32" />}
            <TableHeadCell value="Location" width="72" />
            {currentTab === 'ALL' && (
              <TableHeadCell
                value={<TableSortableColumnHeading text="Status" onClick={() => handleSort('status')} />}
                width="72"
              />
            )}
            <TableHeadCell
              value={<TableSortableColumnHeading text="Supplier" onClick={() => handleSort('supplierName')} />}
              width="72"
            />
            <TableHeadCell
              value={<TableSortableColumnHeading text="Created" onClick={() => handleSort('createdAt')} />}
              width="32"
            />
            <TableHeadCell
              value={<TableSortableColumnHeading text="Updated" onClick={() => handleSort('updatedAt')} />}
              width="32"
            />
            <TableHeadCell value="Total Cost" width="48" />
            {(isReceived || isDraft) && <TableHeadCell alignment="right" width="72" value="Actions" />}
          </TableRow>
        )}
        renderBody={() => (
          <>
            {sortedOrders().map(order => {
              const {
                id,
                createdAt,
                invoiceNumber,
                locationId,
                orderNumber,
                orderTotal,
                sentToXero,
                status,
                supplierName,
                xeroInvoiceLink,
                updatedAt,
              } = order
              const actions = {
                delete: {
                  id: 'delete',
                  iconBefore: <FaTrash />,
                  onClick: e => {
                    e.stopPropagation()
                    deleteOrder(id)
                  },
                },
                view: {
                  id: 'viewInXero',
                  loading: orderIdXero === id,
                  iconBefore: <FaEye />,
                  onClick: e => {
                    e.stopPropagation()
                    window.open(xeroInvoiceLink, '_blank', 'noopener')
                  },
                  value: 'View in Xero',
                  variant: 'ghost',
                },
                send: {
                  id: 'sendToXero',
                  loading: orderIdXero === id,
                  iconBefore: <FaFileInvoiceDollar />,
                  onClick: e => {
                    e.stopPropagation()
                    sendToXero(id)
                  },
                  value: sentToXero ? 'Resend to Xero' : 'Send To Xero',
                  variant: sentToXero ? 'ghost' : 'secondary',
                },
              }

              function getActions() {
                if (isDraft) return [actions.delete]
                if (isReceived && xeroInvoiceLink) return [actions.view, actions.send]
                if (isReceived) return [actions.send]
                return []
              }

              return (
                <TableRow key={id} onClick={() => navigate(`/orders/${id}`)}>
                  <TableCell value={orderNumber} />
                  {!isDraft && !isSent && <TableCell value={invoiceNumber} />}
                  <TableCell value={locations.find(l => l.id === locationId).companyName} />
                  {currentTab === 'ALL' && <TableCell value={status.toLowerCase().replace('_', ' ')} />}
                  <TableCell value={supplierName} />
                  <TableCell value={formatDate(createdAt)} />
                  <TableCell value={formatDate(updatedAt)} />
                  <TableCell value={`$${roundToTwo(orderTotal / 100)}`} />
                  {(isDraft || isReceived) && <TableRowActions actions={getActions()} />}
                </TableRow>
              )
            })}
          </>
        )}
      />
    </>
  )
}

OrdersTable.propTypes = {
  currentTab: PropTypes.string,
  deleteOrder: PropTypes.func.isRequired,
  locations: PropTypes.arrayOf(PROP_TYPES.LOCATION),

  orders: PropTypes.arrayOf(
    PropTypes.shape({
      createdAt: PropTypes.string.isRequired,
      kind: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      locationId: PropTypes.string.isRequired,
      orderNumber: PropTypes.number.isRequired,
      orderTotal: PropTypes.number,
      sentToXero: PropTypes.string,
      status: PropTypes.string.isRequired,
      supplierName: PropTypes.string.isRequired,
      xeroInvoiceLink: PropTypes.string,
    }),
  ),
  orderIdXero: PropTypes.string,
  sendToXero: PropTypes.func.isRequired,
  suppliers: PropTypes.arrayOf(PROP_TYPES.SUPPLIER),
}

OrdersTable.defaultProps = {
  currentTab: '',
  locations: null,
  orders: [],
  orderIdXero: null,
  suppliers: null,
}

export { OrdersTable }
