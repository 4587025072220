import React from 'react'
import PropTypes from 'prop-types'

import { HorizontalTable } from '../../HorizontalTable'
import { HorizontalTableCell } from '../../HorizontalTableCell'
import { TableCellInput } from '../../TableCellInput'

import { CONSTANTS } from '../../../common/constants'
import { getSubtotal, getFixedExpenses } from '../../../common/helpers'
import { PROP_TYPES } from '../../../common/propTypes'
import { roundToTwo } from '../../../helpers'

const OrderTotals = ({ loading, order, updateOrder }) => {
  const subtotal = getSubtotal({ products: order.products, status: order.status })
  const expenses = getFixedExpenses(order.fixedExpenses)
  const gst = (subtotal + expenses + order.adjustments) * CONSTANTS.TAX_MULTIPLIER
  const total = subtotal + expenses + order.adjustments + gst

  const isDraft = order.status === CONSTANTS.STATUS.DRAFT
  const isSent = order.status === CONSTANTS.STATUS.SENT
  const isReceived = order.status === CONSTANTS.STATUS.RECEIVED

  function handleUpdateAdjustments(value) {
    return updateOrder({ adjustments: value ? roundToTwo(parseFloat(value)) * 100 : 0 }, true)
  }

  return (
    <div className="mt-8">
      <HorizontalTable
        head={
          <>
            <HorizontalTableCell value="Sub Total" />
            <HorizontalTableCell value="Adjustments" />
            <HorizontalTableCell value="Fixed Expenses" />
            <HorizontalTableCell value="GST" />
            <HorizontalTableCell
              bold
              large
              value={`Total 
            ${isDraft || isSent ? 'Estimated' : ''} Cost`}
            />
          </>
        }
        body={
          <>
            <HorizontalTableCell value={roundToTwo(subtotal / 100)} />
            <HorizontalTableCell
              value={
                !isReceived ? (
                  <div className="flex items-center -my-1">
                    <TableCellInput
                      className="text-right"
                      disabled={loading}
                      onChange={handleUpdateAdjustments}
                      type="number"
                      value={roundToTwo(order.adjustments / 100)}
                    />
                  </div>
                ) : (
                  roundToTwo(order.adjustments / 100)
                )
              }
            />
            <HorizontalTableCell value={roundToTwo(expenses / 100)} />
            <HorizontalTableCell value={roundToTwo(gst / 100)} />
            <HorizontalTableCell large value={`$${roundToTwo(total / 100)}`} />
          </>
        }
      />
    </div>
  )
}

OrderTotals.propTypes = {
  loading: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  order: PROP_TYPES.ORDER.isRequired,
  updateOrder: PropTypes.func.isRequired,
}

OrderTotals.defaultProps = {
  loading: false,
}

export { OrderTotals }
