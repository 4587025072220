import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment'

import { Button } from '../../Button'
import { DateRangePicker } from '../../DateRangePicker'
import { Loading } from '../../Loading'
import { Table } from '../../Table'
import { TableCell } from '../../TableCell'
import { TableHeadCell } from '../../TableHeadCell'
import { TableHeadRow } from '../../TableHeadRow'
import { TableRow } from '../../TableRow'

import { getReport } from '../../../backend/reports'
import { mergeReportLocations } from '../../../common/helpers'
import { dateString, roundToTwo, sortArrayByObjectKey } from '../../../helpers'
import { useFeedback } from '../../../hooks/useFeedback'

const ControlPanelReports = () => {
  const initialStartDate = new Date(moment().subtract(30, 'days'))
  const initialEndDate = new Date()

  const isMounted = useRef(true)
  const [loading, setLoading] = useState()
  const [dateRange, setDateRange] = useState([initialStartDate, initialEndDate])
  const [report, setReport] = useState()
  const { setError } = useFeedback()

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  async function handleGetReport() {
    setLoading(true)

    const res = await getReport(dateString(dateRange[0]), dateString(dateRange[1]))

    if (!isMounted.current) return
    if (!res.success) setError(res)
    if (res.success && typeof res.data.locations === 'undefined') setReport([])
    if (res.success && typeof res.data.locations !== 'undefined') {
      const data = formatLocations(res.data.locations)
      const allLocations = mergeReportLocations(data)
      setReport([...data, allLocations])
    }

    setLoading()
  }

  function formatLocations(locations) {
    return locations.map(location => ({
      ...location,
      days: location.days.map(({ date, values }) => ({
        date,
        unitsInTransfer: values.unitsInTransfer,
        unitsOnHand: values.unitsOnHand,
        valueInTransfer: values.valueInTransfer,
        valueOnHand: values.valueOnHand,
      })),
    }))
  }

  return (
    <div className="my-8">
      <h2 className="font-bold">Generate a report</h2>
      <div className="mt-4">
        <label className="font-bold text-sm" htmlFor="positionDateRange">
          Report Date Range
        </label>
        <div className="flex items-center mt-1">
          <div className="mr-2">
            <DateRangePicker id="reportDateRange" maxDate={new Date()} onChange={setDateRange} value={dateRange} />
          </div>
          <Button disabled={loading} onClick={() => handleGetReport()} value="Generate" variant="secondary" />
        </div>
      </div>
      {(loading || report) && (
        <div className="mt-6 max-w-2xl">
          {!loading ? (
            <>
              {report.map(({ location, days }, i) => (
                <div key={location} className={i !== 0 ? 'mt-6' : ''}>
                  <h2>{location}</h2>
                  <Table
                    className="mt-2"
                    emptyState={!days.length && 'Nothing here...'}
                    renderHead={() => (
                      <TableHeadRow>
                        <TableHeadCell value="Date" width="32" />
                        <TableHeadCell alignment="right" value="In Transfer" width="32" />
                        <TableHeadCell alignment="right" value="Transfer Value" width="32" />
                        <TableHeadCell alignment="right" value="On Hand" width="32" />
                        <TableHeadCell alignment="right" value="Stock Value" width="32" />
                      </TableHeadRow>
                    )}
                    renderBody={() => (
                      <>
                        {sortArrayByObjectKey(days, 'date', true).map(
                          ({ date, unitsInTransfer, unitsOnHand, valueInTransfer, valueOnHand }) => (
                            <TableRow key={date}>
                              <TableCell value={date} />
                              <TableCell alignment="right" value={unitsInTransfer} />
                              <TableCell alignment="right" value={`$${roundToTwo(valueInTransfer / 100)}`} />
                              <TableCell alignment="right" value={unitsOnHand} />
                              <TableCell alignment="right" value={`$${roundToTwo(valueOnHand / 100)}`} />
                            </TableRow>
                          ),
                        )}
                      </>
                    )}
                  />
                </div>
              ))}
              {report.length < 1 && <div className="mt-4">No data for those dates...</div>}
            </>
          ) : (
            <Loading />
          )}
        </div>
      )}
    </div>
  )
}

export { ControlPanelReports }
