import React from 'react'
import PropTypes from 'prop-types'

import { EmptyTableRow } from '../../EmptyStates/EmptyTableRow'
import { Table } from '../../Table'
import { TableCell } from '../../TableCell'
import { TableHeadCell } from '../../TableHeadCell'
import { TableHeadRow } from '../../TableHeadRow'

import { PROP_TYPES } from '../../../common/propTypes'
import { sortArrayByObjectKey } from '../../../helpers'

const VendorProductsTable = ({ loading, products }) => {
  return (
    <Table
      className="mt-8"
      loading={loading}
      renderHead={() => (
        <TableHeadRow>
          <TableHeadCell value="Products" width="5/12" />
        </TableHeadRow>
      )}
      renderBody={() => (
        <>
          {sortArrayByObjectKey(products, 'title').map(({ id, title, url }) => (
            <tr key={id}>
              <TableCell value={title} url={url} />
            </tr>
          ))}
          {products.length < 1 && <EmptyTableRow columns={['products']} />}
        </>
      )}
    />
  )
}

VendorProductsTable.propTypes = {
  loading: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  products: PropTypes.arrayOf(PROP_TYPES.PRODUCT),
}

VendorProductsTable.defaultProps = {
  loading: false,
  products: [],
}

export { VendorProductsTable }
