import { useState, useCallback, useRef } from 'react'

import { useDocumentEvent } from './useDocumentEvent'

const useOverlay = () => {
  const ref = useRef(null)
  const [overlay, setOverlay] = useState()

  const handleClickOutside = useCallback(
    event => {
      if (!overlay || (ref.current && ref.current.contains(event.target))) return

      setOverlay(false)
    },
    [ref, overlay],
  )

  const handleHideOverlay = useCallback(
    event => {
      if (event.key === 'Escape') setOverlay(false)
    },
    [overlay],
  )

  useDocumentEvent([
    { type: 'click', callback: handleClickOutside },
    { type: 'keydown', callback: handleHideOverlay },
  ])

  return [ref, overlay, setOverlay]
}

export { useOverlay }
