import React from 'react'
import { FaArrowLeft } from 'react-icons/fa'
import { navigate } from '@reach/router'

import { Button } from '../components/Button'

const NotFound = () => (
  <div className="flex items-center justify-center h-screen -mt-20 text-center">
    <div>
      <p className="text-2xl">Page not found.</p>
      <div className="flex mt-8 mx-auto">
        <Button
          className="mr-2"
          iconBefore={<FaArrowLeft />}
          onClick={() => navigate(-1)}
          value="Back"
          variant="ghost"
        />
        <Button onClick={() => navigate('/')} value="Go home" variant="secondary" />
      </div>
    </div>
  </div>
)

export { NotFound }
