const CONSTANTS = {
  FIELD_TYPES: {
    FLOAT: 'FLOAT',
    INT: 'INT',
    MONEY: 'MONEY',
    STRING: 'STRING',
  },
  STATUS: {
    ARCHIVED: 'ARCHIVED',
    COUNTED: 'COUNTED',
    DELETED: 'DELETED',
    DRAFT: 'DRAFT',
    FULFILLED: 'FULFILLED',
    IN_PROGRESS: 'IN_PROGRESS',
    PARTIALLY_RECEIVED: 'PARTIALLY_RECEIVED',
    RECEIVED: 'RECEIVED',
    REQUESTED: 'REQUESTED',
    SENT: 'SENT',
  },
  KIND: {
    PURCHASE: 'PURCHASE',
    TRANSFER: 'TRANSFER',
  },
  TAX_MULTIPLIER: 0.15,
}

export { CONSTANTS }
