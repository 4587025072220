import React from 'react'
import PropTypes from 'prop-types'

const SyncJob = ({ pctComplete, status, worker }) => {
  const isComplete = status === 'complete'

  function getDescription(x) {
    const raw = x.split('::')
    const rawTitle = raw[0]
    const rawDesc = raw[1]

    function customise(desc, title) {
      switch (desc) {
        case 'SyncLocations':
          return 'Syncing locations'
        case 'SyncSales':
          return 'Syncing sales'
        case 'SyncVariants':
          return 'Syncing products'
        case 'SyncTest':
          return 'Test sync'
        default:
          return `Syncing ${title}`
      }
    }

    return customise(rawDesc, rawTitle)
  }

  return (
    <li className="px-4 py-1 text-right text-sm">
      <span className="inline-block pr-2">{getDescription(worker)}</span>
      <div className="w-24 inline-block">
        <span className="h-2 bg-white inline-block" style={{ width: `${isComplete ? 100 : pctComplete}%` }} />
        <span
          className="h-2 bg-orange-400 inline-block"
          style={{ width: `${isComplete ? 0 : 100 - parseInt(pctComplete)}%` }}
        />
      </div>
      <span className="w-12 inline-block">{isComplete ? 100 : pctComplete}%</span>
    </li>
  )
}

SyncJob.propTypes = {
  pctComplete: PropTypes.string,
  status: PropTypes.string.isRequired,
  worker: PropTypes.string.isRequired,
}

SyncJob.defaultProps = {
  pctComplete: '0',
}

export { SyncJob }
