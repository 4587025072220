import moment from 'moment'
import { useState, useRef, useEffect } from 'react'

import { dateString } from '../helpers'
import { getStockPosition as getPosition } from '../backend/stockPosition'
import { useFeedback } from './useFeedback'

const useOrderStockPosition = orderId => {
  const isMounted = useRef(true)
  const [isFetchingPosition, setIsFetchingPosition] = useState()
  const [stockPosition, setStockPosition] = useState()
  const { setError } = useFeedback()

  const initialStartDate = new Date(moment().subtract(30, 'days'))
  const initialEndDate = new Date()

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  async function getStockPosition(dateRange, locationId) {
    setIsFetchingPosition(true)

    const res = await getPosition(
      orderId,
      dateString(dateRange[0]),
      dateString(dateRange[1]),
      locationId !== 'add' && locationId,
    )

    if (!isMounted.current) return
    if (!res.success) setError(res)
    if (res.success) setStockPosition(res.data)

    setIsFetchingPosition()
  }

  return {
    getStockPosition,
    initialDateRange: [initialStartDate, initialEndDate],
    isFetchingPosition,
    stockPosition,
  }
}

export { useOrderStockPosition }
