import React from 'react'
import PropTypes from 'prop-types'
import { FaTrash, FaPlus } from 'react-icons/fa'

import { Table } from '../../Table'
import { TableCell } from '../../TableCell'
import { TableHeadCell } from '../../TableHeadCell'
import { TableHeadRow } from '../../TableHeadRow'
import { TableRow } from '../../TableRow'
import { TableRowActions } from '../../TableRowActions'

import { PROP_TYPES } from '../../../common/propTypes'

const IntegrationsTable = ({ onAddClick, onRemoveClick, integrations }) => {
  return (
    <Table
      className="mt-4 max-w-3xl"
      renderHead={() => (
        <TableHeadRow>
          <TableHeadCell value="Name" width="3/12" />
          <TableHeadCell alignment="right" width="32" value="Actions" />
        </TableHeadRow>
      )}
      renderBody={() => (
        <>
          {integrations.map(({ id, name, active }) => {
            const actions = {
              add: {
                id: 'add',
                iconBefore: <FaPlus />,
                onClick: () => onAddClick(id),
                variant: 'outline',
              },
              remove: {
                id: 'remove',
                iconBefore: <FaTrash />,
                onClick: () => onRemoveClick(id),
              },
            }
            return (
              <TableRow key={id}>
                <TableCell value={name} />
                <TableRowActions actions={!active ? [actions.add] : [actions.remove]} />
              </TableRow>
            )
          })}
        </>
      )}
    />
  )
}

IntegrationsTable.propTypes = {
  onAddClick: PropTypes.func,
  onRemoveClick: PropTypes.func,
  integrations: PropTypes.arrayOf(PROP_TYPES.INTEGRATION),
}

IntegrationsTable.defaultProps = {
  integrations: [],
  onAddClick: null,
  onRemoveClick: null,
}

export { IntegrationsTable }
