import React from 'react'
import PropTypes from 'prop-types'
import { FaSyncAlt } from 'react-icons/fa'

import { Modal } from '../../Modal'

import { CONSTANTS } from '../../../common/constants'

const OrderUpdateStatusModal = ({ currentStatus, isOpen, loading, onClose, onSubmit }) => {
  function getNewStatus() {
    switch (currentStatus) {
      case CONSTANTS.STATUS.DRAFT:
        return CONSTANTS.STATUS.SENT
      case CONSTANTS.STATUS.SENT:
        return CONSTANTS.STATUS.DRAFT
      case CONSTANTS.STATUS.RECEIVED:
        return CONSTANTS.STATUS.PARTIALLY_RECEIVED
      default:
        return currentStatus
    }
  }

  return (
    <Modal
      closeModal={onClose}
      heading="Change Purchase Order Status"
      loading={loading}
      isOpen={isOpen}
      onSubmit={() => onSubmit(getNewStatus())}
      submitIcon={<FaSyncAlt />}
      submitValue="Confirm"
    >
      <div className="mt-4">
        {currentStatus === CONSTANTS.STATUS.DRAFT && (
          <p>
            This will change your purchase order status to <code className="text-orange">Sent</code>. Make sure you
            download the CSV and send it to your supplier.
          </p>
        )}
        {currentStatus === CONSTANTS.STATUS.SENT && (
          <p>
            This will revert your purchase order status to <code className="text-orange">Draft</code>.
          </p>
        )}
        {currentStatus === CONSTANTS.STATUS.RECEIVED && (
          <p>
            This will revert your purchase order status to <code className="text-orange">Partially Received</code>. Are
            you sure?
          </p>
        )}
      </div>
    </Modal>
  )
}

OrderUpdateStatusModal.propTypes = {
  currentStatus: PropTypes.string.isRequired,
  isOpen: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  loading: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

OrderUpdateStatusModal.defaultProps = {
  loading: false,
  isOpen: false,
}

export { OrderUpdateStatusModal }
