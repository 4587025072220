import React from 'react'
import PropTypes from 'prop-types'
import { FaTrash } from 'react-icons/fa'
import { Link, navigate } from '@reach/router'

import { Table } from '../../Table'
import { TableCell } from '../../TableCell'
import { TableHeadCell } from '../../TableHeadCell'
import { TableHeadRow } from '../../TableHeadRow'
import { TableRow } from '../../TableRow'
import { TableRowActions } from '../../TableRowActions'

import { PROP_TYPES } from '../../../common/propTypes'
import { sortArrayByObjectKey } from '../../../helpers'

const SupplierVendorsTable = ({ handleRemove, supplier }) => (
  <div className="max-w-xl">
    <Table
      className="mt-2"
      renderHead={() => (
        <TableHeadRow>
          <TableHeadCell value="Name" width="auto" />
          <TableHeadCell alignment="right" value="Actions" width="32" />
        </TableHeadRow>
      )}
      renderBody={() =>
        sortArrayByObjectKey(supplier.vendors, 'name').map(({ id, name }) => (
          <TableRow
            key={id}
            onClick={() => navigate(`/suppliers/${supplier.id}/${id}`, { state: { supplierName: supplier.name } })}
          >
            <TableCell
              value={
                <Link to={`/suppliers/${supplier.id}/${id}`} state={{ supplierName: supplier.name }}>
                  {name}
                </Link>
              }
            />
            <TableRowActions
              actions={[
                {
                  id: 'remove',
                  iconBefore: <FaTrash />,
                  onClick: e => {
                    e.stopPropagation()
                    handleRemove(id)
                  },
                },
              ]}
            />
          </TableRow>
        ))
      }
    />
  </div>
)

SupplierVendorsTable.propTypes = {
  handleRemove: PropTypes.func.isRequired,
  supplier: PROP_TYPES.SUPPLIER.isRequired,
}

export { SupplierVendorsTable }
