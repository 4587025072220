import React from 'react'
import PropTypes from 'prop-types'

const Input = ({ alignText, className, error, id, label, onChange, readOnly, type, value, ...props }) => {
  function getBorderStyle() {
    return error ? 'border-red focus:border-red' : 'focus:border-black'
  }

  return (
    <div className={className}>
      {label && (
        <label className="font-bold text-sm" htmlFor={id}>
          {label}
        </label>
      )}
      <div className="relative mt-1">
        {!readOnly ? (
          <input
            id={id}
            onChange={e => onChange(e.target.value)}
            className={`block rounded appearance-none bg-white w-full py-2 px-3 focus:outline-none border disabled:border-gray-300 disabled:text-gray-300 text-${alignText}
          ${getBorderStyle()}
        `}
            type={type}
            {...props}
          />
        ) : (
          <span className={`block rounded bg-white w-full py-2 px-3 text-${alignText}`}>{value}</span>
        )}
        {error && <p className="text-xs font-bold text-red mt-1">{error}</p>}
      </div>
    </div>
  )
}

Input.propTypes = {
  alignText: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

Input.defaultProps = {
  alignText: 'left',
  className: '',
  error: null,
  label: null,
  readOnly: false,
  type: 'string',
  value: '',
}

export { Input }
