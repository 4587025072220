import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Loading } from '../components/Loading'
import { SupplierVendorHeader } from '../components/Layouts/SupplierVendor/SupplierVendorHeader'
import { SupplierVendorProductsTable } from '../components/Layouts/SupplierVendor/SupplierVendorProductsTable'

import { getSupplierVariants } from '../backend/suppliers'
import { getVendor } from '../backend/vendors'
import { useFeedback } from '../hooks/useFeedback'

const SupplierVendor = ({ location, supplierId, vendorId }) => {
  const [supplierVariants, setSupplierVariants] = useState()
  const [vendor, setVendor] = useState()
  const { setError } = useFeedback()

  useEffect(() => {
    async function getData() {
      const res = await getVendor(vendorId)

      if (!res.success) setError(res)
      if (res.success) {
        setVendor(res.data)
      }
    }

    getData()
  }, [vendorId])

  useEffect(() => {
    async function getData() {
      const res = await getSupplierVariants(supplierId)

      if (!res.success) setError(res)
      if (res.success) {
        setSupplierVariants(res.data)
      }
    }

    getData()
  }, [supplierId])

  return !vendorId || !vendor || !supplierId || !supplierVariants ? (
    <Loading />
  ) : (
    <div>
      <SupplierVendorHeader
        parent={{
          to: `/suppliers/${supplierId}`,
          value: location.state.supplierName ? location.state.supplierName : 'Supplier',
        }}
        id={vendorId}
        name={vendor && vendor.name}
      />
      <SupplierVendorProductsTable products={supplierVariants} supplierId={supplierId} />
    </div>
  )
}

SupplierVendor.propTypes = {
  location: PropTypes.shape({ state: PropTypes.shape({ supplierName: PropTypes.string }) }),
  supplierId: PropTypes.string,
  vendorId: PropTypes.string,
}

SupplierVendor.defaultProps = {
  location: { state: { supplierName: null } },
  supplierId: null,
  vendorId: null,
}

export { SupplierVendor }
