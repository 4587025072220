import React from 'react'
import PropTypes from 'prop-types'

const TableHeadRow = ({ className, ...props }) => (
  <tr className={`text-sm border-b sticky top-0 ${className}`} {...props} />
)

TableHeadRow.propTypes = {
  className: PropTypes.string,
}

TableHeadRow.defaultProps = {
  className: '',
}

export { TableHeadRow }
