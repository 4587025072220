import { useEffect, useState, useRef } from 'react'

import { getSupplierVariants } from '../backend/suppliers'
import { getVariants } from '../backend/variants'

const useVariants = (supplierId, init) => {
  const isMounted = useRef(true)
  const [error, setError] = useState()
  const [fetching, setFetching] = useState()
  const [variants, setVariants] = useState()

  useEffect(() => {
    if (init && !supplierId) getAllVariants()
    if (init && supplierId) getVariantsFromSupplier()

    return () => {
      isMounted.current = false
    }
  }, [init])

  async function getAllVariants() {
    setFetching(true)
    const res = await getVariants()

    if (!isMounted.current) return
    if (!res.success) setError(res)
    if (res.success) setVariants(res.data)

    setFetching()
  }

  async function getVariantsFromSupplier() {
    setFetching(true)
    const res = await getSupplierVariants(supplierId)

    if (!isMounted.current) return
    if (!res.success) setError(res)
    if (res.success) setVariants(res.data)

    setFetching()
  }

  return { variants, fetching, error }
}

export { useVariants }
