import React from 'react'
import { Router, Redirect } from '@reach/router'

import { ControlPanel } from '../pages/ControlPanel'
import { Integrations } from '../pages/Integrations'
import { NotFound } from '../pages/NotFound'
import { Order } from '../pages/Order'
import { Orders } from '../pages/Orders'
import { Stocktake } from '../pages/Stocktake'
import { Stocktakes } from '../pages/Stocktakes'
import { Supplier } from '../pages/Supplier'
import { Suppliers } from '../pages/Suppliers'
import { SupplierVendor } from '../pages/SupplierVendor'
import { Transfers } from '../pages/Transfers'
import { Transfer } from '../pages/Transfer'
import { Vendor } from '../pages/Vendor'
import { Vendors } from '../pages/Vendors'

const Routes = () => (
  <Router>
    <NotFound default />
    <Redirect from="/" to="/orders" noThrow />
    <Orders path="/orders" />
    <Order path="/orders/:orderId" parent={{ to: '/orders', value: 'Orders' }} />
    <Stocktakes path="/stocktakes" />
    <Stocktake path="/stocktakes/:stocktakeId" parent={{ to: '/stocktakes', value: 'Stocktakes' }} />
    <Suppliers path="/suppliers" />
    <Supplier path="/suppliers/:supplierId" parent={{ to: '/suppliers', value: 'Suppliers' }} />
    <SupplierVendor path="/suppliers/:supplierId/:vendorId" />
    <Transfers path="/transfers" />
    <Transfer path="/transfers/:orderId" parent={{ to: '/transfers', value: 'Transfers' }} />
    <Vendors path="/vendors" parent={{ to: '/vendors', value: 'Vendors' }} />
    <Vendor path="/vendors/:vendorId" parent={{ to: '/vendors', value: 'Vendors' }} />
    <Integrations path="/integrations" parent={{ to: '/integrations', value: 'Integrations' }} />
    <ControlPanel path="/control-panel" />
  </Router>
)

export { Routes }
