import React from 'react'
import PropTypes from 'prop-types'
import Picker from 'react-date-picker/dist/entry.nostyle'
import { FaCalendarAlt, FaTimes } from 'react-icons/fa'

const DatePicker = ({ className, onChange, readOnly, value, ...props }) => (
  <Picker
    calendarIcon={<FaCalendarAlt />}
    className={className}
    clearIcon={<FaTimes />}
    disabled={readOnly}
    format="dd/MM/y"
    onChange={onChange}
    value={value}
    {...props}
  />
)

DatePicker.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  value: PropTypes.instanceOf(Date),
}

DatePicker.defaultProps = {
  className: '',
  readOnly: false,
  value: null,
}

export { DatePicker }
