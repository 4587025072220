import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { AddIntegrationModal } from '../components/Layouts/Integrations/AddIntegrationModal'
import { IntegrationsTable } from '../components/Layouts/Integrations/IntegrationsTable'
import { Loading } from '../components/Loading'
import { RemoveIntegrationModal } from '../components/Layouts/Integrations/RemoveIntegrationModal'

import { useIntegrations } from '../hooks/useIntegrations'

const Integrations = ({ location }) => {
  const [modal, setModal] = useState()
  const [currentIntegration, setCurrentIntegration] = useState()
  const { addIntegration, integrations, isUpdatingIntegrations, removeIntegration } = useIntegrations(location)

  useEffect(() => {
    if (integrations && !isUpdatingIntegrations) setModal()
  }, [isUpdatingIntegrations])

  function onRemoveCancel() {
    setCurrentIntegration()
    setModal()
  }

  function onAddClick(integrationId) {
    setCurrentIntegration(integrations.find(({ id }) => id === integrationId))
    setModal('add')
  }

  function onRemoveClick(integrationId) {
    setCurrentIntegration(integrations.find(({ id }) => id === integrationId))
    setModal('remove')
  }

  if (!integrations) return <Loading />

  return (
    <div className="max-w-site mt-2 mb-6">
      {integrations.some(({ active }) => active) && (
        <>
          <p className="text-orange mb-4">Existing Integrations</p>
          <IntegrationsTable onRemoveClick={onRemoveClick} integrations={integrations.filter(({ active }) => active)} />
        </>
      )}
      {integrations.some(({ active }) => !active) && (
        <>
          <p className="text-orange mt-8 mb-4">Available Integrations</p>
          <IntegrationsTable onAddClick={onAddClick} integrations={integrations.filter(({ active }) => !active)} />
        </>
      )}
      <AddIntegrationModal
        integration={currentIntegration}
        isOpen={modal === 'add'}
        loading={isUpdatingIntegrations}
        onClose={setModal}
        onSubmit={addIntegration}
      />
      {integrations.length > 0 && (
        <RemoveIntegrationModal
          integration={currentIntegration}
          isOpen={modal === 'remove'}
          loading={isUpdatingIntegrations}
          onClose={onRemoveCancel}
          onSubmit={removeIntegration}
        />
      )}
    </div>
  )
}

Integrations.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
}

Integrations.defaultProps = {
  location: { search: '' },
}

export { Integrations }
