import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { debounce } from 'throttle-debounce'

const TableCellInput = ({ className, nullable, onChange, type, value: initialValue, ...props }) => {
  const [value, setValue] = useState(getInitialValue())

  const debouncedOnChange = useCallback(debounce(850, onChange), [])

  function getInitialValue() {
    switch (type) {
      case 'number':
        return nullable ? initialValue || '' : initialValue || 0
      case 'string':
      default:
        return initialValue || ''
    }
  }

  function handleChange(e) {
    setValue(e.target.value)
    debouncedOnChange(e.target.value, props)
  }

  useEffect(() => {
    if (initialValue !== value) setValue(getInitialValue())
  }, [initialValue])

  return (
    <input
      className={`appearance-none focus:outline-none w-full block bg-orange-100 px-2 py-1 disabled:bg-gray-100 disabled:text-gray-300 ${className}`}
      onChange={handleChange}
      onClick={e => e.target.select()}
      type={type}
      value={value}
      {...props}
    />
  )
}

TableCellInput.propTypes = {
  className: PropTypes.string,
  nullable: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

TableCellInput.defaultProps = {
  className: '',
  nullable: false,
  type: 'text',
  value: '',
}

export { TableCellInput }
