import { BACKEND_CONSTANTS } from './constants'
import { get, post, destroy } from './helpers'

const addVendor = async (supplierId, vendorId) =>
  post(`suppliers/${supplierId}/vendor/${vendorId}`, BACKEND_CONSTANTS.VENDOR.ADD_VENDOR, {})

const getVendor = async id => get(`vendors/${id}`, BACKEND_CONSTANTS.VENDOR.GET_VENDOR)
const getVendors = async () => get('vendors', BACKEND_CONSTANTS.VENDOR.GET_VENDORS)

const getVendorProducts = async vendorId =>
  get(`vendors/${vendorId}/variants`, BACKEND_CONSTANTS.VENDOR.GET_VENDOR_PRODUCTS)

const removeVendor = async (supplierId, vendorId) =>
  destroy(`suppliers/${supplierId}/vendor/${vendorId}`, BACKEND_CONSTANTS.VENDOR.REMOVE_VENDOR)

export { addVendor, getVendor, getVendors, getVendorProducts, removeVendor }
