import React from 'react'
import PropTypes from 'prop-types'
import { FaPlus } from 'react-icons/fa'

import { Button } from '../../Button'
import { Header } from '../../Header'

const SuppliersHeader = ({ onCreateClick }) => {
  return (
    <Header
      actions={<Button iconBefore={<FaPlus />} onClick={onCreateClick} value="New" variant="secondary" />}
      crumbs={[{ to: '/suppliers', value: 'Suppliers' }]}
    />
  )
}

SuppliersHeader.propTypes = {
  onCreateClick: PropTypes.func.isRequired,
}

export { SuppliersHeader }
