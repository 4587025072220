import React from 'react'
import PropTypes from 'prop-types'

const AutoCompleteResult = ({ className, selected, ...props }) => (
  <div
    className={`cursor-pointer flex py-1 px-2 border-b border-gray-100 hover:border-gray-500 active:bg-gray-100 focus:bg-gray-100 ${
      selected ? 'bg-orange text-white' : ''
    } ${className}`}
    {...props}
  />
)

AutoCompleteResult.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

AutoCompleteResult.defaultProps = {
  className: '',
  selected: false,
}

export { AutoCompleteResult }
