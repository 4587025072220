import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { navigate } from '@reach/router'
import { FaTrash } from 'react-icons/fa'

import { Table } from '../../Table'
import { TableCell } from '../../TableCell'
import { TableHeadCell } from '../../TableHeadCell'
import { TableSortableColumnHeading } from '../../TableSortableColumnHeading'
import { TableRow } from '../../TableRow'
import { TableRowActions } from '../../TableRowActions'
import { TransfersFilter } from './TransfersFilter'

import { formatDate, sortArrayByObjectKey, roundToTwo } from '../../../helpers'
import { PROP_TYPES } from '../../../common/propTypes'
import { CONSTANTS } from '../../../common/constants'

const TransfersTable = ({ deleteTransfer, locations, orders, status }) => {
  const [filteredOrders, setFilteredOrders] = useState(orders)
  const [sorting, setSorting] = useState({
    key: 'updatedAt',
    ascending: true,
  })

  const isFulfilled = status === CONSTANTS.STATUS.FULFILLED
  const isReceived = status === CONSTANTS.STATUS.RECEIVED

  function sortedOrders() {
    const sorted = sortArrayByObjectKey(filteredOrders, sorting.key, true)
    return sorting.ascending ? sorted : sorted.reverse()
  }

  function handleSort(key) {
    setSorting({
      key,
      ascending: key === sorting.key ? !sorting.ascending : sorting.ascending,
    })
  }

  return (
    <>
      <TransfersFilter
        filterKeys={[
          { label: 'Location To', value: 'location_id' },
          { label: 'Location From', value: 'from_location_id' },
        ]}
        locations={locations}
        orders={orders}
        setFilteredOrders={setFilteredOrders}
      />
      <Table
        className="mt-6 max-w-5xl"
        emptyState={!orders.length && 'No transfers here...'}
        renderHead={() => (
          <TableRow>
            <TableHeadCell value="No." width="32" />
            <TableHeadCell
              value={<TableSortableColumnHeading text="From Location" onClick={() => handleSort('fromLocationId')} />}
              width="72"
            />
            <TableHeadCell
              value={<TableSortableColumnHeading text="To Location" onClick={() => handleSort('toLocationId')} />}
              width="72"
            />
            <TableHeadCell
              value={<TableSortableColumnHeading text="Created" onClick={() => handleSort('createdAt')} />}
              width="32"
            />
            <TableHeadCell
              value={<TableSortableColumnHeading text="Updated" onClick={() => handleSort('updatedAt')} />}
              width="32"
            />
            <TableHeadCell value="Total Value" width="48" />
            {!isFulfilled && !isReceived && <TableHeadCell alignment="right" width="64" value="Actions" />}
          </TableRow>
        )}
        renderBody={() => (
          <>
            {sortedOrders().map(({ id, createdAt, fromLocationId, locationId, orderNumber, orderTotal, updatedAt }) => (
              <TableRow key={id} onClick={() => navigate(`/transfers/${id}`)}>
                <TableCell value={orderNumber} />
                <TableCell value={locations.find(location => location.id === fromLocationId).companyName} />
                <TableCell value={locations.find(location => location.id === locationId).companyName} />
                <TableCell value={formatDate(createdAt)} />
                <TableCell value={formatDate(updatedAt)} />
                <TableCell value={`$${orderTotal ? roundToTwo(orderTotal / 100) : 0}`} />
                {!isFulfilled && !isReceived && (
                  <TableRowActions
                    actions={[
                      {
                        id: 'delete',
                        iconBefore: <FaTrash />,
                        onClick: e => {
                          e.stopPropagation()
                          deleteTransfer(id)
                        },
                      },
                    ]}
                  />
                )}
              </TableRow>
            ))}
          </>
        )}
      />
    </>
  )
}

TransfersTable.propTypes = {
  deleteTransfer: PropTypes.func.isRequired,
  locations: PropTypes.arrayOf(PROP_TYPES.LOCATION),
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      date: PropTypes.string,
      status: PropTypes.string,
      supplier: PropTypes.string,
    }),
  ),
  status: PropTypes.string,
}

TransfersTable.defaultProps = {
  locations: [],
  orders: [],
  status: CONSTANTS.STATUS.DRAFT,
}

export { TransfersTable }
