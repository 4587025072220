import { useEffect, useState, useRef } from 'react'
import { navigate } from '@reach/router'

import { createStocktake as create, deleteStocktake as destroy, getStocktakes } from '../backend/stocktakes'
import { useFeedback } from './useFeedback'

const useStocktakes = () => {
  const isMounted = useRef(true)
  const [isFetchingStocktakes, setIsFetchingStocktakes] = useState()
  const [isUpdatingStocktakes, setIsUpdatingStocktakes] = useState()
  const [stocktakes, setStocktakes] = useState()
  const { setError, setToast } = useFeedback()

  useEffect(() => {
    getData()

    return () => {
      isMounted.current = false
    }
  }, [])

  async function getData() {
    setIsFetchingStocktakes(true)
    const res = await getStocktakes()

    if (!isMounted.current) return
    if (!res.success) setError(res)
    if (res.success) setStocktakes(res.data)

    setIsFetchingStocktakes()
  }

  async function createStocktake(data) {
    setIsUpdatingStocktakes(true)
    const res = await create(data)

    if (!isMounted.current) return
    if (!res.success) setError(res)
    if (res.success) {
      setToast('Stocktake created!')
      navigate(`stocktakes/${res.data.id}`)
    }

    setIsUpdatingStocktakes()
  }

  async function deleteStocktake(stocktakeId) {
    setIsUpdatingStocktakes(true)

    const res = await destroy(stocktakeId)

    if (!isMounted.current) return
    if (!res.success) setError(res)
    if (res.success) {
      setStocktakes(prev => prev.filter(stocktake => stocktake.id !== stocktakeId))
      setToast('Deleted!')
    }

    setIsUpdatingStocktakes()
  }

  return {
    createStocktake,
    deleteStocktake,
    isFetchingStocktakes,
    isUpdatingStocktakes,
    stocktakes,
  }
}

export { useStocktakes }
