import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FaPen, FaUndo } from 'react-icons/fa'

import { Address } from '../../Address'
import { Button } from '../../Button'
import { DatePicker } from '../../DatePicker'
import { DebouncedInput } from '../../DebouncedComponent'
import { Dropdown } from '../../Dropdown'
import { Input } from '../../Input'

import { dateString } from '../../../helpers'
import { PROP_TYPES } from '../../../common/propTypes'

const OrderDetails = ({ locations, loading, order, readOnly, updateOrder }) => {
  const [editingLocation, setEditingLocation] = useState()
  const [fields, setFields] = useState({
    invoiceDate: order.invoiceDate ? new Date(order.invoiceDate) : null,
    invoiceNumber: order.invoiceNumber || '',
    locationId: order.location ? order.location.id : '',
    paymentDue: order.paymentDue ? new Date(order.paymentDue) : null,
  })

  function handleSetValue(field, value) {
    setFields(prevFields => ({
      ...prevFields,
      [field]: value,
    }))
  }

  return (
    <div className="inline-block relative">
      <div className="flex mt-4">
        <div className="flex">
          <DebouncedInput
            className="w-48 mr-2"
            Component={Input}
            disabled={loading}
            id="invoice-number"
            label="Invoice Number"
            readOnly={readOnly}
            requestHandler={value => updateOrder({ invoiceNumber: value }, true)}
            setValue={value => handleSetValue('invoiceNumber', value)}
            value={fields.invoiceNumber}
          />
        </div>
        <div className="flex">
          <div className="mr-2">
            <label className="font-bold text-sm" htmlFor="invoice-date">
              Invoice Date
            </label>
            <div className="mt-1">
              <DebouncedInput
                Component={DatePicker}
                disabled={readOnly || loading}
                id="invoice-date"
                requestHandler={value => updateOrder({ invoiceDate: dateString(value) }, true)}
                setValue={value => handleSetValue('invoiceDate', value)}
                value={fields.invoiceDate}
              />
            </div>
          </div>
          <div>
            <label className="font-bold text-sm" htmlFor="payment-date-due">
              Payment Due
            </label>
            <div className="mt-1">
              <DebouncedInput
                Component={DatePicker}
                disabled={loading}
                id="payment-date-due"
                requestHandler={value => updateOrder({ paymentDue: dateString(value) }, true)}
                setValue={value => handleSetValue('paymentDue', value)}
                value={fields.paymentDue}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <div className="flex justify-between items-end">
          <div className="flex">
            <div className="mr-2">
              {editingLocation && (
                <div className="mt-2 flex items-end">
                  <DebouncedInput
                    Component={Dropdown}
                    disabled={loading}
                    id="shipping-address"
                    label="Select Shipping Address"
                    requestHandler={value => updateOrder({ locationId: value }, true)}
                    setValue={value => {
                      handleSetValue('locationId', value)
                      setEditingLocation()
                    }}
                    options={locations.map(({ id: value, companyName }) => ({ value, option: companyName }))}
                    value={fields.locationId}
                  />
                  <div className="ml-2 pb-1">
                    <Button iconBefore={<FaUndo />} onClick={() => setEditingLocation()} variant="plain" />
                  </div>
                </div>
              )}
              {fields.locationId && !editingLocation && (
                <div className="mt-2 flex">
                  <Address data={locations.find(loc => loc.id === fields.locationId)} />
                  {!readOnly && (
                    <div className="ml-2 -mt-1">
                      <Button iconBefore={<FaPen />} onClick={() => setEditingLocation(true)} variant="plain" />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

OrderDetails.propTypes = {
  locations: PropTypes.arrayOf(PROP_TYPES.LOCATION),
  loading: PropTypes.bool,
  order: PROP_TYPES.ORDER.isRequired,
  readOnly: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  updateOrder: PropTypes.func.isRequired,
}

OrderDetails.defaultProps = {
  locations: [],
  loading: false,
  readOnly: false,
}

export { OrderDetails }
