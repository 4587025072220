import { useEffect, useState, useRef } from 'react'

import { getLocations } from '../backend/locations'
import { useFeedback } from './useFeedback'

const useLocations = () => {
  const isMounted = useRef(true)
  const [isFetchingLocations, setIsFetchingLocations] = useState()
  const [locations, setLocations] = useState()
  const { setError } = useFeedback()

  useEffect(() => {
    getData()

    return () => {
      isMounted.current = false
    }
  }, [])

  async function getData() {
    setIsFetchingLocations(true)

    const res = await getLocations()

    if (!isMounted.current) return
    if (!res.success) setError(res)
    if (res.success) setLocations(res.data)

    setIsFetchingLocations()
  }

  return { locations, isFetchingLocations }
}

export { useLocations }
