import React from 'react'
import PropTypes from 'prop-types'
import ClipLoader from 'react-spinners/ClipLoader'

import { TableCellInput } from './TableCellInput'

import { theme } from '../../tailwind.config'

const TableCell = ({ alignment, capitalize, loading, nullable, onChange, slim, tabIndex, type, width, value, url }) => {
  function getContent() {
    return url ? (
      <a className="underline" href={url} target="_blank" rel="noopener noreferrer" tabIndex={tabIndex}>
        {value}
      </a>
    ) : (
      value
    )
  }

  return (
    <td
      className={`${onChange || slim ? 'px-1' : 'pl-2'} py-2 ${
        capitalize ? 'capitalize' : ''
      } w-${width} text-${alignment}`}
    >
      {loading && <ClipLoader size={14} color={theme.colors['gray-300']} />}
      {onChange && (
        <TableCellInput
          disabled={loading}
          nullable={nullable}
          onChange={onChange}
          tabIndex={tabIndex}
          type={type}
          value={value}
        />
      )}
      {!onChange && !loading && getContent()}
    </td>
  )
}

TableCell.propTypes = {
  alignment: PropTypes.oneOf(['left', 'right', 'center']),
  capitalize: PropTypes.bool,
  loading: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  nullable: PropTypes.bool,
  onChange: PropTypes.func,
  slim: PropTypes.bool,
  tabIndex: PropTypes.string,
  type: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.node]),
  url: PropTypes.string,
}

TableCell.defaultProps = {
  alignment: 'left',
  capitalize: true,
  loading: false,
  nullable: false,
  onChange: null,
  slim: false,
  tabIndex: '1',
  type: null,
  width: '20',
  value: null,
  url: null,
}

export { TableCell }
