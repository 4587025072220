import React from 'react'
import PropTypes from 'prop-types'

import { PROP_TYPES } from '../common/propTypes'

const Address = ({ align, className, data }) => {
  if (!data) return null

  const { id, shopifyId, ...address } = data
  return (
    <div className={`text-${align} ${className}`}>
      {Object.values(address).map((value, i) =>
        value && value.length > 0 ? (
          <p key={value} className={i === 0 ? 'font-bold' : ''}>
            {value}
          </p>
        ) : null,
      )}
    </div>
  )
}

Address.propTypes = {
  align: PropTypes.string,
  className: PropTypes.string,
  data: PROP_TYPES.LOCATION,
}

Address.defaultProps = {
  align: 'left',
  className: '',
  data: null,
}

export { Address }
