import { useEffect, useState, useRef } from 'react'

import { getVendors, getVendorProducts as getProducts } from '../backend/vendors'

import { sortArrayByObjectKey } from '../helpers'
import { useFeedback } from './useFeedback'

const useVendors = () => {
  const isMounted = useRef(true)
  const [isFetchingVendors, setIsFetchingVendors] = useState()
  const [isVendorModalLoading, setIsVendorModalLoading] = useState()
  const [vendors, setVendors] = useState()
  const [vendorProducts, setVendorProducts] = useState()
  const { setError } = useFeedback()

  useEffect(() => {
    getData()

    return () => {
      isMounted.current = false
    }
  }, [])

  async function getData() {
    setIsFetchingVendors(true)

    const res = await getVendors()

    if (!isMounted.current) return
    if (!res.success) setError(res)
    if (res.success) setVendors(sortArrayByObjectKey(res.data, 'name'))

    setIsFetchingVendors()
  }

  async function getVendorProducts() {
    setIsVendorModalLoading(true)

    const res = await getProducts()

    if (!isMounted.current) return
    if (!res.success) setError(res)
    if (res.success) setVendorProducts(sortArrayByObjectKey(res.data, 'title'))

    setIsVendorModalLoading()
  }

  return { getVendorProducts, isFetchingVendors, isVendorModalLoading, vendors, vendorProducts }
}

export { useVendors }
