import slugify from 'slugify'

import { CONSTANTS } from './constants'
import { roundToTwo } from '../helpers'

const getFieldValueFromType = (fieldType, value) => {
  switch (fieldType) {
    case CONSTANTS.FIELD_TYPES.FLOAT:
      return parseFloat(value || 0)
    case CONSTANTS.FIELD_TYPES.MONEY:
      return roundToTwo(parseFloat(value || 0)) * 100
    case CONSTANTS.FIELD_TYPES.INT:
      return parseInt(value || 0)
    case CONSTANTS.FIELD_TYPES.STRING:
    default:
      return value
  }
}

const getFixedExpenses = expenses =>
  expenses.reduce((acc, { amount }) => {
    return amount ? amount + acc : acc
  }, 0)

const getIsEstimate = status => status === CONSTANTS.STATUS.DRAFT || status === CONSTANTS.STATUS.SENT

const getSubtotal = ({ products, status }) =>
  getIsEstimate(status)
    ? products.reduce(
        (acc, { lastPricePaid, quantityOrdered }) => (lastPricePaid ? lastPricePaid * quantityOrdered + acc : acc),
        0,
      )
    : products.reduce((acc, { costPaid, quantityReceived }) => (costPaid ? costPaid * quantityReceived + acc : acc), 0)

const getTotal = ({ adjustments, fixedExpenses, products, status }) => {
  const subtotal = getSubtotal({ products, status })
  const expenses = getFixedExpenses(fixedExpenses)
  const gst = (subtotal + expenses + adjustments) * CONSTANTS.TAX_MULTIPLIER
  return subtotal + expenses + adjustments + gst
}

const handleCallback = callback => {
  if (typeof callback === 'function') callback()
}

const mergeReportLocations = reports =>
  reports.reduce(
    (acc, cur) => {
      return {
        ...acc,
        days:
          typeof acc.days !== 'undefined'
            ? [...acc.days, ...cur.days].reduce((all, current) => {
                const existingDate = all.some(entry => entry.date === current.date)
                return existingDate
                  ? all.map(entry => {
                      if (entry.date !== current.date) return entry

                      return {
                        date: entry.date,
                        unitsInTransfer: entry.unitsInTransfer + current.unitsInTransfer,
                        valueInTransfer: entry.valueInTransfer + current.valueInTransfer,
                        unitsOnHand: entry.unitsOnHand + current.unitsOnHand,
                        valueOnHand: entry.valueOnHand + current.valueOnHand,
                      }
                    })
                  : [...all, current]
              }, [])
            : cur.days,
      }
    },
    { id: 'all', location: 'All' },
  )

const toSlug = string => slugify(string, { lower: true })

export {
  getFieldValueFromType,
  getFixedExpenses,
  getIsEstimate,
  getSubtotal,
  getTotal,
  handleCallback,
  mergeReportLocations,
  toSlug,
}
