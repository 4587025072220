import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FaPlus } from 'react-icons/fa'

import { AddProductModal } from '../components/AddProductModal'
import { Button } from '../components/Button'
import { DeleteProductModal } from '../components/Layouts/Order/DeleteProductModal'
import { Loading } from '../components/Loading'
import { LoadingGlobal } from '../components/LoadingGlobal'
import { NotFound } from './NotFound'
import { OrderDetails } from '../components/Layouts/Order/OrderDetails'
import { OrderExpensesTable } from '../components/Layouts/Order/OrderExpensesTable'
import { OrderHeader } from '../components/Layouts/Order/OrderHeader'
import { OrderNotes } from '../components/Layouts/Order/OrderNotes'
import { OrderReceiveModal } from '../components/Layouts/Order/OrderReceiveModal'
import { OrderSendModal } from '../components/Layouts/Order/OrderSendModal'
import { OrderStockPosition } from '../components/Layouts/Order/OrderStockPosition'
import { OrderSyncModal } from '../components/Layouts/Order/OrderSyncModal'
import { OrderTable } from '../components/Layouts/Order/OrderTable'
import { OrderTotals } from '../components/Layouts/Order/OrderTotals'
import { OrderUpdateStatusModal } from '../components/Layouts/Order/OrderUpdateStatusModal'
import { Tabs } from '../components/Tabs'

import { CONSTANTS } from '../common/constants'
import { useLocations } from '../hooks/useLocations'
import { useOrder } from '../hooks/useOrder'
import { useOrderStockPosition } from '../hooks/useOrderStockPosition'
import { useOverlay } from '../hooks/useOverlay'

const Order = ({ orderId, parent }) => {
  const [currentProductId, setCurrentProductId] = useState()
  const [currentTab, setCurrentTab] = useState('details')
  const [isEditing, setIsEditing] = useState()
  const [modalContainerRef, modal, setModal] = useOverlay()
  const { locations, isFetchingLocations } = useLocations()
  const {
    addOrderProducts,
    createExpense,
    deleteExpense,
    deleteOrderProduct,
    deleteOrderProducts,
    isDeletingSingleProduct,
    isFetchingOrder,
    isOrderModalLoading,
    isUpdatingOrder,
    notFound,
    order,
    receiveOrder,
    sendOrder,
    syncOrder,
    updateExpense,
    updateOrder,
    updateOrderProduct,
    updateOrderProducts,
  } = useOrder(orderId)
  const { getStockPosition, initialDateRange, isFetchingPosition, stockPosition } = useOrderStockPosition(orderId)

  const isDraft = order && order.status === CONSTANTS.STATUS.DRAFT
  const isSent = order && order.status === CONSTANTS.STATUS.SENT
  const isReceived = order && order.status === CONSTANTS.STATUS.RECEIVED

  useEffect(() => {
    if (order && !isOrderModalLoading && !isDeletingSingleProduct) {
      setIsEditing()
      setModal()
    }
  }, [isOrderModalLoading, isDeletingSingleProduct])

  useEffect(() => {
    if (order && !stockPosition && !isFetchingPosition && isDraft) getStockPosition(initialDateRange, order.location.id)
  }, [order, stockPosition])

  function getSyncModalWarnings() {
    const warnings = order.products.reduce(
      (acc, product) =>
        product.costPaid <= 0 && product.quantityReceived > 0
          ? [...acc, `${product.title} has a price paid of <strong>$0</strong>`]
          : acc,
      [],
    )
    return warnings.length > 0 ? warnings : null
  }

  function handleDeleteProduct(productId) {
    setCurrentProductId(productId)
    setModal('deleteProduct')
  }

  function orderProductsFillQuantities() {
    function getQuantity(quantityInStock, quantitySold) {
      if (quantityInStock < 0 && quantitySold > 0) return quantitySold
      if (quantityInStock <= quantitySold) return quantitySold - quantityInStock
      return 0
    }

    return stockPosition.reduce((acc, { id, quantityInStock, quantitySold }) => {
      return quantitySold > 0
        ? [
            ...acc,
            {
              id,
              quantityOrdered: getQuantity(quantityInStock, quantitySold),
            },
          ]
        : acc
    }, [])
  }

  if (notFound) return <NotFound />
  if (!order || !locations || isFetchingOrder || isFetchingLocations) return <Loading />

  return (
    <div className="max-w-site mb-12">
      <OrderHeader
        isEditing={isEditing}
        parent={parent}
        order={order}
        setIsEditing={setIsEditing}
        setModal={setModal}
      />
      <div className="flex justify-between items-start">
        <div className="capitalize mr-2">
          <h2>
            <strong>Supplier</strong>: {order.supplier.name}
          </h2>
          <h2 className="mt-2">
            <strong>Location</strong>: {order.location.companyName}
          </h2>
        </div>
        {isDraft && (
          <OrderStockPosition
            fetching={isFetchingPosition}
            initialDateRange={initialDateRange}
            locations={locations}
            onFill={() => updateOrderProducts(orderProductsFillQuantities())}
            onRefresh={getStockPosition}
            orderLocation={order.location}
          />
        )}
      </div>
      <OrderTable
        deleteOrderProducts={deleteOrderProducts}
        handleDeleteProduct={handleDeleteProduct}
        isEditing={isEditing}
        isFetchingPosition={isFetchingPosition}
        order={order}
        stockPosition={stockPosition}
        updateOrderProduct={updateOrderProduct}
      />
      {!isReceived && (
        <div className="mt-4">
          <Button
            iconBefore={<FaPlus />}
            onClick={() => setModal('addProducts')}
            size="small"
            value="Add Products"
            variant="primary"
          />
        </div>
      )}
      <div className="flex justify-between mt-8">
        <div className="w-full">
          <Tabs
            className="max-w-3xl"
            content={
              currentTab === 'details' ? (
                <OrderDetails
                  locations={locations}
                  loading={isUpdatingOrder}
                  order={order}
                  readOnly={isReceived}
                  updateOrder={updateOrder}
                />
              ) : (
                <OrderNotes loading={isUpdatingOrder} notes={order.notes} updateOrder={updateOrder} />
              )
            }
            currentTab={currentTab}
            onTabClick={setCurrentTab}
            tabs={[
              {
                className: '',
                id: 'details',
                label: 'Details',
              },
              {
                className: 'mr-2',
                id: 'notes',
                label: 'Notes',
              },
            ]}
          />
        </div>
        <div className="ml-4 w-full max-w-lg">
          <OrderExpensesTable
            createExpense={createExpense}
            deleteExpense={deleteExpense}
            expenses={order.fixedExpenses}
            loading={isUpdatingOrder}
            modalLoading={isOrderModalLoading}
            readOnly={isReceived}
            updateExpense={updateExpense}
          />
          <OrderTotals loading={isUpdatingOrder} order={order} updateOrder={updateOrder} />
        </div>
      </div>
      {modal && (
        <div ref={modalContainerRef} className="fixed top-0 right-0 w-full h-screen z-50">
          <AddProductModal
            isOpen={modal === 'addProducts'}
            loading={isOrderModalLoading}
            onClose={setModal}
            onSubmit={productIds => addOrderProducts(productIds)}
            supplierId={order.supplierId}
          />
          <DeleteProductModal
            isOpen={modal === 'deleteProduct'}
            loading={isDeletingSingleProduct}
            onClose={setModal}
            onSubmit={() => deleteOrderProduct(currentProductId)}
          />
          <OrderReceiveModal
            isOpen={modal === 'receive'}
            loading={isOrderModalLoading}
            onClose={setModal}
            onSubmit={receiveOrder}
            warnings={getSyncModalWarnings()}
          />
          <OrderSendModal
            isOpen={modal === 'send'}
            loading={isOrderModalLoading}
            onClose={setModal}
            onSubmit={sendOrder}
            orderId={order.id}
            supplier={order.supplier}
          />
          <OrderSyncModal
            isOpen={modal === 'sync'}
            loading={isOrderModalLoading}
            onClose={setModal}
            onSubmit={() => syncOrder(isSent)}
            status={order.status}
            warnings={getSyncModalWarnings()}
          />
          <OrderUpdateStatusModal
            currentStatus={order.status}
            isOpen={modal === 'status'}
            loading={isOrderModalLoading}
            onClose={setModal}
            onSubmit={status => updateOrder({ status })}
          />
        </div>
      )}
      <LoadingGlobal loading={isUpdatingOrder} />
    </div>
  )
}

Order.propTypes = {
  orderId: PropTypes.string,
  parent: PropTypes.shape({
    to: PropTypes.string,
    value: PropTypes.string,
  }),
}

Order.defaultProps = {
  orderId: '',
  parent: null,
}

export { Order }
