import React from 'react'
import PropTypes from 'prop-types'

import { EmptyTableRow } from '../../EmptyStates/EmptyTableRow'
import { Table } from '../../Table'
import { TableCell } from '../../TableCell'
import { TableHeadCell } from '../../TableHeadCell'
import { TableHeadRow } from '../../TableHeadRow'

import { CONSTANTS } from '../../../common/constants'
import { getFieldValueFromType } from '../../../common/helpers'
import { PROP_TYPES } from '../../../common/propTypes'
import { useSupplierCode } from '../../../hooks/useSupplierCode'
import { sortArrayByObjectKey } from '../../../helpers'

const SupplierVendorProductsTable = ({ loading, products }) => {
  const { updateSupplierCode } = useSupplierCode()

  function handleSupplierCodeChange(productSupplierId, value) {
    return updateSupplierCode(productSupplierId, getFieldValueFromType(CONSTANTS.FIELD_TYPES.STRING, value))
  }

  return (
    <Table
      className="mt-8 max-w-3xl"
      loading={loading}
      renderHead={() => (
        <TableHeadRow>
          <TableHeadCell value="Products" width="72" />
          <TableHeadCell value="Supplier Code" width="32" />
        </TableHeadRow>
      )}
      renderBody={() => (
        <>
          {sortArrayByObjectKey(products, 'title').map(({ id, productSupplierId, supplierCode, title, url }) => (
            <tr key={id}>
              <TableCell value={title} url={url} width="72" />
              <TableCell
                value={supplierCode}
                onChange={value => handleSupplierCodeChange(productSupplierId, value)}
                width="32"
              />
            </tr>
          ))}
          {products.length < 1 && <EmptyTableRow columns={['products']} />}
        </>
      )}
    />
  )
}

SupplierVendorProductsTable.propTypes = {
  loading: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  products: PropTypes.arrayOf(PROP_TYPES.PRODUCT),
}

SupplierVendorProductsTable.defaultProps = {
  loading: false,
  products: [],
}

export { SupplierVendorProductsTable }
