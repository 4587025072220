import React from 'react'
import PropTypes from 'prop-types'

const Textarea = ({ className, label, rows, ...props }) => (
  <div className={`w-full ${className}`}>
    {label && <label className="text-sm font-bold">{label}</label>}
    <textarea
      className="border rounded w-full block mt-1 p-4 text-lg disabled:border-gray-300 disabled:text-gray-300"
      rows={6}
      {...props}
    />
  </div>
)

Textarea.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  rows: PropTypes.number,
}

Textarea.defaultProps = {
  className: '',
  label: null,
  rows: 6,
}

export { Textarea }
