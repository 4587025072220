import React from 'react'
import BarLoader from 'react-spinners/BarLoader'

import { theme } from '../../tailwind.config'

const Loading = () => (
  <div className="p-6 flex justify-center items-center">
    <BarLoader color={theme.colors['orange-500']} loading />
  </div>
)

export { Loading }
