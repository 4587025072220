import { useContext } from 'react'

import { FeedbackContext } from '../providers/FeedbackProvider'

function useFeedback() {
  const { getSyncJobs, saved, setError, setSaved, setToast, syncJobs } = useContext(FeedbackContext)

  return { getSyncJobs, saved, setError, setSaved, setToast, syncJobs }
}

export { useFeedback }
