import React from 'react'
import PropTypes from 'prop-types'
import { FaArrowLeft } from 'react-icons/fa'

import { Button } from '../../Button'

const StocktakePrintable = ({ children, onClose }) => {
  return (
    <div className="absolute bg-white top-0 left-0 w-full h-full z-60">
      <div className="absolute top-0 right-0 p-4">
        <Button iconBefore={<FaArrowLeft />} onClick={onClose} variant="plain" />
      </div>
      <div className="p-2 bg-white">{children}</div>
    </div>
  )
}

StocktakePrintable.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
}

export { StocktakePrintable }
