import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Dropdown } from '../../Dropdown'
import { Input } from '../../Input'

import { PROP_TYPES } from '../../../common/propTypes'
import { Button } from '../../Button'

const DEFAULT_FILTER_KEY = 'none'

const OrdersFilter = ({ filterKeys, orders, setFilteredOrders }) => {
  const [filterKey, setFilterKey] = useState(DEFAULT_FILTER_KEY)
  const [query, setQuery] = useState('')

  const dataOptions = getOptionsFromData()

  useEffect(() => {
    setFilteredOrders(getFilteredOrders(query, orders))
  }, [orders])

  function getFilteredOrders(newQuery, ordersToFilter) {
    // Clear dropdown filter with 'none' value
    if (newQuery === DEFAULT_FILTER_KEY && typeof dataOptions[filterKey] !== 'undefined') return orders
    // Clear input filter with 'none' filterKey
    if (!filterKey || filterKey === DEFAULT_FILTER_KEY) return orders
    const key = filterKeys.find(({ value }) => value === filterKey).value

    return ordersToFilter.filter(order =>
      order[key].toString().trim().toLowerCase().includes(newQuery.trim().toLowerCase()),
    )
  }

  function getPlaceholderText() {
    return filterKey !== DEFAULT_FILTER_KEY ? `Enter a ${filterKey.toLowerCase()}` : 'First select a filter'
  }

  function reset() {
    setFilterKey(DEFAULT_FILTER_KEY)
    setQuery('')
    setFilteredOrders(orders)
  }

  function onFilterKeyChange(newKey) {
    if (newKey === DEFAULT_FILTER_KEY) reset()

    setFilterKey(newKey)
  }

  function onQueryChange(newQuery) {
    setFilteredOrders(getFilteredOrders(newQuery, orders))
    setQuery(newQuery)
  }

  function getOptionsFromData() {
    return filterKeys.reduce(
      (acc, cur) => (typeof cur.data !== 'undefined' ? { ...acc, [cur.value]: cur.data } : acc),
      {},
    )
  }

  return (
    <div className="flex items-end">
      <Dropdown
        className="mr-2 w-48"
        id="filter-key"
        label="Filter By"
        onChange={onFilterKeyChange}
        options={[
          { option: 'None', value: DEFAULT_FILTER_KEY },
          ...filterKeys.map(({ label, value }) => ({ option: label, value })),
        ]}
        value={filterKey}
      />
      {typeof dataOptions[filterKey] !== 'undefined' ? (
        <Dropdown
          className="w-64 mr-2"
          id="filter-query"
          label="Query"
          onChange={onQueryChange}
          options={[
            { option: '', value: DEFAULT_FILTER_KEY },
            ...dataOptions[filterKey].map(({ id, label }) => ({ option: label, value: id })),
          ]}
          value={query}
        />
      ) : (
        <Input
          className="w-64 mr-2"
          disabled={filterKey === DEFAULT_FILTER_KEY}
          id="filter-query"
          label="Query"
          onChange={onQueryChange}
          placeholder={getPlaceholderText()}
          value={query}
        />
      )}
      <div>
        <Button onClick={reset} value="Reset Filter" variant="secondary" />
      </div>
    </div>
  )
}

OrdersFilter.propTypes = {
  filterKeys: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.object),
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  orders: PropTypes.arrayOf(PROP_TYPES.ORDER).isRequired,
  setFilteredOrders: PropTypes.func.isRequired,
}

export { OrdersFilter }
