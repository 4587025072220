import { useState, useRef, useEffect } from 'react'

import { sendToXero as send } from '../backend/orders'
import { useFeedback } from './useFeedback'

const useXero = () => {
  const isMounted = useRef(true)
  const [orderIdXero, setOrderIdXero] = useState()
  const [xeroOrderData, setXeroOrderData] = useState()
  const { setError, setToast } = useFeedback()

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  async function sendToXero(orderId) {
    setOrderIdXero(orderId)

    const res = await send(orderId)

    if (!isMounted.current) return
    if (!res.success) setError(res)
    if (res.success) {
      setXeroOrderData(res.data)
      setToast('Sent to Xero!')
    }

    setOrderIdXero()
  }

  return { orderIdXero, sendToXero, xeroOrderData }
}

export { useXero }
