import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { navigate } from '@reach/router'
import { FaTrash } from 'react-icons/fa'

import { StocktakesFilter } from './StocktakesFilter'
import { Table } from '../../Table'
import { TableCell } from '../../TableCell'
import { TableHeadCell } from '../../TableHeadCell'
import { TableSortableColumnHeading } from '../../TableSortableColumnHeading'
import { TableRow } from '../../TableRow'
import { TableRowActions } from '../../TableRowActions'

import { CONSTANTS } from '../../../common/constants'
import { formatDate, roundToTwo, sortArrayByObjectKey } from '../../../helpers'
import { PROP_TYPES } from '../../../common/propTypes'

const StocktakesTable = ({ currentTab, deleteStocktake, locations, stocktakes, vendors }) => {
  const [filteredStocktakes, setFilteredStocktakes] = useState(
    sortArrayByObjectKey(stocktakes, 'stocktakeNumber', true),
  )
  const [sorting, setSorting] = useState({
    key: 'updatedAt',
    ascending: true,
  })

  const isDraft = currentTab === CONSTANTS.STATUS.DRAFT ? true : null

  function sortedStocktakes() {
    const sorted = sortArrayByObjectKey(filteredStocktakes, sorting.key, true)
    return sorting.ascending ? sorted : sorted.reverse()
  }

  function handleSort(key) {
    setSorting({
      key,
      ascending: key === sorting.key ? !sorting.ascending : sorting.ascending,
    })
  }

  return (
    <>
      <StocktakesFilter
        filterKeys={[
          { data: locations.map(l => ({ id: l.id, label: l.companyName })), label: 'Location', value: 'locationId' },
          { label: 'Stocktake No.', value: 'stocktakeNumber' },
          { data: vendors.map(v => ({ id: v.name, label: v.name })), label: 'vendor', value: 'vendorName' },
        ]}
        stocktakes={stocktakes}
        setFilteredStocktakes={setFilteredStocktakes}
      />
      <Table
        className="mt-6 max-w-6xl"
        renderHead={() => (
          <TableRow>
            <TableHeadCell value="Stocktake No." width="32" />
            <TableHeadCell value="Location" width="72" />
            {currentTab === 'ALL' && (
              <TableHeadCell
                value={<TableSortableColumnHeading text="Status" onClick={() => handleSort('status')} />}
                width="72"
              />
            )}
            <TableHeadCell
              value={<TableSortableColumnHeading text="Vendor" onClick={() => handleSort('vendorName')} />}
              width="72"
            />
            <TableHeadCell
              value={<TableSortableColumnHeading text="Created" onClick={() => handleSort('createdAt')} />}
              width="32"
            />
            <TableHeadCell
              value={<TableSortableColumnHeading text="Updated" onClick={() => handleSort('updatedAt')} />}
              width="32"
            />
            <TableHeadCell value="Total Value" width="48" />
            {isDraft && <TableHeadCell alignment="right" width="72" value="Actions" />}
          </TableRow>
        )}
        renderBody={() => (
          <>
            {sortedStocktakes().map(stocktake => {
              const { id, createdAt, locationId, status, stocktakeNumber, updatedAt, value, vendorName } = stocktake

              const actions = {
                delete: {
                  id: 'delete',
                  iconBefore: <FaTrash />,
                  onClick: e => {
                    e.stopPropagation()
                    deleteStocktake(id)
                  },
                },
              }

              function getActions() {
                if (isDraft) return [actions.delete]
                return []
              }

              return (
                <TableRow key={id} onClick={() => navigate(`/stocktakes/${id}`)}>
                  <TableCell value={stocktakeNumber} />
                  <TableCell value={locations.find(l => l.id === locationId).companyName} />
                  {currentTab === 'ALL' && <TableCell value={status.toLowerCase()} />}
                  <TableCell value={vendorName} />
                  <TableCell value={formatDate(createdAt)} />
                  <TableCell value={formatDate(updatedAt)} />
                  <TableCell value={`$${value ? roundToTwo(value / 100) : 0}`} />
                  {isDraft && <TableRowActions actions={getActions()} />}
                </TableRow>
              )
            })}
          </>
        )}
      />
    </>
  )
}

StocktakesTable.propTypes = {
  currentTab: PropTypes.string,
  deleteStocktake: PropTypes.func.isRequired,
  locations: PropTypes.arrayOf(PROP_TYPES.LOCATION),
  stocktakes: PropTypes.arrayOf(
    PropTypes.shape({
      createdAt: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      locationId: PropTypes.string.isRequired,
      stocktakeNumber: PropTypes.number.isRequired,
      value: PropTypes.number,
      status: PropTypes.string.isRequired,
    }),
  ),
  vendors: PropTypes.arrayOf(PROP_TYPES.VENDOR),
}

StocktakesTable.defaultProps = {
  currentTab: '',
  locations: null,
  stocktakes: [],
  vendors: null,
}

export { StocktakesTable }
