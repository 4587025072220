import React from 'react'
import PropTypes from 'prop-types'
import { FaDownload, FaUndo, FaSyncAlt, FaEye, FaFileInvoiceDollar } from 'react-icons/fa'

import { Button } from '../../Button'
import { Header } from '../../Header'
import { Status } from '../../Status'

import { BACKEND_CONSTANTS } from '../../../backend/constants'
import { CONSTANTS } from '../../../common/constants'
import { formatDate } from '../../../helpers'
import { PROP_TYPES } from '../../../common/propTypes'
import { useXero } from '../../../hooks/useXero'

const OrderHeader = ({ isEditing, parent, order, setIsEditing, setModal, ...props }) => {
  const { orderIdXero, sendToXero } = useXero()

  const isDraft = order.status === CONSTANTS.STATUS.DRAFT
  const isSent = order.status === CONSTANTS.STATUS.SENT
  const isPartial = order.status === CONSTANTS.STATUS.PARTIALLY_RECEIVED
  const isReceived = order.status === CONSTANTS.STATUS.RECEIVED

  return (
    <Header
      actions={
        <>
          <Button
            asLink
            iconBefore={<FaDownload />}
            href={`${BACKEND_CONSTANTS.BASE_URL}/order_products/${order.id}/csv`}
            rel="noopener noreferrer"
            value="Download"
            variant="ghost"
          />
          {isDraft && <Button className="ml-2" onClick={() => setModal('send')} value="Mark as sent" />}
          {isSent && !isEditing && (
            <>
              <Button
                className="ml-2"
                iconBefore={<FaUndo />}
                onClick={() => setModal('status')}
                value="Revert to draft"
                variant="secondary"
              />
              <Button className="ml-2" onClick={() => setIsEditing(true)} value="Start receiving" />
            </>
          )}
          {isSent && isEditing && (
            <>
              <Button
                className="ml-2"
                iconBefore={<FaSyncAlt />}
                onClick={() => setModal('sync')}
                value="Sync stock"
                variant="secondary"
              />
              <Button className="ml-2" onClick={() => setModal('receive')} value="Mark as received" variant="outline" />
              <Button className="ml-2" onClick={() => setIsEditing()} value="Stop receiving" />
            </>
          )}
          {isPartial && (
            <>
              <Button
                className="ml-2"
                iconBefore={<FaSyncAlt />}
                onClick={() => setModal('sync')}
                value="Sync stock"
                variant="secondary"
              />
              <Button className="ml-2" onClick={() => setModal('receive')} value="Mark as received" />
            </>
          )}
          {isReceived && (
            <>
              <Button
                className="ml-2"
                iconBefore={<FaUndo />}
                onClick={() => setModal('status')}
                value="Revert Status"
                variant="secondary"
              />
              <Button
                className="ml-2"
                iconBefore={<FaFileInvoiceDollar />}
                loading={orderIdXero}
                onClick={() => sendToXero(order.id)}
                value={order.sentToXero ? 'Resend to Xero' : 'Send to Xero'}
                variant="secondary"
              />
              {order.xeroInvoiceLink && (
                <Button
                  className="ml-2"
                  iconBefore={<FaEye />}
                  onClick={() => window.open(order.xeroInvoiceLink, '_blank', 'noopener')}
                  value="View in Xero"
                  variant="secondary"
                />
              )}
            </>
          )}
        </>
      }
      crumbs={[
        { to: parent.to, value: parent.value },
        {
          to: `${parent.to}/${order.id}`,
          value: (
            <div>
              {order.orderNumber} <Status className="mx-2 text-sm" status={order.status} />{' '}
              <span className="text-black inline-block text-sm font-bold">Created at:</span>{' '}
              <span className="text-black inline-block text-sm">{formatDate(order.createdAt)}</span>
            </div>
          ),
        },
      ]}
      {...props}
    />
  )
}

OrderHeader.propTypes = {
  isEditing: PropTypes.bool,
  order: PROP_TYPES.ORDER,
  parent: PropTypes.shape({
    to: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  setIsEditing: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
}

OrderHeader.defaultProps = {
  isEditing: false,
  order: null,
}

export { OrderHeader }
