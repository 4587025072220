import React from 'react'
import PropTypes from 'prop-types'
import { navigate, Link } from '@reach/router'
import { FaTrash } from 'react-icons/fa'

import { Table } from '../../Table'
import { TableCell } from '../../TableCell'
import { TableHeadCell } from '../../TableHeadCell'
import { TableHeadRow } from '../../TableHeadRow'
import { TableRow } from '../../TableRow'
import { TableRowActions } from '../../TableRowActions'

import { PROP_TYPES } from '../../../common/propTypes'
import { sortArrayByObjectKey } from '../../../helpers'

const SuppliersTable = ({ onDeleteClick, suppliers }) => (
  <Table
    childIds={suppliers.map(({ id }) => id)}
    className="mt-6"
    renderHead={() => (
      <TableHeadRow>
        <TableHeadCell value="Supplier Name" width="3/12" />
        <TableHeadCell value="Vendors" width="4/12" />
        <TableHeadCell value="Email" width="64" />
        <TableHeadCell value="Contact Name" width="64" />
        <TableHeadCell alignment="right" width="32" value="Actions" />
      </TableHeadRow>
    )}
    renderBody={() =>
      sortArrayByObjectKey(suppliers, 'name').map(({ contactFirstName, contactLastName, email, id, name, vendors }) => (
        <TableRow key={id} onClick={() => navigate(`/suppliers/${id}`)}>
          <TableCell value={<Link to={`/suppliers/${id}`}>{name}</Link>} />
          <TableCell
            value={vendors.reduce(
              (acc, cur, i) => [
                ...acc,
                <Link className="hover:underline" key={cur.id} to={`/vendors/${cur.id}`}>
                  {cur.name}
                </Link>,
                ...[i < vendors.length - 1 ? [<span key={`_${cur.id}`}>, </span>] : []],
              ],
              [],
            )}
          />
          <TableCell capitalize={false} value={email} />
          <TableCell value={`${contactFirstName || ''}${contactFirstName ? ' ' : ''}${contactLastName || ''}`} />
          <TableRowActions
            actions={[
              {
                id: 'delete',
                iconBefore: <FaTrash />,
                onClick: e => {
                  e.stopPropagation()
                  onDeleteClick(id)
                },
              },
            ]}
          />
        </TableRow>
      ))
    }
  />
)

SuppliersTable.propTypes = {
  onDeleteClick: PropTypes.func.isRequired,
  suppliers: PropTypes.arrayOf(PROP_TYPES.SUPPLIER),
}

SuppliersTable.defaultProps = {
  suppliers: [],
}

SuppliersTable.displayName = 'SuppliersTable'

export { SuppliersTable }
