import { useEffect, useRef } from 'react'

import { updateSupplierCode as update } from '../backend/productSupplier'
import { useFeedback } from './useFeedback'

const useSupplierCode = () => {
  const isMounted = useRef(true)
  const { setError, setSaved } = useFeedback()

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  async function updateSupplierCode(productSupplierId, code) {
    setSaved(false)

    const res = await update(productSupplierId, code)

    if (!isMounted.current) return
    if (!res.success) {
      setSaved('error')
      setError(res)
    }
    if (res.success) setSaved(true)
  }

  return { updateSupplierCode }
}

export { useSupplierCode }
