import React from 'react'

import { ErrorMessage } from './ErrorMessage'

import { PROP_TYPES } from '../common/propTypes'

const ErrorInline = ({ error }) =>
  error ? (
    <div className="inline-block">
      <ErrorMessage error={error} />
    </div>
  ) : null

ErrorInline.propTypes = {
  error: PROP_TYPES.ERROR,
}

ErrorInline.defaultProps = {
  error: null,
}

export { ErrorInline }
