import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Loading } from '../components/Loading'
import { LoadingGlobal } from '../components/LoadingGlobal'
import { NotFound } from './NotFound'
import { StocktakeHeader } from '../components/Layouts/Stocktake/StocktakeHeader'
import { StocktakeBeginModal } from '../components/Layouts/Stocktake/StocktakeBeginModal'
import { StocktakeCompleteModal } from '../components/Layouts/Stocktake/StocktakeCompleteModal'
import { StocktakePrintable } from '../components/Layouts/Stocktake/StocktakePrintable'
import { StocktakeTable } from '../components/Layouts/Stocktake/StocktakeTable'
import { StocktakeTotals } from '../components/Layouts/Stocktake/StocktakeTotals'

import { CONSTANTS } from '../common/constants'
import { useLocations } from '../hooks/useLocations'
import { useStocktake } from '../hooks/useStocktake'
import { useOverlay } from '../hooks/useOverlay'

const Stocktake = ({ stocktakeId, parent }) => {
  const [print, setPrint] = useState()
  const [modalContainerRef, modal, setModal] = useOverlay()
  const { locations, isFetchingLocations } = useLocations()
  const {
    beginStocktake,
    completeStocktake,
    stocktake,
    isFetchingStocktake,
    isStocktakeModalLoading,
    isUpdatingStocktake,
    notFound,
    updateStocktakeProduct,
  } = useStocktake(stocktakeId)

  const isDraft = stocktake && stocktake.status === CONSTANTS.STATUS.DRAFT

  useEffect(() => {
    if (stocktake && !isStocktakeModalLoading) setModal()
  }, [isStocktakeModalLoading])

  if (notFound) return <NotFound />
  if (!stocktake || !locations || isFetchingStocktake || isFetchingLocations) return <Loading />

  const Headings = () => (
    <div className="mt-8 mb-4">
      <h2 className="mb-2">
        <span className="font-bold">Stock Location</span>:{' '}
        <span className="capitalize">{stocktake.location.companyName}</span>
      </h2>
      <h2>
        <span className="font-bold">Vendor</span>: <span className="capitalize">{stocktake.vendor.name}</span>
      </h2>
    </div>
  )

  const Totals = () => (
    <div className="flex justify-end">
      <div className="ml-4 w-full max-w-lg">
        <StocktakeTotals stocktake={stocktake} />
      </div>
    </div>
  )

  return !print ? (
    <div className="max-w-site mb-12">
      <StocktakeHeader
        onPrintClick={() => setPrint(prev => (prev ? null : true))}
        parent={parent}
        stocktake={stocktake}
        setModal={setModal}
      />
      <Headings />
      <StocktakeTable stocktake={stocktake} updateStocktakeProduct={updateStocktakeProduct} />
      {!isDraft && <Totals />}
      {modal && (
        <div ref={modalContainerRef} className="fixed top-0 right-0 w-full h-screen z-50">
          <StocktakeBeginModal
            isOpen={modal === 'begin'}
            loading={isStocktakeModalLoading}
            onClose={setModal}
            onSubmit={beginStocktake}
            stocktakeId={stocktake.id}
          />
          <StocktakeCompleteModal
            isOpen={modal === 'complete'}
            loading={isStocktakeModalLoading}
            onClose={setModal}
            onSubmit={completeStocktake}
            stocktakeId={stocktakeId}
          />
        </div>
      )}
      <LoadingGlobal loading={isUpdatingStocktake} />
    </div>
  ) : (
    <StocktakePrintable onClose={() => setPrint()}>
      <Headings />
      <StocktakeTable printable stocktake={stocktake} updateStocktakeProduct={updateStocktakeProduct} />
    </StocktakePrintable>
  )
}

Stocktake.propTypes = {
  parent: PropTypes.shape({
    to: PropTypes.string,
    value: PropTypes.string,
  }),
  stocktakeId: PropTypes.string,
}

Stocktake.defaultProps = {
  parent: null,
  stocktakeId: '',
}

export { Stocktake }
