import React from 'react'
import PropTypes from 'prop-types'
import { FaPaperPlane } from 'react-icons/fa'

import { Modal } from '../../Modal'

const OrderReceiveModal = ({ isOpen, loading, onClose, onSubmit, warnings }) => (
  <Modal
    closeModal={onClose}
    heading="Receive Order &amp; Sync Stock with Shopify"
    loading={loading}
    isOpen={isOpen}
    onSubmit={() => onSubmit()}
    submitIcon={<FaPaperPlane />}
    submitValue="Submit"
  >
    <div className="mt-4">
      <p>
        This will change your purchase order status to <code className="text-orange">Received</code> and sync stock
        levels with Shopify.
      </p>
    </div>
    {warnings && (
      <>
        <div className="text-sm mt-4 p-4 bg-orange-100 rounded border border-orange overflow-y-auto max-h-1/2">
          <p className="font-bold mb-2">Please check the following first:</p>
          {warnings.map(error => (
            <p key={error} dangerouslySetInnerHTML={{ __html: error }} />
          ))}
        </div>
        <p className="mt-4">Are you sure?</p>
      </>
    )}
  </Modal>
)

OrderReceiveModal.propTypes = {
  isOpen: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  loading: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  warnings: PropTypes.arrayOf(PropTypes.string),
}

OrderReceiveModal.defaultProps = {
  isOpen: false,
  loading: false,
  warnings: null,
}

export { OrderReceiveModal }
