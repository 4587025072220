import React, { useState, useCallback, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import toaster from 'toasted-notes'

import { ErrorModal } from '../components/ErrorModal'
import { Toast } from '../components/Toast'

import { getStatuses } from '../backend/status'

const FeedbackContext = React.createContext({
  getSyncJobs: () => {},
  setError: () => {},
  setSaved: () => {},
  setSyncJobs: () => {},
  setToast: () => {},
})

const FeedbackProvider = ({ children }) => {
  const timer = useRef()
  const [error, setError] = useState()
  const [saved, setSaved] = useState(true)
  const [syncJobs, setSyncJobs] = useState([])

  useEffect(() => {
    return () => {
      clearTimeout(timer.current)
    }
  }, [])

  function setToast(message) {
    toaster.notify(({ onClose }) => <Toast message={message} onClose={onClose} />, {
      position: 'bottom',
      duration: 5000,
    })
  }

  async function getSyncJobs() {
    const res = await getStatuses()

    if (!res.success) setError(res)
    if (res.success) {
      const jobs = res.data.filter(job => typeof job.status !== 'undefined')
      // if there are still uncompleted jobs in the queue
      if (jobs.length > 0 && jobs.some(job => job.status !== 'complete')) {
        timer.current = setTimeout(() => {
          getSyncJobs()
        }, 5000)
      }
      setSyncJobs(jobs)
    }
  }

  const contextValue = {
    getSyncJobs,
    saved,
    setError,
    setSaved,
    setSyncJobs,
    setToast: useCallback(message => setToast(message), []),
    syncJobs,
  }

  return (
    <FeedbackContext.Provider value={contextValue}>
      {children}
      {error && <ErrorModal onClose={() => setError()} error={error} />}
    </FeedbackContext.Provider>
  )
}

FeedbackProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export { FeedbackContext, FeedbackProvider }
