import React from 'react'
import PropTypes from 'prop-types'

import { Modal } from './Modal'

import { ErrorMessage } from './ErrorMessage'
import { PROP_TYPES } from '../common/propTypes'

const ErrorModal = ({ error, loading, onClose, onSubmit }) => (
  <Modal
    cancelValue="Close"
    closeModal={onClose}
    heading="Error"
    loading={loading}
    isOpen={error && error.status}
    onSubmit={onSubmit}
  >
    <ErrorMessage error={error} />
  </Modal>
)

ErrorModal.propTypes = {
  error: PROP_TYPES.ERROR,
  loading: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
}

ErrorModal.defaultProps = {
  error: null,
  loading: false,
  onSubmit: null,
}

export { ErrorModal }
