import React from 'react'
import PropTypes from 'prop-types'
import ClipLoader from 'react-spinners/ClipLoader'
import styled from '@emotion/styled'

import { theme } from '../../tailwind.config'

const Wrapper = styled.button()

const Button = ({ asLink, className, disabled, loading, iconBefore, iconAfter, size, value, variant, ...props }) => {
  const isDisabled = disabled || loading

  function getSizeClasses() {
    switch (size) {
      case 'small':
        return 'py-1 px-3 text-sm'
      case 'large':
        return 'py-3 px-5 text-lg'
      case 'normal':
      default:
        return 'py-2 px-4'
    }
  }

  function getVariantClasses() {
    const disabledFill = 'disabled:border disabled:border-gray-100 disabled:text-white bg-gray-300 cursor-default'
    const disabledOutline =
      'disabled:border disabled:border-gray-100 disabled:text-gray-300 text-gray-300 cursor-default'
    const disabledPlain = 'disabled:text-gray-300 text-gray-300 cursor-default'

    switch (variant) {
      case 'ghost':
        return isDisabled ? disabledOutline : 'border border-black text-black hover:bg-black hover:text-white'
      case 'outline':
        return isDisabled ? disabledOutline : 'border border-orange text-orange hover:bg-orange hover:text-white'
      case 'plain':
        return isDisabled ? disabledPlain : 'hover:border-orange text-black hover:underline'
      case 'secondary':
        return isDisabled
          ? disabledFill
          : 'bg-black border border-black text-white hover:border-gray-800 hover:bg-gray-800'
      case 'warning':
        return isDisabled ? disabledOutline : 'border border-gray-200 hover:border-red-500 text-red-500'
      case 'primary':
      default:
        return isDisabled
          ? disabledOutline
          : 'bg-orange-500 hover:bg-orange-600 border border-orange-500 hover:border-orange-600 text-white'
    }
  }

  function getSpinnerColor() {
    switch (variant) {
      case 'secondary':
        return theme.colors.white
      case 'ghost':
      case 'plain':
      case 'warning':
      case 'primary':
      default:
        return theme.colors['gray-300']
    }
  }

  return (
    <Wrapper
      as={asLink ? 'a' : null}
      className={`font-bold rounded flex items-center justify-center relative ${getVariantClasses()} ${getSizeClasses()} ${className}`}
      disabled={isDisabled}
      {...props}
    >
      <span className={`flex items-center justify-center block ${loading ? 'opacity-0' : ''}`}>
        {iconBefore && <span className={value ? 'mr-3' : ''}>{iconBefore}</span>}
        <span>{value}</span>
        {iconAfter && <span className={value ? 'ml-3' : ''}>{iconAfter}</span>}
      </span>
      {loading && (
        <span className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
          <ClipLoader size={20} color={getSpinnerColor()} />
        </span>
      )}
    </Wrapper>
  )
}

Button.propTypes = {
  asLink: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  iconAfter: PropTypes.node,
  iconBefore: PropTypes.node,
  loading: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  size: PropTypes.oneOf(['normal', 'small', 'large']),
  value: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'secondary', 'warning', 'ghost', 'outline', 'plain']),
}

Button.defaultProps = {
  asLink: false,
  className: '',
  disabled: false,
  iconAfter: null,
  iconBefore: null,
  loading: false,
  size: 'normal',
  value: '',
  variant: 'primary',
}

export { Button }
