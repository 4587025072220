import React from 'react'
import PropTypes from 'prop-types'

import { TableRow } from '../TableRow'

const EmptyTableRow = ({ columns, hasActions }) => (
  <TableRow>
    {columns.map(col => (
      <td key={col} className="px-1 py-2 text-white">
        &nbsp;
      </td>
    ))}
    {hasActions && <td />}
  </TableRow>
)

EmptyTableRow.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  hasActions: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

EmptyTableRow.defaultProps = {
  hasActions: false,
}

export { EmptyTableRow }
