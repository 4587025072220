import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FaPlus } from 'react-icons/fa'

import { Dropdown } from '../../Dropdown'
import { Modal } from '../../Modal'

import { PROP_TYPES } from '../../../common/propTypes'

const CreateStocktakeModal = ({ isOpen, loading, locations, onClose, onSubmit, vendors, ...props }) => {
  const [location, setLocation] = useState(locations[0].id)
  const [vendor, setVendor] = useState(vendors[0].id)

  return (
    <Modal
      closeModal={onClose}
      disabled={!vendor}
      loading={loading}
      heading="New Stocktake"
      isOpen={isOpen}
      onSubmit={() =>
        onSubmit({
          locationId: location,
          vendorId: vendor,
        })
      }
      submitIcon={<FaPlus />}
      submitValue="Create"
      {...props}
    >
      <Dropdown
        className="mt-6"
        id="new-stocktake-vendor"
        label="Vendor"
        onChange={setVendor}
        options={vendors.map(({ id: value, name }) => ({
          option: name,
          value,
        }))}
        value={vendor}
      />
      <Dropdown
        className="mt-4"
        id="new-vendor-location"
        label="Store / Warehouse Location"
        onChange={setLocation}
        options={locations.map(({ id: value, companyName }) => ({ value, option: companyName }))}
        value={location}
      />
    </Modal>
  )
}

CreateStocktakeModal.propTypes = {
  isOpen: PropTypes.bool,
  loading: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  locations: PropTypes.arrayOf(PROP_TYPES.LOCATION).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  vendors: PropTypes.arrayOf(PROP_TYPES.VENDOR).isRequired,
}

CreateStocktakeModal.defaultProps = {
  isOpen: false,
  loading: false,
}

export { CreateStocktakeModal }
