import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'

import { BACKEND_CONSTANTS } from './constants'

const onSuccess = (action, data = {}) => {
  console.log('SUCCESS: ', action)
  return {
    success: true,
    data,
  }
}

const onError = (action, status, messages = null) => {
  console.error('ERROR: ', action)
  return {
    success: false,
    status: typeof status === 'object' ? status.toString() : status,
    messages,
  }
}

const destroy = async (endpoint, action, data = {}) => {
  try {
    const res = await fetch(`${BACKEND_CONSTANTS.BASE_URL}/${endpoint}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(snakecaseKeys(data, { deep: true })),
    })

    if (!res.ok && res.status === 422) throw await getCustomError(res)
    if (!res.ok) throw res

    return onSuccess(action)
  } catch (e) {
    return onError(action, e.status, e.messages)
  }
}

const get = async (endpoint, action) => {
  try {
    const res = await fetch(`${BACKEND_CONSTANTS.BASE_URL}/${endpoint}`, {
      method: 'GET',
      credentials: 'include',
    })

    if (!res.ok && res.status === 422) throw await getCustomError(res)
    if (!res.ok) throw res

    const json = await res.json()

    return onSuccess(action, camelcaseKeys(json, { deep: true }))
  } catch (e) {
    return onError(action, e.status, e.messages)
  }
}

const patch = async (endpoint, action, data) => modify('PATCH', endpoint, action, data)
const post = async (endpoint, action, data) => modify('POST', endpoint, action, data)
const put = async (endpoint, action, data) => modify('PUT', endpoint, action, data)

async function modify(method, endpoint, action, data) {
  try {
    const res = await fetch(`${BACKEND_CONSTANTS.BASE_URL}/${endpoint}`, {
      method,
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      credentials: 'include',
      body: JSON.stringify(snakecaseKeys(data, { deep: true })),
    })

    if (!res.ok && res.status === 422) throw await getCustomError(res)
    if (!res.ok) throw res

    const json = await res.json()

    return onSuccess(action, camelcaseKeys(json, { deep: true }))
  } catch (e) {
    return onError(action, e.status, e.messages)
  }
}

async function getCustomError(res) {
  const messages = await res.json()
  const error = new Error()
  return Object.assign(error, { status: res.status, messages })
}

export { destroy, get, patch, post, put, onSuccess, onError }
