const toVendorSuppliers = suppliers =>
  suppliers.map(({ id, name, vendors }) => ({ id, name, vendors: vendors.map(vendor => vendor.id) }))

const toOrder = ({ invoiceDate, orderDate, paymentDue, products, ...props }) => ({
  invoiceDate: invoiceDate && new Date(invoiceDate),
  orderDate: orderDate && new Date(orderDate),
  paymentDue: paymentDue && new Date(paymentDue),
  products: products.map(product => ({
    ...product,
    quantityInStock: product.quantityInStock || 0,
    quantityOnOrder: product.quantityOnOrder || 0,
    quantityOrdered: product.quantityOrdered || 0,
    quantityReceived: product.quantityReceived || 0,
    quantitySold: product.quantitySold || 0,
    quantitySynced: product.quantitySynced || 0,
  })),
  ...props,
})

const toLocation = ({ id, city, companyName, country, line1, line2, phoneNumber, postcode, province, shopifyId }) => ({
  id,
  companyName,
  line1,
  line2,
  line3: `${city} ${postcode ? postcode : ''}`,
  line4: province,
  line5: country,
  phoneNumber,
  shopifyId,
})

export { toLocation, toVendorSuppliers, toOrder }
