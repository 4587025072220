import React, { useEffect, useState, useRef } from 'react'
import { FaExclamationTriangle } from 'react-icons/fa'

import { Authenticate } from './components/Authenticate'
import { FeedbackProvider } from './providers/FeedbackProvider'
import { LoadingGlobal } from './components/LoadingGlobal'
import { PageLayout } from './components/PageLayout'

import { checkAuthenticated } from './backend/authenticate'

const App = () => {
  const isMounted = useRef(true)
  const [loading, setLoading] = useState(true)
  const [shopUrl, setShopUrl] = useState()

  const isStaging = process.env.BRANCH === 'staging'

  useEffect(() => {
    check()

    return () => {
      isMounted.current = false
    }
  }, [])

  async function check() {
    const res = await checkAuthenticated()
    if (!isMounted.current) return
    if (res.success) setShopUrl(res.data.shopifyDomain)

    setLoading()
  }

  return !loading ? (
    <FeedbackProvider>
      {shopUrl ? <PageLayout shopUrl={shopUrl} onLogout={setShopUrl} /> : <Authenticate setShopUrl={setShopUrl} />}
      {isStaging && (
        <div className="text-red fixed bottom-0 right-0 py-2 px-4 z-90 bg-red text-white text-lg rounded-tl flex items-center justify-center">
          <FaExclamationTriangle />
          <span className="ml-2">Staging Environment</span>
        </div>
      )}
    </FeedbackProvider>
  ) : (
    <LoadingGlobal loading={loading} />
  )
}

export default App
