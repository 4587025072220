import { BACKEND_CONSTANTS } from './constants'
import { post, destroy, patch } from './helpers'

const createFixedExpense = async expense => post('fixed_expenses', BACKEND_CONSTANTS.EXPENSE.CREATE_EXPENSE, expense)
const deleteFixedExpense = async fixedExpenseId =>
  destroy(`fixed_expenses/${fixedExpenseId}`, BACKEND_CONSTANTS.EXPENSE.DELETE_EXPENSE)
const updateFixedExpense = async (fixedExpenseId, data) =>
  patch(`fixed_expenses/${fixedExpenseId}`, BACKEND_CONSTANTS.EXPENSE.UPDATE_EXPENSE, data)

export { createFixedExpense, deleteFixedExpense, updateFixedExpense }
