import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { navigate } from '@reach/router'

import { Loading } from '../components/Loading'
import { NotFound } from './NotFound'
import { SupplierAddVendorModal } from '../components/Layouts/Supplier/SupplierAddVendorModal'
import { SupplierHeader } from '../components/Layouts/Supplier/SupplierHeader'
import { SupplierDetails } from '../components/Layouts/Supplier/SupplierDetails'
import { SupplierVendorsTable } from '../components/Layouts/Supplier/SupplierVendorsTable'
import { Tabs } from '../components/Tabs'

import { useSupplier } from '../hooks/useSupplier'
import { SupplierRemoveVendorModal } from '../components/Layouts/Supplier/SupplierRemoveVendorModal'

const Supplier = ({ location, parent, supplierId }) => {
  const [modal, setModal] = useState()
  const [currentTab, setCurrentTab] = useState('vendors')
  const [currentVendor, setCurrentVendor] = useState()
  const {
    addSupplierVendor,
    isFetchingSupplier,
    isUpdatingSupplier,
    isUpdatingSupplierVendor,
    removeSupplierVendor,
    supplier,
    supplierNotFound,
    updateSupplier,
  } = useSupplier(supplierId)

  useEffect(() => {
    const parsed = queryString.parse(location.search)

    if (parsed.tab) setCurrentTab(parsed.tab)
  }, [])

  useEffect(() => {
    if (supplier && !isUpdatingSupplierVendor) setModal()
  }, [isUpdatingSupplierVendor])

  function handleRemoveVendor(vendorId) {
    setCurrentVendor(supplier.vendors.find(v => v.id === vendorId))
    setModal('removeVendor')
  }

  function handleSetTab(tab) {
    navigate(`/suppliers/${supplierId}?tab=${tab.toLowerCase()}`)
    setCurrentTab(tab)
  }

  if (supplierNotFound) return <NotFound />
  if (!supplier || isFetchingSupplier) return <Loading />

  return (
    <>
      <SupplierHeader onAddVendor={() => setModal('addVendor')} parent={parent} supplier={supplier} />
      <div className="text-sm mt-6">
        <div>
          <span className="font-bold">Email:</span> {supplier.email}
        </div>
        {(supplier.contactFirstName || supplier.contactLastName) && (
          <div className="mt-2">
            <span className="font-bold">Contact person:</span> {supplier.contactFirstName} {supplier.contactLastName}
          </div>
        )}
      </div>
      <Tabs
        className="w-full mt-8"
        content={
          currentTab === 'vendors' ? (
            <SupplierVendorsTable handleRemove={handleRemoveVendor} supplier={supplier} />
          ) : (
            <SupplierDetails loading={isUpdatingSupplier} supplier={supplier} updateSupplier={updateSupplier} />
          )
        }
        currentTab={currentTab}
        maxWidth="none"
        onTabClick={handleSetTab}
        tabs={[
          {
            id: 'vendors',
            label: 'Vendors',
          },
          {
            className: 'mr-2',
            id: 'details',
            label: 'Details',
          },
        ]}
      />
      {modal === 'addVendor' && (
        <SupplierAddVendorModal
          existingVendors={supplier.vendors}
          isOpen
          loading={isUpdatingSupplierVendor}
          onClose={setModal}
          onSubmit={addSupplierVendor}
        />
      )}
      <SupplierRemoveVendorModal
        isOpen={currentVendor && modal === 'removeVendor'}
        loading={isUpdatingSupplierVendor}
        onClose={setModal}
        onSubmit={vendorId => removeSupplierVendor(vendorId)}
        vendor={currentVendor}
      />
    </>
  )
}

Supplier.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  parent: PropTypes.shape({
    to: PropTypes.string,
    value: PropTypes.string,
  }),
  supplierId: PropTypes.string,
}

Supplier.defaultProps = {
  location: { search: '' },
  parent: null,
  supplierId: '',
}

export { Supplier }
