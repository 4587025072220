import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Dropdown } from '../../Dropdown'

import { PROP_TYPES } from '../../../common/propTypes'
import { Button } from '../../Button'

const DEFAULT_FILTER_KEY = 'None'

const TransfersFilter = ({ filterKeys, locations, orders, setFilteredOrders }) => {
  const [filterKey, setFilterKey] = useState(DEFAULT_FILTER_KEY)
  const [filterValue, setFilterValue] = useState()

  useEffect(() => {
    setFilteredOrders(getFilteredOrders(filterValue, orders))
  }, [orders])

  function getFilteredOrders(selectedLocation, ordersToFilter) {
    if (!filterKey || filterKey === DEFAULT_FILTER_KEY) return orders
    const key = filterKeys.find(({ label }) => label === filterKey).value

    return ordersToFilter.filter(order => order[key].toString() === selectedLocation)
  }

  function reset() {
    setFilterKey(DEFAULT_FILTER_KEY)
    setFilterValue()
    setFilteredOrders(orders)
  }

  function onFilterKeyChange(newKey) {
    if (newKey === DEFAULT_FILTER_KEY) reset()

    setFilterKey(newKey)
  }

  function onValueChange(selectedLocation) {
    setFilteredOrders(getFilteredOrders(selectedLocation, orders))
    setFilterValue(selectedLocation)
  }

  return (
    <div className="flex items-end">
      <Dropdown
        className="mr-2 w-48"
        id="filter-key"
        label="Filter Type"
        onChange={onFilterKeyChange}
        options={[{ option: DEFAULT_FILTER_KEY }, ...filterKeys.map(({ label }) => ({ option: label }))]}
      />
      <Dropdown
        className="mr-2 w-48"
        disabled={filterKey === DEFAULT_FILTER_KEY}
        id="filter-value"
        label="Filter Location"
        onChange={onValueChange}
        options={[
          { option: DEFAULT_FILTER_KEY },
          ...locations.map(({ id, companyName }) => ({ value: id.toString(), option: companyName })),
        ]}
        value={filterValue}
      />
      <div>
        <Button onClick={reset} value="Reset Filter" variant="secondary" />
      </div>
    </div>
  )
}

TransfersFilter.propTypes = {
  filterKeys: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  locations: PropTypes.arrayOf(PROP_TYPES.LOCATION).isRequired,
  orders: PropTypes.arrayOf(PROP_TYPES.ORDER).isRequired,
  setFilteredOrders: PropTypes.func.isRequired,
}

export { TransfersFilter }
