import { useEffect, useState, useRef } from 'react'
import queryString from 'query-string'
import { navigate } from '@reach/router'

import { addIntegration as add, getIntegrations, removeIntegration as remove } from '../backend/integrations'
import { useFeedback } from './useFeedback'

const useIntegrations = location => {
  const isMounted = useRef(true)
  const [isUpdatingIntegrations, setIsUpdatingIntegrations] = useState()
  const [integrations, setIntegrations] = useState()
  const { setError, setToast } = useFeedback()

  useEffect(() => {
    const parsed = queryString.parse(location.search)

    if (parsed.success) handleSuccessCallback(parsed.success)
  }, [])

  useEffect(() => {
    getData()

    return () => {
      isMounted.current = false
    }
  }, [])

  function handleSuccessCallback(success) {
    if (success === 'true') setToast('Integration added!')
    if (success === 'false') setToast('Integration failed...')

    navigate('/integrations')
  }

  async function getData() {
    setIsUpdatingIntegrations(true)
    const res = await getIntegrations()

    if (!isMounted.current) return
    if (!res.success) setError(res)
    if (res.success) setIntegrations(res.data)

    setIsUpdatingIntegrations()
  }

  async function addIntegration(id, provider) {
    setIsUpdatingIntegrations(true)

    const res = await add({ provider })

    if (!isMounted.current) return
    if (!res.success) {
      setIsUpdatingIntegrations()
      setError(res)
    }

    if (res.success) {
      console.log('TODO: handle different integrations', id)
      navigate(res.data.url)
    }
  }

  async function removeIntegration(integrationId) {
    setIsUpdatingIntegrations(true)

    const res = await remove(integrationId)

    if (!isMounted.current) return
    if (!res.success) setError(res)
    if (res.success) {
      setIntegrations(
        integrations.map(integration =>
          integration.id === integrationId
            ? {
                ...integration,
                active: false,
              }
            : integration,
        ),
      )
      setToast('Integration removed!')
    }

    setIsUpdatingIntegrations()
  }

  return { addIntegration, removeIntegration, integrations, isUpdatingIntegrations }
}

export { useIntegrations }
