import { useEffect, useState, useRef } from 'react'
import { navigate } from '@reach/router'

import { addVendor, removeVendor } from '../backend/vendors'
import { getSupplier, getXeroContacts as getXero, updateSupplier as update } from '../backend/suppliers'
import { sortArrayByObjectKey } from '../helpers'
import { useFeedback } from './useFeedback'

const useSupplier = supplierId => {
  const isMounted = useRef(true)
  const [isFetchingSupplier, setIsFetchingSupplier] = useState()
  const [isUpdatingSupplier, setIsUpdatingSupplier] = useState()
  const [isUpdatingSupplierVendor, setIsUpdatingSupplierVendor] = useState()
  const [supplier, setSupplier] = useState()
  const [supplierNotFound, setSupplierNotFound] = useState()
  const [xeroContacts, setXeroContacts] = useState()
  const { setError, setToast } = useFeedback()

  useEffect(() => {
    if (supplierId) getData()

    return () => {
      isMounted.current = false
    }
  }, [])

  async function getData() {
    setIsFetchingSupplier(true)

    const supplierRes = await getSupplier(supplierId)

    if (!isMounted.current) return
    if (!supplierRes.success && supplierRes.code === 404) setSupplierNotFound(true)
    if (!supplierRes.success && supplierRes.code !== 404) {
      setError(supplierRes.code)
      navigate(-1)
    }
    if (supplierRes.success) setSupplier(supplierRes.data)

    setIsFetchingSupplier()
  }

  async function addSupplierVendor(vendorId) {
    setIsUpdatingSupplierVendor(true)

    const res = await addVendor(supplierId, vendorId)

    if (!isMounted.current) return
    if (!res.success) setError(res)
    if (res.success) {
      setToast('Vendor added!')
      setSupplier(prevSupplier => ({
        ...prevSupplier,
        vendors: [...prevSupplier.vendors, res.data],
      }))
    }

    setIsUpdatingSupplierVendor()
  }

  async function getXeroContacts() {
    const res = await getXero()

    if (!isMounted.current) return
    if (!res.success) setError(res)
    if (res.success) setXeroContacts(sortArrayByObjectKey(res.data, 'name'))
  }

  async function removeSupplierVendor(vendorId) {
    setIsUpdatingSupplierVendor(true)
    const res = await removeVendor(supplierId, vendorId)

    if (!isMounted.current) return
    if (!res.success) setError(res)
    if (res.success) {
      setToast('Vendor removed!')
      setSupplier(prevSupplier => ({
        ...prevSupplier,
        vendors: prevSupplier.vendors.filter(v => v.id !== vendorId),
      }))
    }

    setIsUpdatingSupplierVendor()
  }

  async function updateSupplier(updatedSupplier) {
    setIsUpdatingSupplier(true)

    const { id, vendors, updatedAt, ...supplierData } = updatedSupplier
    const res = await update(id, supplierData)

    if (!isMounted.current) return
    if (!res.success) setError(res)
    if (res.success) {
      setSupplier({ ...res.data, vendors })
      setToast('Supplier updated!')
    }

    setIsUpdatingSupplier()
  }

  return {
    addSupplierVendor,
    getXeroContacts,
    isFetchingSupplier,
    isUpdatingSupplier,
    isUpdatingSupplierVendor,
    removeSupplierVendor,
    supplier,
    supplierNotFound,
    xeroContacts,
    updateSupplier,
  }
}

export { useSupplier }
