import React, { useState } from 'react'
import { FaSyncAlt } from 'react-icons/fa'

import { Button } from '../../Button'

import { sync } from '../../../backend/sync'
import { useFeedback } from '../../../hooks/useFeedback'

const ControlPanelSync = () => {
  const [loading, setLoading] = useState()
  const { getSyncJobs, setError, setToast } = useFeedback()

  async function onSyncClick(type) {
    setLoading(type)

    const res = await sync(type)
    // const res = await sync('test')

    if (!res.success) setError(res)
    if (res.success) {
      getSyncJobs()
      setToast(<div>Sync started!</div>)
    }

    setLoading()
  }

  return (
    <div>
      <h2 className="font-bold">Force a sync with Shopify</h2>
      <div className="flex items-center mt-4">
        <Button
          className="mr-2"
          iconBefore={<FaSyncAlt />}
          loading={loading === 'locations'}
          onClick={() => onSyncClick('locations')}
          value="Sync Locations"
          variant="secondary"
        />
        <Button
          className="mr-2"
          iconBefore={<FaSyncAlt />}
          loading={loading === 'variants'}
          onClick={() => onSyncClick('variants')}
          value="Sync Products &amp; Vendors"
          variant="secondary"
        />
        <Button
          iconBefore={<FaSyncAlt />}
          loading={loading === 'sales'}
          onClick={() => onSyncClick('sales')}
          value="Sync Sales"
          variant="secondary"
        />
      </div>
    </div>
  )
}

export { ControlPanelSync }
