import React from 'react'

import { ControlPanelReports } from '../components/Layouts/ControlPanel/ControlPanelReports'
import { ControlPanelSync } from '../components/Layouts/ControlPanel/ControlPanelSync'
import { Header } from '../components/Header'

const ControlPanel = () => (
  <div>
    <Header crumbs={[{ to: '/control-panel', value: 'Control Panel' }]} />
    <ControlPanelSync />
    <ControlPanelReports />
  </div>
)

export { ControlPanel }
