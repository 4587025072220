import React from 'react'
import PropTypes from 'prop-types'
import { navigate, Link } from '@reach/router'

import { Table } from '../../Table'
import { TableCell } from '../../TableCell'
import { TableHeadCell } from '../../TableHeadCell'
import { TableHeadRow } from '../../TableHeadRow'
import { TableRow } from '../../TableRow'

import { PROP_TYPES } from '../../../common/propTypes'

const VendorsTable = ({ suppliers, vendors }) => (
  <Table
    childIds={vendors.map(({ id }) => id)}
    className="mt-6"
    renderHead={() => (
      <TableHeadRow>
        <TableHeadCell value="Vendor Name" width="3/12" />
        <TableHeadCell value="Suppliers" width="4/12" />
      </TableHeadRow>
    )}
    renderBody={() =>
      vendors.map(vendor => {
        const filteredSuppliers = suppliers.filter(supplier => supplier.vendors.includes(vendor.id))

        return (
          <TableRow key={vendor.id} onClick={() => navigate(`/vendors/${vendor.id}`)}>
            <TableCell value={<Link to={`/vendors/${vendor.id}`}>{vendor.name}</Link>} />
            <TableCell
              value={filteredSuppliers.reduce(
                (acc, cur, i) => [
                  ...acc,
                  <Link
                    key={cur.id}
                    className="hover:underline"
                    onClick={e => e.stopPropagation()}
                    to={`/suppliers/${cur.id}`}
                  >
                    {cur.name}
                  </Link>,
                  ...[i < filteredSuppliers.length - 1 ? [<span key={`_${cur.id}`}>, </span>] : []],
                ],
                [],
              )}
            />
          </TableRow>
        )
      })
    }
  />
)

VendorsTable.propTypes = {
  suppliers: PropTypes.arrayOf(PROP_TYPES.VENDOR_SUPPLIER).isRequired,
  vendors: PropTypes.arrayOf(PROP_TYPES.VENDOR).isRequired,
}

export { VendorsTable }
