import React, { useState, useEffect } from 'react'
import queryString from 'query-string'
import PropTypes from 'prop-types'
import { navigate } from '@reach/router'

import { CreateTransferModal } from '../components/Layouts/Transfers/CreateTransferModal'
import { DeleteTransferModal } from '../components/Layouts/Transfers/DeleteTransferModal'
import { Loading } from '../components/Loading'
import { TransfersHeader } from '../components/Layouts/Transfers/TransfersHeader'
import { TransfersTable } from '../components/Layouts/Transfers/TransfersTable'
import { Tabs } from '../components/Tabs'

import { CONSTANTS } from '../common/constants'
import { useOrders } from '../hooks/useOrders'
import { useLocations } from '../hooks/useLocations'
import { useSuppliers } from '../hooks/useSuppliers'

const Transfers = ({ location }) => {
  const [currentTab, setCurrentTab] = useState(CONSTANTS.STATUS.DRAFT)
  const [currentOrderId, setCurrentOrderId] = useState()
  const [modal, setModal] = useState()
  const { createOrder, deleteOrder, isFetchingOrders, isUpdatingOrders, orders } = useOrders(CONSTANTS.KIND.TRANSFER)
  const { locations, isFetchingLocations } = useLocations()
  const { suppliers, isFetchingSuppliers } = useSuppliers()

  useEffect(() => {
    if (orders && !isUpdatingOrders) setModal()
  }, [isUpdatingOrders])

  useEffect(() => {
    const parsed = queryString.parse(location.search)

    if (parsed.tab) setCurrentTab(parsed.tab.toUpperCase())
  }, [])

  function handleSetTab(tab) {
    navigate(`/transfers?tab=${tab.toLowerCase()}`)
    setCurrentTab(tab)
  }

  function handleDeleteOrder(orderId) {
    setCurrentOrderId(orderId)
    setModal('delete')
  }

  if (!orders || !locations || !suppliers || isFetchingOrders || isFetchingLocations || isFetchingSuppliers)
    return <Loading />

  return (
    <>
      <TransfersHeader onCreateClick={locations.length > 1 ? () => setModal('create') : null} />
      <Tabs
        className="w-full"
        content={
          <TransfersTable
            deleteTransfer={handleDeleteOrder}
            orders={orders ? orders.filter(({ status }) => status === currentTab) : []}
            locations={locations}
            status={currentTab}
          />
        }
        currentTab={currentTab}
        maxWidth="none"
        onTabClick={handleSetTab}
        tabs={[
          {
            id: CONSTANTS.STATUS.DRAFT,
            label: 'Draft',
          },
          {
            id: CONSTANTS.STATUS.REQUESTED,
            label: 'Requested',
          },
          {
            id: CONSTANTS.STATUS.FULFILLED,
            label: 'Fulfilled',
          },
          {
            id: CONSTANTS.STATUS.RECEIVED,
            label: 'Received',
          },
        ]}
      />
      {locations.length > 1 && (
        <CreateTransferModal
          locations={locations}
          isOpen={modal === 'create'}
          loading={isUpdatingOrders}
          onClose={setModal}
          onSubmit={createOrder}
        />
      )}
      <DeleteTransferModal
        isOpen={modal === 'delete'}
        loading={isUpdatingOrders}
        onClose={() => setModal()}
        onSubmit={() => deleteOrder(currentOrderId)}
      />
    </>
  )
}

Transfers.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
}

Transfers.defaultProps = {
  location: { search: '' },
}

export { Transfers }
