import React from 'react'
import PropTypes from 'prop-types'
import { FaAngleDown } from 'react-icons/fa'

const Dropdown = ({ className, disabled, id, label, onChange, options, ...props }) => (
  <div className={className}>
    <label className="font-bold text-sm" htmlFor={id}>
      {label}
    </label>
    <div className="relative mt-1">
      <select
        id={id}
        onChange={e => onChange(e.target.value)}
        className={`block capitalize rounded cursor-${
          disabled ? 'default' : 'pointer'
        } appearance-none bg-white w-full border py-2 pl-3 pr-8 focus:outline-none focus:border-black disabled:border-gray-300 disabled:text-gray-300`}
        disabled={disabled}
        {...props}
      >
        {options.map(({ disabled: optionDisabled = false, option, value = null }) => (
          <option disabled={optionDisabled} key={value || option} value={value || option}>
            {option}
          </option>
        ))}
      </select>
      <div
        className={`pointer-events-none absolute inset-y-0 right-0 flex items-center px-3 ${
          disabled ? 'text-gray-300' : ''
        }`}
      >
        <FaAngleDown />
      </div>
    </div>
  </div>
)

Dropdown.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), option: PropTypes.string }),
  ).isRequired,
}

Dropdown.defaultProps = {
  className: '',
  disabled: false,
}

export { Dropdown }
