import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FaTrash } from 'react-icons/fa'

import { Button } from './Button'
import { LoadingLocal } from './LoadingLocal'

const Table = ({
  bulkAction,
  childIds,
  className,
  emptyState,
  fullWidth,
  loading,
  readOnly,
  renderBody,
  renderHead,
}) => {
  const [bulkChecked, setBulkChecked] = useState([])
  const bulkActionDisabled = (bulkAction && bulkChecked.length > 0) || readOnly

  function handleBulkAction() {
    bulkAction(bulkChecked)
    setBulkChecked([])
  }

  function onBulkChange(id, checked) {
    switch (id) {
      case 'all':
        setBulkChecked(!checked ? childIds : [])
        break
      default:
        setBulkChecked(checked ? bulkChecked.filter(x => x !== id) : [...bulkChecked, id])
    }
  }

  return (
    <div className={`relative ${className}`}>
      {bulkAction && (
        <div className="mb-2">
          <Button
            disabled={!bulkActionDisabled || loading}
            iconBefore={<FaTrash />}
            onClick={handleBulkAction}
            size="small"
            value="Delete selected"
            variant="secondary"
          />
        </div>
      )}
      <table className={`${fullWidth ? 'w-full' : ''}`}>
        <thead>
          {renderHead({
            bulkChecked,
            onBulkChange,
          })}
        </thead>
        <tbody className="text-sm">
          {renderBody({
            bulkChecked,
            onBulkChange,
          })}
        </tbody>
      </table>
      {emptyState && <div className="p-2">{emptyState}</div>}
      <LoadingLocal loading={loading} />
    </div>
  )
}

Table.propTypes = {
  bulkAction: PropTypes.func,
  className: PropTypes.string,
  childIds: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  emptyState: PropTypes.node,
  fullWidth: PropTypes.bool,
  loading: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  readOnly: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  renderBody: PropTypes.func.isRequired,
  renderHead: PropTypes.func.isRequired,
}

Table.defaultProps = {
  bulkAction: null,
  className: '',
  childIds: [],
  emptyState: null,
  fullWidth: true,
  loading: false,
  readOnly: false,
}

export { Table }
