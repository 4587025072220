import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FaPlus } from 'react-icons/fa'

import { Dropdown } from '../../Dropdown'
import { Modal } from '../../Modal'

import { CONSTANTS } from '../../../common/constants'
import { PROP_TYPES } from '../../../common/propTypes'

const CreateTransferModal = ({ isOpen, loading, locations, onClose, onSubmit, ...props }) => {
  const [locationFrom, setLocationFrom] = useState(locations[0].id)
  const [locationTo, setLocationTo] = useState(locations[1].id)

  function handleSetLocationFrom(value) {
    setLocationFrom(value)

    if (value === locationTo.toString()) {
      setLocationTo(locations.filter(({ id }) => id.toString() !== value)[0].id)
    }
  }

  return (
    <Modal
      closeModal={onClose}
      disabled={!locationTo && !locationFrom}
      loading={loading}
      heading="New Transfer"
      isOpen={isOpen}
      onSubmit={() =>
        onSubmit({
          locationId: locationTo,
          fromLocationId: locationFrom,
          kind: CONSTANTS.KIND.TRANSFER,
        })
      }
      submitIcon={<FaPlus />}
      submitValue="Create"
      {...props}
    >
      <Dropdown
        className="w-64 max-w-full mt-6"
        id="new-order-supplier"
        label="Origin Location"
        onChange={handleSetLocationFrom}
        options={locations.map(({ id: value, companyName }) => ({ value, option: companyName }))}
        value={locationFrom}
      />
      <Dropdown
        className="w-64 max-w-full mt-4"
        disabled={!locationTo}
        id="new-order-location"
        label="Destination Location"
        onChange={setLocationTo}
        options={locations
          .filter(({ id }) => id !== locationFrom.id)
          .map(({ id: value, companyName }) => ({ value, option: companyName }))}
        value={locationTo}
      />
    </Modal>
  )
}

CreateTransferModal.propTypes = {
  isOpen: PropTypes.bool,
  loading: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  locations: PropTypes.arrayOf(PROP_TYPES.LOCATION).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

CreateTransferModal.defaultProps = {
  isOpen: false,
  loading: false,
}

export { CreateTransferModal }
